button[id^="videoUpload"]
  display: none !important


a[href="https://www.froala.com/wysiwyg-editor?k=u"]
  display: none !important;
  position: absolute;
  top: -99999999px;

img.fr-uploading
  display: none !important

.fr-popup
  position fixed
  margin-top 100px
  margin-left: 10px
  z-index 99999 !important
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    margin-top 200px
  
.edit-translation-field
  .fr-popup
    position absolute
    margin 0
    margin-top 10px

.fr-checkbox-line
  label
    display: inline-block !important

.fr-buttons
  display: block
  
  button[id^="imageManager"]
    visibility: hidden
  
.fr-toolbar.fr-top
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;

.fr-editor:not(.fr-modal):before
  border-left: 0px
  border-right: 0px

.f-inline
  border: 0px

#dialog-mask, .dialog-mask
  display block
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 999
  background rgba(0, 0, 0, .5)

#dialog-content, .dialog-content
  position fixed
  left 50%
  top 60%
  transform translate(-50%, -55%)
  -webkit-transform translate(-50%, -55%)
  -ms-transform translate(-50%, -55%)
  max-height 70%
  width 70%
  max-width desktop
  overflow-x hidden
  overflow-y auto
  z-index 1000
  border-radius .25em
  background white
  box-shadow 2px 2px 10px 1px rgba(0, 0, 0, .2)
  box-sizing: border-box

  @media tablet-horizontal
    width 90% !important

  @media tablet
    width 90% !important

  @media mobile-max
    width 98% !important

  .loader
    background $very-dark-gray
    opacity .5

  label
    display: block

  label.upload
    display: inline-block
    padding: default-padding default-padding*5

    &.processing
      pointer-events: none
      background-color: $very-dark-gray

  label.upload-3d-pic
    display: inline-block
    padding: default-padding default-padding*2

  label.select-offline-content
    display: inline-block
    margin-left: 0em
    margin-top: 1.5em
    
  section.edit-content
    overflow-y: auto 
    
  section.warehouseurl
    ol.basic-data
      label
        margin-top: -1em
      input
        width: 48%
        margin-top: 0.2em

  section
    &.surveyconfirm
      margin 8em 8em

    section
      &.surveylinks
        font-size 1.3em
        margin-top: 1em
        .left
          float:left
          margin-left: 10em
        .right
          margin-left: 18em


  article
    height 100%
    position relative

    .selection
      clear: both
      float: left
      width: 100%
      margin-top: 0em

      @media tablet-horizontal-max
        margin-top: 0.5em

      @media tablet-max
        margin-top: 1em

      @media mobile-horizontal-max
        margin-top: 1em

    .embed-info
      margin-top: 1em
      font-size: 0.7em
      display: flex
      align-items: center

    .embed-info-translations
      margin-top: 0.5em
      margin-bottom: 0.5em
      font-size: 0.7em

    .collection-translation-infos
      padding: 2.7em 1em 0em 1em

      .default-language-info
        padding: 0em 0em 1em 0em
        font-family: font-normal
        margin-bottom: -1em


    .translation-infos
      padding: 3.9em 1em 0em 1em

      .default-language-info
        padding: 0em 0em 1em 0em
        font-family: font-normal
        margin-bottom: -1em

      .translation-details-too-long
        padding: 0em 0em 1em 0em
        font-size: 1em
        color: red

    .header-3d
      height: 80%

    .pic-info-text, .pic-uploading
      margin: 12em 3em
      font-style: italic

    .pic-frame-area
      margin: 3em 3em 1.5em 2em
      width: 90%
      height: 90%

      iframe.threed-pic-frame
        width: 100%
        height: 90%

    .main-comment
      min-height: 1em
      padding-bottom: 1em

      .comment-createtime
        font-size: 0.8em
        width: 100%
      .comment-text
        margin-top: -0.5em
        margin-bottom: 1.5em
        max-width: 450px

    .reply-comments
      margin-left: 3em
      margin-top: -6em

      .reply-comment-title
        font-family: font-semi-bold
        font-size: 1em

      .comment-createtime
        font-size: 0.8em
        width: 100%
      .comment-text
        margin-top: -0.5em
        margin-bottom: 1.5em
        max-width: 450px

    .user-comment-items
      margin: 1.5em 0em

      a.edit-comment-link
        margin-left: -0.5em

      .report-bad-comment
        margin-bottom: 1em
        margin-top: 1em
        font-size: 0.8em

      .comment-createtime
        font-size: 0.8em
        width: 100%
      .comment-text
        margin-top: -0.5em
        margin-bottom: 1.5em
        max-width: 450px

    header
      padding 0 default-padding*2
      top 0
      position absolute
      width 100%
      left 0
      box-sizing border-box
      z-index 1100
      background white

      &.translations-header
        position relative

      .related-info
        margin-top: 1.5em

      &.with-top-actions
        padding-right: 15%

        @media mobile-horizontal-max
          padding-right: 30%
          padding-top: 10%


      h1, h2, h3, h4
        margin-top: default-margin
        margin-bottom: default-padding*.5

      span.info
        margin-left: default-margin
        font-size: 0.80em
        color: $very-dark-gray

    section
      padding 100px default-padding*2 80px default-padding*2
      box-sizing border-box
      height 100%
      background: white

      &.translations-dialog
        padding 0px default-padding*2 40px default-padding*2

      &.survey
        padding default-padding*2 default-padding*2 80px default-padding*2
        background #f6f6f6

      ol.edit-title
        margin-bottom: 3em

        @media mobile-horizontal-max
          margin-top: 3em
          margin-bottom: 0em

      ol.basic-data
        list-style: none
        
        iframe.survey
          min-height: 520px
          border: none

        .related-input
          margin-top: 2em
          margin-bottom: 2.5em

        .download-install
          margin: 0 default-margin default-margin*2
          font-size: 1.1em

          .failed-installs
            width: 95%
            margin: 0 auto
            border: 1px solid
            background-color: $almost-white
            overflow-y: auto

            .failed-content-area
              margin: 1em

              .failed-description
                margin-bottom: 1em
                margin-top: 1em

              .failed-title
                font-family: font-semi-bold

              .failed-content
                color: $blue
                font-family: font-semi-bold

          .install-information
            margin-top: 2em
            margin-left: 1em
            margin-right: 1em
            text-align: center

            .select-ts-version
              width: 50%
              display: inline-block
              margin-bottom 2em

            .install-progress

              .install-started
                font-size: 0.9em
                font-family: font-semi-bold
                margin-bottom: default-margin

              .install-title
                font-size: 0.9em
                margin-bottom: 1em

          .copy-data
            margin-left: 2.8em

            .copy-data-text
              margin-left: -0.3em

          .steps
            margin-top: 1em
            width: 38em

            .step
              margin-top: 1em

              .step-numbering
                text-align: center
                position: absolute
                float:left
                width: 30px
                height: 30px
                color: $almost-white
                background-color: $blue
                border-radius: 50%
                line-height: 1.7em

              .step-text
                margin-left: 2.5em

                .button-primary
                  margin-left: 0em

              .step-one
                margin-top: -0.2em
                &:before
                  content: '1'

              .step-two
                margin-top: -0.2em
                &:before
                  content: '2'

              .step-three
                margin-top: -0.2em
                &:before
                  content: '3'

        > li
          margin: .5em 0

        input
          width: 100%

      ol.support-data
        min-height: 25em

        input
          width: 100%
          
          &.invalid
            border: 1px solid $red 

        input.release-note-file
          width: 40%
          float:left

        .icon-trash
          margin-top: 0.6em
          float: left
          margin-left: 0.5em

        label.release-note-button
          width: 25%
          float: left
          margin-top: 0.3em
          margin-left: 1em

    & > .actions, & > form > .actions
      padding default-padding
      text-align right
      border-top 1px solid $very-light-grey
      background white
      position absolute
      bottom 0
      left 0
      width 100%
      box-sizing border-box
      border-radius 0 0 .25em .25em

      button, .button
        margin: default-padding default-padding*.5

      .dont-show, .dont-notify
        float: left
        margin: default-margin
        position: relative

        input
          margin-right: default-padding

    .top-actions
      position: absolute
      top: 0
      right: 0
      padding: default-padding
      z-index: 1101

      button, .button
        margin: default-padding default-padding*.5

    .tree-view
      margin-bottom: 1em

    .subscribers
      list-style: none

    .offline-to-online
      list-style: none

    .link-errors
      list-style: none

      span
        margin-left: 1em

    .scan-errors-info
      margin-bottom: 1em
      margin-top: -1em

    textarea
      min-height: 100px

    .thumb-box
      width: 16em
      min-height: 0

  &.comment-dialog
      display flex
      flex-direction column
      overflow: initial
    
      header
        position relative
        
      .actions
        position relative

      section
        overflow-y auto
        padding 1em

        .fr-element
          max-height: 30vh
  
  &.download-all
    width: 65%
    
  &.batch-edit
    width 95% !important
    height 95% !important

    header.main
      h2, h3
        text-align: center
        
    .confirm-message
      font-family: font-light
      font-size: 1.5em
      margin-top: 2em
      margin-bottom: 2em
      
    .confirm-buttons
      margin-top: 2em

    .processing-update

      .info-header
        text-align: center

        span
          display: block
          margin: default-margin*2 0

      .failed-updates
        width: 70%
        margin: 0 auto
        border: 2px solid $dark-gray
        overflow-y: auto

        .failed-content-list
          padding-bottom: 0.2em

    ol.basic-data > li
      height: 100%
      margin: 0

    .basic-data
      height: 100%
      
      @media mobile-horizontal-max
        height: initial
      
      .error-messages
        text-align: right
        padding-right: default-padding*2

      .batch-edit-table
        width: 100%
        height: 100%
        display: flex
        flex-wrap: wrap

        &.has-errors
          height: 85%

        .first-column, .second-column
          height: 100%
          
        .first-column
          width: 40%
          
          @media tablet-horizontal-max
            width: 100%
            float: none
            
        .second-column
          width: 60%
          padding-left: default-padding*2
          box-sizing: border-box
          
          @media tablet-horizontal-max
            width: 100%
            margin-left: -1em
            
        .column
          border: 1px solid $blue
          vertical-align: top
          overflow-y: auto
          padding: default-padding*2
          margin-bottom: 5em
          box-sizing: border-box

          @media mobile-horizontal-max
            margin-bottom: 2em


        h3
          margin-top: 0
          
          @media mobile-horizontal-max
            margin-top: 5%

        h4
          margin-top: 0
          font-family: font-normal
          text-transform: none

        li
          list-style: none

        .edit-group
          clear: both
          margin-bottom: default-margin
          margin-left: default-margin*2
          position: relative
          overflow: visible

          label
            display: inline-block
            
        .sub-group-title
          clear: both
          margin-left: default-margin*1
          font-family: font-bold

        .edit-fields
          margin-left: default-margin*3
          margin-top: default-margin*0.5

        .actiontype
          width: 30%
          margin-bottom: default-padding
          float:left

        .invalid-action
          border: 2px solid $red

        .dropdown
          width: 50%

        .input-with-tooltip
          margin-top: default-margin*-2

        .inputs, .input-with-tooltip, .tag-list-area
          width: 65%
          float: left
          margin-left: default-margin*2
          
          .react-datepicker-wrapper
            width: 100%
            
          .react-datepicker
            font-size: 0.8em
            
          .react-datepicker__header
            padding-top: 0.2em
            
          .react-datepicker__month
            margin: 0.4em 1em
            
          .react-datepicker__day-name, .react-datepicker__day
            font-size: 0.85em
            width: 1.9em
            line-height: 1.8em
            margin: 0.166em
            
          .react-datepicker__current-month
            font-size: 1em
            
          .react-datepicker__navigation
            top: 1px
            line-height: 1em
            border: 0.45em solid transparent
            
          .react-datepicker__navigation--previous
            border-right-color: #ccc
            left: 1em
          
          .react-datepicker__navigation--next
            border-left-color: #ccc
            right: 1em
              
        .tag-list-area          
          margin-bottom: 1em
          
        .invalid-action
            border: 2px solid $red
          
        .batch-edit-versions
          clear: both

        .batch-select-content
          margin-top: default-padding
          
          a
            cursor: pointer
      
          .selected-organization
            .organization-title
              margin-bottom: 0.5em
              
            a
              display: block
              margin-bottom: 1em
          
          .select-organization-search
            display: block
            margin-bottom: 2em
                    
            .organizations-list
              margin-top: 1em
              
              li
                margin-top: 0.5em
            
            form 
              position: relative
              width: 90%
              
              .clear-search
              
                &.icon-remove
                  font-size: 12px
                  position: absolute
                  top: 10px
                  right: 35px
                  cursor: pointer
                  color: $light-blue
                  
                  &:hover
                    color: $very-light-blue
                 
              button.input-search
                
                margin: 0
                font-size: 12px
                color: $dark-text-color
                padding: 6px
                  
                &.icon-search
                  font-size: 14px
                  position: absolute
                  top: 7px
                  right: 3px
                  
                  &:before
                    content "\e915"
                  
              input                
                padding: 5px 50px 5px 11px !important                
                border: 1px solid $input-border
                border-radius: 2px
                color: $input-text
                
                &:hover
                  background: $lighter-grey
                  
              .react-autosuggest__suggestions-container
                border: 1px solid $input-border                
                                  
          span
            margin-right: default-padding
            
          .dropdown-wrapper
            margin-bottom: 1em

            [class*="-container"] [class*="-control"] [class*="-singleValue"]
              max-height: none
              white-space: normal

        .select-collection-title
          margin-left: default-padding
          
        .select-version-title          
          margin-left: default-padding

        .content-area
          margin-top: default-margin*2

          label
            display: inline-block !important
            padding-right: 0

          .checkbox-label
            vertical-align: middle

          .select-all
            margin-bottom: default-margin*2
            margin-left: 1.8em

            .info
              font-size: 0.7em
              margin-left: default-margin*4

        .collection-list, .content-list, .version-list, .select-all
          margin-top: default-padding*2
          position: relative

        .collection-list
          margin-left: default-padding

          .treecontrols
            font-size: 1.5em
            margin-right: default-padding*.5
            float: left

            .open
              margin-top: 0.05em

            .close
              margin-left: 0.25em

          .checkbox
            display: inline-block

          .content-list
            margin-left: 2em

          .version-list
            margin-left: 3em

  &.user-comments

    .fr-element
      height: 100px
      
  &.survey
    width: 55%
    height: 70%

  &.threed-pic-viewer
    width: 50%
    height: 55%

    @media mobile-horizontal-max
      width: 95%
      height: 95%

    .fr-element
      height: 100px

    .fr-box
      font-size: 1em

  &.collection-translations-edit
    width: 60%
    height: 75%

    @media mobile-horizontal-max
      width: 95%
      height: 95%

    @media tablet
      height: 60%

    @media tablet-horizontal
      height: 45%

    .fr-element
      height: 100px

  &.entity-translations-edit
    width: 60%
    height: 100%

    @media mobile-horizontal-max
      width: 95%
      height: 95%

    .fr-element
      height: 100px

    .details-editor

      .fr-element
        height: 400px

        @media mobile-horizontal-max
          height: 250px

    .fr-box
      font-size: 1em

      .fr-counter
        display: none

      .fa-mouse-pointer
        &:before
          content "\f15c"


  &.file-type
    overflow: visible
    width: 35%
    height: 30%
    min-width: 15em
    min-height: 15em

    @media desktop-max
      width: 40%

    @media tablet-horizontal-max
      width: 50%

    @media tablet-max
      width: 55%

  &.translations-edit
    .fr-counter
      display: none
      
  &.support
    width: 50%
    
 &.edit-org-description, &.edit-org-logo
    width: 55%
    
  &.release-notes
    width: 30%
    height: 35%
    overflow-y: hidden
  
  &.select-client
    width: 40%
    
  &.edit-package-dialog
    overflow-y: visible
    overflow-x: visible
    
  &.related-content
    width: 45%
    overflow-y: hidden
    
  &.translations-export
    height: 95%
    min-height: 36em
    min-width: 20em
    
    @media mobile-horizontal-max
      min-height: 39em
      
    @media tablet
      height: 70%
      width: 25% !important
      
    @media tablet-horizontal
      height: 60%
      width: 40% !important
    
    section.select-content
      overflow-y: hidden !important

    header.main
      h2, h3
        text-align: center
        
    .confirm-message
      font-family: font-light
      font-size: 1.5em
      margin-top: 2em
      margin-bottom: 2em
      
    .confirm-buttons
      margin-top: 2em

    .processing-update

      .info-header
        text-align: center

        span
          display: block
          margin: default-margin*2 0

    ol.basic-data > li
      height: 100%
      margin: 0
      overflow-y: scroll;

    .basic-data
      height: 75%
      margin-left: 1em
      
      .select-export-import
        text-align: center
        margin-bottom: 0.5em
        width: 95%
      
        .export-import-info
          text-align: left

      .translation-export-table
        width: 100%
        
        .first-column, .second-column
          height: 100%
          
        .first-column
          width: 95%
          height: 62%
          float: left
          margin-bottom: -5em
          
          @media desktop-max
            margin-bottom: -4em
            height: 53% !important
             
          @media export-first-break
            margin-bottom: -4em !important
            height: 62% !important
            
          @media export-second-break
            height: 65% !important
            
          @media export-third-break
            margin-bottom: -6em !important
            height: 75% !important
            
          @media export-fourth-break
            margin-bottom: -7em !important
            height: 75% !important
            
          
          .select-content-title
            margin-bottom: 0.5em
          
        .export-to-file
          width: 100%
          margin-top: 0.5em
          
          .export-button
            width: 100%
            margin-left: 0em
            
        .second-column
          width: 30%
          padding-left: default-padding*2
          float: right
          box-sizing: border-box
          
        .column
          border: 1px solid $blue
          vertical-align: top
          overflow-y: auto
          padding: default-padding*2
          box-sizing: border-box
          height: 80%

        h3
          margin-top: 0

        h4
          margin-top: 0
          font-family: font-normal
          text-transform: none

        li
          list-style: none

        .edit-group
          clear: both
          margin-bottom: default-margin
          margin-left: default-margin*2
          position: relative
          overflow: hidden

          label
            display: inline-block

        .sub-group-title
          clear: both
          margin-left: default-margin*1
          font-family: font-bold

        .edit-fields
          margin-left: default-margin*3
          margin-top: default-margin*0.5

        .actiontype
          width: 30%
          margin-bottom: default-padding
          float:left

        .invalid-action
          border: 2px solid $red

        .dropdown
          width: 50%

        .input-with-tooltip
          margin-top: default-margin*-2

        .inputs, .input-with-tooltip, .tag-list-area
          width: 65%
          float: left
          margin-left: default-margin*2
      
        .tag-list-area
          margin-top: -0.1em
                     
        .show-dropdown
          margin-top: default-padding

          span
            margin-right: default-padding

          .dropdown-wrapper
            display: inline-block
            width: 15.5em
            @media desktop-max
              width: 13.5em

        .select-collection-title
          margin-left: default-padding

        .content-area
          margin-top: default-margin*2

          label
            display: inline-block !important
            padding-right: 0

          .checkbox-label
            vertical-align: middle

          .select-all
            margin-bottom: default-margin*2
            margin-left: 1.8em

            .info
              font-size: 0.7em
              margin-left: default-margin*4

        .collection-list, .content-list, .version-list, .select-all
          margin-top: default-padding*2
          position: relative

        .collection-list
          margin-left: default-padding

          .treecontrols
            font-size: 1.5em
            margin-right: default-padding*.5
            float: left

            .open
              margin-top: 0.05em

            .close
              margin-left: 0.25em

          .checkbox
            display: inline-block

          .content-list
            margin-left: 2em

          .version-list
            margin-left: 3em

    
  &.group-and-category
    width: 50%
    overflow: visible

  &.comment-edit
    width: 50%

    .fr-element
      height: 150px

  &.basic-edit
    width: 50%
    max-height: 47%

    @media mobile-horizontal-max
      width: 95%
      max-height: 70%

    @media tablet
      max-height: 35%

    @media tablet-horizontal
      max-height: 27%

    .fr-element
      height: 100px

    .fr-counter
      display: none

    .edit-dialog
      display flex
      flex-direction column
    
      header
        position relative
        z-index 0

      section
        padding 1em
        
      .actions
        position relative  

  &.edit-images
    width: 700px
    
  &.scan-report
    width: 800px
    
  &.export-analytics
    width: 40%
    overflow-y: visible
    overflow-x: visible

  &.basic-edit-local
    width: 50%
    max-height: 43%

    .edit-dialog
      display flex
      flex-direction column
    
      header
        position relative
        z-index 0

      section
        padding 1em
        
      .actions
        position relative

    .fr-counter
      display: none

    .fr-element
      height: 100px

  &.scrollable-fill
    height 90%
    overflow initial
    width 80%

    article
    
    &.edit-dialog
      display flex
      flex-direction column
    
      header
        position relative
        z-index 0
        
      .actions
        position relative

      section
        overflow-y auto
        padding 1em

        &.not-scrollable
          overflow-y hidden

          .fr-box
            height 100%
            position relative
            overflow-y hidden
            box-sizing border-box
            border-radius 0
            -moz-border-radius 0
            -webkit-border-radius 0
            display: flex;
            flex-direction: column;
            height: 100%

            .fr-wrapper
              height: 100%
              overflow: auto
              flex-grow: 2

            .fr-counter
              display: none

            .fa-mouse-pointer
              &:before
                content "\f15c"

            .fr-editor
              position absolute
              top 1px
              left 1px
              border initial

            .fr-element
              height 100%
              box-sizing border-box
              overflow-y initial
            
            
.edit-dialog
  .details-errors
    padding: 0.5em

    .unallowed-embeds
      ul
        padding: 0 1.5em