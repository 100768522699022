.content-manager
  display: flex
  flex-direction: column
  padding: 2em
  

  .content-manager-header
    display: flex
    justify-content: space-between
    padding-bottom: 2em
    width: 100%

    h2
      margin: 0
      color: #252a2e

  .content-manager-body
    display: flex
    flex-direction: row
    
    .content-manager-main, .content-manager-sidepane
      background-color: #f1f1f6
      border-radius: 0.25em

    .content-manager-main
      display: flex
      flex-direction: column
      padding: 0.75em
      width: 100%

      .content-manager-actions
        display: flex
        justify-content: space-between

        button
          margin: 0.25em

        .actions-left
          display: flex
          flex-direction: column
          align-items: flex-start

          div
            display: flex
            flex-direction: row
            flex-wrap: wrap
            align-items: center

            .content-selected-info, modus-checkbox
              padding-bottom: 0.5em

          .actions-for-selected
            padding-top: 0.5em
            flex-wrap: wrap

            modus-button
              padding-right: 0.5em
              padding-top: 0.5em

        .actions-right
          display: flex
          flex-direction: row
          align-self: flex-start
          align-items: center

      .content-manager-results
        margin-top: 1em

        .result-list
          width: 100%
          display: flex
          flex-direction: row
          flex-wrap: wrap

          .result-list-item
            margin: 0.5em
            width: 23%

            @media desktop-max
              width: 30%

            @media tablet-horizontal-max
              width: 43%

            @media mobile-horizontal-max
              width: 100%

            h3
              font-family: font-bold
              font-size: 1em
              margin: 0

            span
              font-family: font-normal
              font-size: 0.75em

            .item-card
              display: flex
              flex-direction: column
              height: 99.2%
              border-color: inherit
              border-radius: 1em
              border: transparent solid 2px

              &.selected
                border-color: $light-blue

              .inline-icon
                display: flex
                flex-direction: row
                flex-wrap: nowrap
                justify-content: flex-start
                align-items: center
                margin-top: 0.5em
                text-wrap: wrap
                overflow-wrap: anywhere

                .icon
                  padding-right: 0.75em

              .thumb-box
                width: 100%
                border-radius: 1em
                min-height: fit-content
                display: grid
                
                figure
                  border-radius: 1em 1em 0 0
                  background-size: cover

                .requires-maintenance
                  color: white
                  background-color: $yellow
                  position: absolute
                  align-self: end
                  padding: 0 0.5em
                  border-radius: .75em .75em 0 0

                  span
                    display: none
     
                  &:hover
                    span
                      display: block

                .item-status-tags
                  display: flex
                  position: absolute
                  padding: 0.5em

                  .item-tag
                    margin-right: 0.5em

              .item-content
                display: flex
                flex-direction: column
                margin: 0.5em 0.75em
                height: 100%

                .item-description
                  display: flex
                  flex-direction: column
                  padding-bottom: 0.75em

                  .item-attributes
                    display: flex
                    flex-direction: row
                    justify-content: start
                    flex-wrap: wrap

                    .inline-icon
                      padding-right: 1.5em
                      line-height: 1em

                  .item-tags
                    display: flex
                    flex-direction: row
                    flex-wrap: wrap
                    margin: 0.5em 0
                    max-height: 5em
                    overflow: auto

                    .item-tag
                      margin: 0.1em
                      border-radius: 5em
                    

                .item-footer
                  border-top: 1px solid $very-dark-gray
                  margin-top: auto

    .content-manager-sidepane
      display: flex
      flex-direction: column
      padding: 0.75em
      margin-right: 1em
      min-width: 20%

      h5
        margin: 0.5em 0
      
      .filter-by
        margin: 0.75em 0 1em 0

      .sectioned
        border-top: $very-dark-gray solid 1px

      .search-filter, .search-target
        margin: 0.75em 0

      .input-container
        display: flex
        justify-content: space-between
        width: 100%    

        .number-input
          width: 40%    