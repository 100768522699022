.l-responsive-list
  list-style: none
  width: 25%
  float: left
  @media tablet-horizontal-max
    width: 33%
  @media tablet-max
    width: 50%
  @media mobile-horizontal-max
    width: 100%

.l-padding-1em
  padding 0.6em 1em

//.item.tekla-maintenance-required
//   background-image: url('../images/tekla_favicon.png')
//   background-repeat: no-repeat
//   background-size 34px 34px
//   background-position top 14px right 14px

li.tekla-maintenance-required.tekla-maintenance-icon
  right: none
  top: none
  position: relative

.item-grid
  margin: 0 0

  &.infinite
    overflow: auto

  .container
    display: inline-block
    margin-bottom: default-margin*2
    
    @media mobile-horizontal-max
      margin-left: 0em
    
  .my-likes
    margin-top: 2em
    
.shopping-cart-actions
  position: absolute
  left: 312px
  top: 5px
  
  &.thumbmode
    left: auto
    right: 10px
    top: 5px
    
  .add-to-basket, .remove-from-basket
    height: 30px
    width: 30px
    margin: 0 0
    padding 0 0   
    

.maintenance
  position: absolute
  width: 25px 
  cursor: pointer
  top: 162px
  right: 4px
  
  &:hover
    width: initial
    right: 0px
    top: 160px

    &.listmode
      top: 220px
      width: 330px
      left: 1px
      z-index: 1
      text-align: center
      
      li
        list-style none
        position: absolute
        right: 0
      
      .icon-maintenance
        width: 100%
         
    li
      list-style none
      
    
    .icon-maintenance
      width: initial
      height: initial
      min-height: 25px
      pointer-events none
      background-color $yellow
      font-size 1.25em
      padding-top: 5px
      padding-left: 5px
      color white   
      
      .maintenance-text
        display: inline-block
        padding: 0px 5px 0px 5px
        font-size: 0.7em
        padding-bottom: 5px
        vertical-align: middle
      
      &.thumbmode
        width: initial
        padding-left: 5px
      
      &.thumbmode-details
        width: 28px 
        padding-left: 2px
        right: 0
  
  &.listmode
    top: 222px
    left: 306px
       
  li
    list-style none
  
  .icon-maintenance
    width: 25px 
    height: 30px
    pointer-events none
    background-color $yellow
    font-size 1.25em
    padding-top: 0px
    padding-left: 5px
    color white   
    
    .maintenance-text
      display: none
    
    &.thumbmode
      width: 30px
      padding-left: 0px
    
    &.thumbmode-details
      width: 28px 
      padding-left: 2px
      right: 0
      
  
.item-actions
  li
    list-style none
      
  .unlink-content
    position absolute
    left 0
    top 0
    color white
    padding-top 5px
    padding-left 8px
    height: 25px
    width: 16px
    background-color $default-text-color
    font-size 1em
    
    &:hover
      background-color $dark-text-color

  .icon-alert
    position absolute
    left 0.2em
    top 0
    color $default-text-color !important
    font-size 2em
    padding 0.3em 0 0 0

    &.unread
      color $yellow !important

      &:hover
        color $very-light-grey !important

  .icon-alert-lists
    position absolute
    left 0px
    top 0px
    color white !important
    font-size 2em
    padding-top: 0px
    padding-left: 3px
    font-size: 1.5em
    width: 27px
    height: 30px
    background-color $default-text-color
    
    &:hover
      cursor: pointer
    
    &.unread
      background-color $yellow
      color white !important
      
      &:hover
        cursor: pointer
        color white !important



  &:hover
    background-color $default-text-color
    border-color $default-text-color
    color white
    
    .description
      color: white
      
      p.sanitized-description
        color: white

    a, h3
      color white !important

    .icon-alert
      &:not(.unread)
        color white !important
    
.item
  background-color $almost-white
  position relative
  border 5px solid
  border-color $almost-white
  display block
  clearfix()
  
  &:hover
    background-color $very-light-grey
    border-color $very-light-grey

  h3
    margin-top: 0
    
  &.item-selected
    border-color $blue
    
    &:hover
      border-color $default-text-color
      
  &:not(.thumb-item)
    min-width: 21em
    
    @media tablet-horizontal-max
      width: 21em
        
  &.thumb-item
    margin 0 0
    display inline-block
    text-align center
    
    @media mobile-horizontal-max
      margin: 0em 0em
    
    &:hover
      background-color $very-light-grey
      border-color $very-light-grey

    .item-title
      width 100%
      text-align: left
      
    .company-name
      clear: both
      float: left
      width: 245px
      font-size: 0.75em
      text-align: left
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      
    .packages-count
      float: left
      padding 0em 0
      
      .icon-drawer
        float: left
        margin-top: 0.05em
        margin-right: 0.5em

    .tekla-maintenance-required
      margin-left 1em

    .thumb-box
      width 255px
      background-color white
        
    .thumb-box-wide 
      width 265px !important
      background-color white

      a.unlink, .icon-alert
        color $default-text-color !important

  .thumbnail[back-img]
    display: block
    width: 200px
    height: 200px
    float: left
    //margin: default-padding*2 0 default-padding*2 default-padding*2
    position: relative
    box-sizing: border-box
    overflow: hidden
    background-repeat: no-repeat
    background-position: center center
    &:hover
      cursor: pointer
    img
      display: block
      min-width: 100%
      min-height: 100%

  .thumb-box
    float: left
    min-height: 0

  .details
    display: table-cell
    padding: default-padding*2 default-padding*2
    text-align: left
    min-width: 20em
    
    .likes 
      float: left
      margin-right: 2em
    
    .company-name
      float: left
        
    .packages-count
      float: left
      margin-right: 2em
      
      .icon-drawer
        float: left
        margin-right: 0.5em

    @media tablet-max
      display: block

    span.user, a.user
      color: $link
      padding: default-padding 0
    .description
      width: 100%
      max-height: 3em
      overflow: hidden
      @media tablet-horizontal
        width: 21em

      p
        margin-top: 0
        color: $default-text-color

    ul.tags
      padding: default-padding 0
      max-height: 72px
      overflow: auto
      margin-top: default-margin

      li
        background-color: $light-ascent-color
        list-style: none
        color: $default-text-color
        font-size: .8em
        padding: default-padding*.5 default-padding
        margin: default-margin*.25
        display: inline-block

  a.item-title
    display: block
    width: 100%
    white-space: nowrap
    position: relative
    overflow: hidden
    text-overflow: ellipsis

  .thumb-item-details, .collection-item-details
    max-width 239px
    clear both
    padding default-padding
    overflow hidden
    
  .thumb-item-small-details
    height: 1em

  .thumb-item-details
    height 4em
    
    @media mobile-horizontal-max
      max-width: 109px
      
    @media tablet
      max-width: 195px
      
  .collection-item-details
    text-align left
    height 8em
    
    @media mobile-horizontal-max
      width: 180px

    .item-title
      height 2.75em
      white-space initial
      font-family font-normal
      padding 0

      &:before
        margin 0 default-padding*.5 0 0

      &:before
        margin 0 default-padding*.5 0 0
        
    .item-title-organization
      height 2.75em
      font-family font-normal
      padding 0

      &:before
        margin 0 default-padding*.5 0 0

      &:before
        margin 0 default-padding*.5 0 0

    .author
      height 4em
      overflow hidden
      text-overflow ellipsis
      font-size: .75em

      span
        margin-right default-padding*.5

    .items
      margin-top default-padding
      .item-count
        margin-left default-padding

  &.collection
    box-shadow 4px 4px 0 0 $very-light-grey, 5px 5px 0 0 $background-color, 10px 10px 0 0 $very-light-grey
    &.grid
      margin 2em 1em
  &.grid
    margin: .5em
    // height: 23em
    padding: 1px
    position: relative
    box-sizing: border-box
    clearfix()
    a
      text-align: center
      .thumbnail
        .icon, .icon
          padding: .5em
          font-size: 5em
          line-height: 1.5em
          &.folder
            color: $blue

    .thumbnail[back-img]
      width: 100%
      height: 100%
      min-width: 200px
      min-height: 240px
      margin: 0

      img
        display: block
        min-width: 100%
        min-height: 100%
      .icon.user-group
        padding: 1em
        font-size: 5em

    .collection
      width: 100%
      position: relative
      padding: 0
      box-sizing: border-box
      a, .label
        font-size: .9em
        font-family: font-bold
        text-overflow: ellipsis

    ol
      width: 100%
      position: relative
      overflow: hidden
      clarfix()
      margin-top: .5em
      li
        list-style: none
      li.items
        box-sizing: border-box
        float: left
        text-align: left
        width: 50%
        span
          display: inline-block
          line-height: 1.75em
          color: $very-dark-gray
          font-size: 1.25em
          &.item-count
            padding: 0 default-padding
            font-family: font-bold

      li.ratings
        box-sizing: border-box
        float: left
        text-align: left
        width: 50%
        clarfix()
        span.rate
          display: inline-block
          font-size: .95em
          .rating
            padding: default-padding 0

        span.count
          display: inline-block
          margin: default-margin
          font-size: .85em
          color: $very-dark-gray
          vertical-align: top

      li.view
        width: 50%
        float: right
        text-align: right
        a.button
          margin: 0
          width: 100%
          padding: default-padding 0
