.multi-range
  display: grid
  background-color:#0063a3
  border-radius: 0.5em

  input[type=range]
    grid-column-start: 1
    grid-row-start: 1
    appearance: none
    -webkit-appearance: none
    -moz-appearance: none
    pointer-events: none
    outline: none
    height: 0.5rem
    margin: 0
    border: 0
    background: transparent

    &::-webkit-slider-thumb
      height: 1rem
      width: 1rem
      background-color: white
      border: 0.125rem solid #217cbb
      border-radius: 50%
      position: relative
      cursor: pointer
      appearance: none
      pointer-events: all
      transition: 0.2s background-color

      &:hover 
        background-color: #217cbb

    &::-webkit-slider-runnable-track 
      z-index: 0

    &::-moz-range-thumb
      height: 1rem
      width: 1rem
      background-color: white
      border: 0.125rem solid #217cbb
      border-radius: 50%
      position: relative
      cursor: pointer
      appearance: none
      pointer-events: all
      transition: 0.2s background-color

      &:hover 
        background-color: #217cbb

    &::-moz-range-track
      z-index: 0