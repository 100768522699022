// inspired by  https://github.com/tobiasahlin/SpinKit

spinner-color(c)
  color: c
  
  &:before
    background-color: c

.spinner
  vertical-align baseline
  color $very-dark-gray
  font-family font-normal
  font-style normal
  font-variant normal
  font-weight 500
  white-space nowrap

  &:before
    content ''
    width 1.3em
    height 1.3em
    vertical-align middle
    background-color $very-dark-gray
    display inline-block
    margin 0em 1em 0.07em 0em
    -webkit-animation rotateplane 0.6s infinite ease-in-out
    animation rotateplane 0.6s infinite ease-in-out

.spinner-gray
  spinner-color($very-dark-gray)
.spinner-white
  spinner-color(white)
.spinner-blue
  spinner-color($blue)

@-webkit-keyframes rotateplane
  0%
    -webkit-transform perspective(120px)
  100%
    -webkit-transform perspective(120px) rotateY(180deg)

@keyframes rotateplane
  0%
    transform perspective(120px) rotateX(0deg) rotateY(0deg)
    -webkit-transform perspective(120px) rotateX(0deg) rotateY(0deg)
  100%
    transform perspective(120px) rotateX(0deg) rotateY(-179.9deg)
    -webkit-transform perspective(120px) rotateY(-179.9deg)

