#content
  .prerequisites ol.labels
    width: 100%
  ol.labels
    width: 50%
    box-sizing: border-box
    padding-right: 1em
    float: left
    &:last-child
      padding-right: 0

    @media tablet-max
      width: 100%
      float: none


input[type="text"], input[type="search"], input[type="tel"], input[type="email"], input[type="number"], input[type="url"], input[type="password"], input[type="date"], textarea, span[role="textbox"]
  padding: default-padding
  background: $very-light-grey
  border: 2px solid $very-light-grey
  margin: 0
  color: $default-text-color
  box-sizing: border-box
  font-family: font-normal
  
  &::-ms-clear
    display: none
    width: 0
    height: 0

  &.ng-invalid:not(.ng-pristine)
    border: 2px solid $warning

  &:not(.disabled):not(:disabled)
    &:hover
      background: $default-text-color
      border: 2px solid $default-text-color
      color: white

    &:focus
      border: 2px solid $blue

    &.white
      background: white
      border: 2px solid white

      &:hover
        background: $default-text-color
        border: 2px solid $default-text-color
        color: white

input[type="checkbox"]
  opacity: 0
  position: absolute
  pointer-events: none
  top: default-padding
  left: default-padding
  z-index: 10

  & + label
    font-size: 1em

    &:before
      icon-font()
      display: inline-block
      content: ''
      width: 22px
      height: 26px
      background: $very-light-grey
      border: 2px solid $very-light-grey
      margin-right: default-padding
      vertical-align: middle
      font-size: 19px
      line-height: 26px
      padding-left: 4px

    &:hover
      &:before
        background: $default-text-color
        border: 2px solid $default-text-color

  &:checked
    & + label
      &:before
        background: white
        border: 2px solid $blue
        content: '\e905'
        color: $blue

  &:indeterminate
    & + label
      &:before
        background: white
        border: 2px solid $blue
        content: '6'
        color: $blue
        font-family: 'Pictos' !important
        text-align: center
        line-height: 1.6em
        padding-right: 1px
        padding-left: 1px

  &.white
    & + label
      &:before
        background: white

      &:hover
        &:before
          background: $default-text-color
          border: 2px solid $default-text-color

    &:checked
      & + label
        &:before
          background: white
          border: 2px solid white
          content: '\e905'
          color: $blue
          
  &.white-blue-border
    & + label
      &:before
        border: 2px solid $blue
        background: white

      &:hover
        &:before
          background: $default-text-color
          border: 2px solid $default-text-color

    &:checked
      & + label
        &:before
          background: white
          border: 2px solid $blue
          content: '\e905'
          color: $blue
          
  &.search-result-menu
    & + label
      &:before
        background: white
        margin-top: -0.2em

      &:hover
        &:before
          background: $default-text-color
          border: 2px solid $default-text-color

    &:checked
      & + label
        &:before
          background: white
          border: 2px solid white
          content: '\e905'
          color: $blue

label
  display: inline-block
  font-size: .875em
  padding-right: default-padding
  color: $default-text-color
  &.block
    display: block

textarea
  resize: none
  width: 100%
  word-wrap break-word
  transition 0.05s
  -moz-transition 0.05s
  -webkit-transition 0.05s
  -o-transition 0.05s

dropdown
  padding: default-padding

.required:after
  content: '*'
  color: $blue
  margin-left: default-padding*.5
  
.release-note-file
  width: 50%

.tooltip-info
  .icon
    font-size: 1.2em
    cursor: pointer

.input-wrapper
  position relative
  overflow auto

span.error
  color: $warning
  font-family: font-semi-bold
  font-size: 1em

div.error
  background: $warning
  color: white
  small
    padding: .25em .5em
    display:block

form
  ul > li
    list-style: none
    margin-bottom: .75em
  ol > li
    list-style: none
    margin-bottom: .75em

.dropdown-wrapper
  &.light

    position: relative

    [class*="-container"]
      font-family: font-normal
      font-size: 1em
      border: none
      width: 100%
      min-width: 12em

      [class*="-indicatorSeparator"]
        display: none

      [class*="-control"]
        border: 1px solid $very-light-grey
        background-color: $white
        color: $dark-gray
        border-radius: 0;
        min-height: 36px
        height: 36px

        [class*="-placeholder"]
          color: $dark-gray

        &:hover
          cursor: pointer
          border-color: $dark-blue

        [class*="-singleValue"]
          color: $dark-gray

        [class*="-indicatorContainer"]
          color: $dark-gray

          [class*="-Svg"]
            height: 15px
            fill: $dark-gray

      [class*="-menu"]
        width: 100%
        margin-top: 0px

        &:hover
          cursor: pointer

        [class*="-MenuList"]
          padding-top: 0px

        [class*="-option"]
          background-color: white
          color: $default-text-color
          border-top-color: transparent
          min-height: 36px
          padding: 4px 12px

          &:hover
            color: $white
            background-color: $blue
            border: 1px solid $blue
            cursor: pointer

  &.dark
    
    position: relative
    
    [class*="-container"]
      font-family: font-normal
      font-size: 1em
      border: none
      position: absolute
      width: 100%
      min-width: 12em
      
      [class*="-indicatorSeparator"]
        display: none
      
      [class*="-control"]
        border: none
        background-color: $dark-gray
        color: white
        box-shadow: 1px 1px 5px rgba(54, 53, 69, 0.13);
        min-height: 36px
        height: 36px
        
        [class*="-placeholder"]
          color: white
              
        &:hover
          cursor: pointer
          border-color: $dark-gray
        
        [class*="-singleValue"]
          color: white
          
        [class*="-indicatorContainer"]
          color: white
          
          [class*="-Svg"]
            height: 15px
            fill: white
         
      [class*="-menu"]
        position: relative
        width: 100%
        margin-top: 0px
        
        &:hover
          cursor: pointer
        
        [class*="-MenuList"]
          padding-top: 0px
        
        [class*="-option"]
          background-color: white
          color: $default-text-color
          border-top-color: transparent
          min-height: 36px
          padding: 4px 12px
          
          &:hover
            background-color: $blue
            border: 1px solid $blue
            color: white
            cursor: pointer


    .select2-choice
      background-color: $dark-gray
      border: 1px solid $dark-gray
      color: white
      &:hover
        background-color: $blue
        border: 1px solid $blue

    .select2-default
      color: white !important

    .select2-dropdown-open
      .select2-choice
        background-color: $very-light-grey
        border: 1px solid $very-light-grey
        color: $default-text-color
        border: 1px solid $blue
        border-top-color: transparent


  &.gray

    position: relative

    [class*="-container"]
      font-family: font-normal
      font-size: 1em
      border: none
      width: 100%
      min-width: 12em

      [class*="-indicatorSeparator"]
        display: none

      [class*="-control"]
        border: 1px solid $very-light-grey
        background-color: $very-light-grey
        color: $dark-gray
        border-radius: 0;
        min-height: 36px

        [class*="-placeholder"]
          color: $dark-gray

        &:hover
          cursor: pointer
          border-color: $dark-blue

        [class*="-singleValue"]
          color: $dark-gray

        [class*="-multiValue"]
          background-color: $light-ascent-color

        [class*="-indicatorsContainer"]
          color: $dark-gray

          [class*="-Svg"]
            height: 15px
            fill: $dark-gray

      [class*="-menu"]
        width: 100%
        margin-top: 0px
        z-index: 3

        &:hover
          cursor: pointer

        [class*="-menuList"]
          padding-top: 0px

        [class*="-option"]
          background-color: white
          color: $dark-gray
          border-top-color: transparent
          min-height: 36px
          padding: 4px 12px

          &:hover
            color: $white
            background-color: $blue
            border: 1px solid $blue
            cursor: pointer

        [class*="-Group"]
        
          [class*="-option"]
            padding-left: 20px

.select2-hidden-accessible
  display: none;

.select2-container
  
  .select2-choice
    &:hover
      background-color: $blue
      border: 1px solid $blue
      color: white

      &.select2-default
        .select2-arrow
          color: white

  .select2-arrow
    &:before
      font-family: 'tekla-icomoon' !important
      content: "\e900"
      transform: rotate(90deg)
      position: absolute
      right: default-margin
      font-size: .8em

    b
      display: none !important

  .select2-search-choice-close
    background: initial !important
    color: $default-text-color
    font-size: .8em
    &:before
      font-family: 'tekla-icomoon' !important
      content: "\e610"
      vertical-align: middle

.select2-drop .select2-input
  font-family font-normal
  font-size .8em
  background-color white

  &:focus
    border 2px solid $light-ascent-color

.select2-results .select2-result-label
  white-space: nowrap
  background-color: white
  color: $default-text-color 
  
  &:hover
    background-color: $blue
    color: white
    

.command-textinput
  position: relative
  display: inline-block

  input[type="text"]
    padding: default-margin default-padding
    padding-right: 4.5em

  button, a.button
    position: absolute
    margin: default-padding !important
    margin-top: 10px !important
    right: 0
    top: 0
    padding: default-padding 1em !important

    &:disabled
      background-color: $almost-white !important
      color: $default-text-color !important

  .loading-indicator
    position: absolute
    margin: default-padding !important
    margin-top: 14px !important
    right: 0
    top: 0

