.crumbs
  background-color: $blue
  .tkl-breadcrumbs
    overflow hidden
    padding: 0 default-padding*3 0 default-padding*6
    max-width desktop
    margin 0 auto
    
    @media tablet-horizontal-max
      padding-left: 1em

    li
      display inline-block
      margin default-margin*1.5
      padding 0 default-margin*1.5 0 0
      white-space: nowrap
      
      @media tablet-horizontal-max
        white-space: initial

      span.icon
        padding-right default-padding

      a
        background transparent
        color white
        position relative
        font-family font-semi-bold
        text-transform uppercase
        font-size: .9em

        &:after
          font-family: 'tekla-icomoon'
          content "\e904"
          color $very-dark-gray
          position absolute
          font-size 1.5em
          margin-left default-margin
          
        .breadcumbs-local
          font-size: 1.5em
          vertical-align: middle

      &:last-child
        a
          &:after
            content none

      &.is-selected
        a
          color $very-dark-gray

