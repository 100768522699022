// Button mixin - needs cleaning up and rethinking, buttons generate ~500 lines of css atm
button(bg-color = $blue)
  display: inline-block
  padding: default-padding default-padding*2
  margin: default-margin
  margin-right: default-padding
  border: 0 none
  font-family: font-semi-bold
  font-size: 1em
  color: white
  line-height: 1em
  text-align: center
  text-decoration: none
  cursor: pointer
  background: bg-color

  &:not(.disabled):not(:disabled)
    &:hover
      background: $yellow
      color: white

    &:active
      background: $blue
      color: white

    &.selected
      color: white

  &.disabled, &:disabled
    background-color: $default-text-color !important
    color: white !important
    cursor: default

    &:active
      color: $very-light-grey !important

label(bg-color = $very-dark-gray )
  button(bg-color = $very-dark-gray )

// Font icon for a button
button-icon(content = "")
  if content != ""
    &:before
      icon(content)

  &:before, .icon
    margin: 0
    margin-right: default-padding*.5
    line-height: .1em

button-symbol(content = "")
  if content != ""
    &:before
      symbol(content)
      margin: 0
      line-height: .1em
      vertical-align: middle

icon-only()
  &:before
    margin-right: 0

.fileupload
  position: relative
  overflow: hidden
  padding: 0.5em 1em

.fileupload input
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  opacity: 0.001

input.hidden-file-upload
  //visibility: hidden
  //display: none
  opacity: 0
  position: absolute
  pointer-events: none
  top: default-padding
  left: default-padding

// Actual selectors for buttons
a.button, button, a.label.button, label.button
  button()

  &.tekla-maintenance-required
    background-color $very-dark-gray
    display border-box
    padding-top 0.7em
    text-transform uppercase
    color white
    font-family font-light
    font-size: 0.9em

  &.input-search
    button-icon("s")
    border: none
    color: $very-dark-gray
    background: transparent
    padding: default-padding*.5 0
    margin: default-padding
    &:not(.disabled):not(:disabled)
      &:hover
        background: transparent
        color: $very-dark-gray
        
  &.input-search-header
    border: none
    color: $very-dark-gray
    background: transparent
    padding: default-padding*.5 0
    margin: default-padding
    &:not(.disabled):not(:disabled)
      &:hover
        background: transparent
        color: $very-dark-gray

    &.disabled, &:disabled
      background-color: transparent

    &.disabled, &:disabled
      background-color: transparent

  &.clear-search
    button-icon('D')
    padding: default-padding default-padding
    position: absolute
    top: 0
    right: 0
    z-index: 5
    margin: default-padding

  &.upload
    background-color: $blue
    padding: default-padding*1.5 default-padding*3
    display: inline
    
  &.clear-upload
    padding: default-padding*1.5 default-padding*3


  &.delete, &.remove
    padding: default-padding default-padding*3 default-padding default-padding
    button-icon('#')

  &.move, &.move
    padding: default-padding default-padding*3 default-padding default-padding
    button-icon('R')

  &.create
    //button-icon('+')
    background-color: $blue
    font-size: 1.1em
    line-height: 1em

    &:before
      line-height: 1.1em

  &.publish
    background-color: $blue

  &.link
    background-color: $blue

  &.alert
    background-color: $blue

  &.edit
    button-icon('p')

  &.go-back
    padding: default-padding default-padding*3 default-padding default-padding
    button-icon('[')

  &.list
    button-icon('l')

  &.thumb
    button-icon('P')

  &.button-primary
    background-color: $blue

  &.cancel
    background-color: $very-dark-gray

  &.button-white
    background-color: white
    color: $blue
    
  &.button-red
    background-color: $red
    color: white

  &.button-large
    font-size 23px
    margin 5px
    //padding 0.62em 0.51em 0.3em 0.51em
    box-sizing: border-box
    padding: default-padding*1.3 default-padding*2.3

  &.button-small
    font-size 17px
    line-height 1.1em
    padding 0.37em 0.71em 0.12em 0.71em

  &.button-large-padding
    padding 1em 2em

  &.button-thin-padding
    padding default-padding*1.5 default-padding

  &.button-icon
    button-icon()

  &.icon-only
    icon-only()

label#choose-thumbnail
  display: inline
  min-width: 0

.buttonContainer
  display: block
  margin: 1em 0

.buttonContainer2
  display: block
  margin: 0.7em 0
  height: 1em

input[type="submit"]
  button($blue)
  
.toggle-button
  background-color: $very-light-grey
  display: inline-block

  button
    float: left
    margin: 0
    padding: default-margin
    line-height: default-margin
    color: white
    font-family: font-normal
    background: $very-dark-gray
    outline: 0
    icon-only()

    &:not(.disabled):not(:disabled)
      &:hover
        color: $blue
        background: $very-light-grey
        outline: 0

    &.active
      color: white
      background: $blue
      outline: 0

      &:not(.disabled):not(:disabled)
        &:hover
          color: white
          background: $blue
          outline: 0
          
   button.icon-thumbnails
     padding: default-margin - 0.02em
          
.toggle-search
  margin: 1em auto 0 auto

