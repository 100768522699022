.landing

  .rbt-sr-status
    display: none
  
  .add-to-basket, .remove-from-basket
    margin: 0 0
  
  ul.item-actions
  li
    list-style none
    
  ol
    padding revert

  .shopping-cart-actions
    &.thumbmode
      left: auto
      right: 5px
      top: 5px
  
  &.thumbmode
    left: auto
    right: 10px
    top: 5px
    
  .icon-upload  
    font-size: 1.2em
    color: $white !important
    float: left
    padding-left: 5px
    padding-top: 2px
    
  .search-icon
    &:before
      icon("s")
      
    font-size: 1.3em
    color: $blue !important
    border: none
    color: $white !important
    background: transparent
    padding-left: 5px
    float: left
    
    &:not(.disabled):not(:disabled)
      &:hover
        background: transparent
        color: $very-dark-gray
  
  

  .verify-account-box
    padding: default-padding default-padding*20 default-padding*3
    background-color: $almost-white
    
    .verify-account-textarea
      text-align: center
      margin-left: 0em
      width: 60em
      
      .warning-sign
        margin-top: -0.2em
        float: left
        
       .warning-text
        text-align: left
        margin-bottom: 0.1em
        margin-left 0em
        
      .info-text
        text-align: left
        margin-left: 3.5em
         
       .info-text-upper
        text-align: left
        margin-left: 3.5em
        text-transform: uppercase
        
       .info-text-with-link
        text-align: left
        margin-left: 3.5em
        
  section.action-links
    width: 100%
    margin: 0
    font-family: font-normal
    box-sizing: border-box
    display: table
    background-color: $almost-white
    
    &.no-recommended-list
      background-color: white
    
    .left-column
      width: 10%
      display: table-cell
      
    .search-link, .upload-link
      width: 15%
      display: table-cell
      min-height: 10em
      text-align: center
      vertical-align: top
        
      .action-items
        padding: 1em 1em
        
        &.no-recommended-list
          padding-top: 3em
        
        .search-title, .upload-title
          margin: 0em 0.5em 1em 0.5em
          display: block
          color: $dark-text-color
          font-family: font-semi-bold
          
        button
          width: 125px
          min-width: 5em
          height: 3em
          margin: 0 auto 1em
          display: block
          
    .middle-column
      width: 0.5%
      display: table-cell
      
    .right-column
      width: 10%
      display: table-cell
      
           
  section.video-links
    margin: 3em 1em 0em 0em
    background-color: $very-light-grey
    font-family: font-normal
    text-align: center
    color: $default-text-color
    float: left
    width: 100%
     
    .video-links-area
      max-width: 1440px
      margin: 0em auto 0em auto
      
         
      .title
        width: 100%
        font-size: 2em
        min-height: 2em
        text-align: center
        margin: 2.5em 0em 0em 
        
        a 
          font-family: font-normal
      
        
      .link
        margin-bottom: 2em
      
      .video-items
        float: left
        margin-bottom: 3em
        text-align: left
        margin-left: 1em
        
        @media mobile-horizontal-max      
          width: initial
        
        .video-item
          display: inline-block
          padding-left: 1em
          float: left
          vertical-align: top
          
          .desc-area
            display: block
            width: 213px
            margin-top: 0.5em
            margin-bottom: 1em
  
          
  .content
    width: 100%
    margin: 3em 0em 0em 0em
    display: inline-block
    box-sizing: border-box

    @media mobile-horizontal-max      
      width: initial

    
    .content-area
      max-width: 1440px
      margin: 0em auto 0em auto
      
    .title
      width: 100%
      min-height: 2em
      float: left
      font-size: 2em
      text-align: center
      margin: 1em 0em 0em
    
      .title-link
        font-family: font-normal
        color: $blue
      
    .spinner
      text-align: center
      margin-bottom: 1em
    
    .content-items-section
      display: flex
      flex-wrap: wrap
      width: 100%
      justify-content: center
      
      .previous-content
        font-family: font-normal
        font-size: 4em
        float: left
        margin-top: 2em
        padding-right: 0.5em
      
      .next-content
        font-family: font-normal
        font-size: 4em
        float: left
        margin-top: 2em
        padding-left: 0.7em
      
      .content-items
        position: relative
        float: left
        display: inline-block
        margin: 1em 0em 1em 1em
      
  .partners
    float: left
    width: 100%
    background-color: $almost-white
    display: inline-block
    box-sizing: border-box
    margin-top: 3em
    
    .partner-area
      max-width: 1440px
      margin: 0em auto 0em auto
    
    .title
      width: 100%
      font-size: 2em
      text-align: center
      margin: 2em 0em 1em
      
      .title-link
        font-family: font-normal
        color: $blue
      
    .spinner
      text-align: center
      margin-bottom: 1em
      
    .partner-items-section 
      margin-bottom: 3em
      text-align: center
      
      .partner-items
        display: inline-block
        margin: 1em 1.5em 0em 1em  
        
        img
          height: 60px
          width: auto
          max-width: 400px
    
          @media mobile-horizontal-max
            height: 50px
            max-width: 300px
      
  .release-notes-box
    padding: default-padding 10% default-padding*3
    background-color: $blue
    
    @media mobile-horizontal-max
      padding: default-padding 10% default-padding*3 0em
    
    .release-notes-textarea
      margin-left: 0em
      width: 95%
      color: white
      margin-top: 1em
      
      .release-note-heading-star
        float: left
        font-family: font-semi-bold
        font-size: 2em
        margin-top: -0.05em
      
      .release-note-heading
        float: left
        margin-left: 0.5em
        font-family: font-semi-bold
        
      .release-note-close
        float: right
        margin-right: -3em
        font-family: font-semi-bold
        color: white
        
    .release-notes-link-area
      margin-left: 2.5em
      color: white
      margin-top: -2em
        
      .release-notes-link
        font-family: font-normal
        margin-top: 1.5em
        
        a
          color: $yellow
          padding: 0 default-padding 0 0
          
          &:hover
            color: white
    

  .development-intro
    text-align: center
    padding: default-padding*3 default-padding*20

  .search
    margin-bottom: 0

  .hero-intro
    text-align: center
    background-color: $blue
    overflow: visible
    width: 100%
    background-image: url('../../images/warehouse-front-page.jpg') !important
    background-repeat: no-repeat !important
    background-size: cover
    padding: default-padding*2 0
    display: table
    
    h1, p
      color: white

    h1
      font-size: 3.85em
      display: inline-block
      margin-right: 0.3em
      
    p
      font-size: 1.25em
      line-height: 1.75em
      
    .icon-area
      vertical-align: middle
      display: inline-block
      font-size: 3.85em
      color: white
      
      .icon-warehouse
        margin-bottom: 0.4em
        
    .intro-text
      width: 40%
      max-width: 1440px
      margin 1em auto 1em auto
      
      
      @media tablet-horizontal-max
        width: 80%
        margin-left: 5%
      
      
    .search-area
      max-width 555px
      width: 95%
      margin: -2em auto 2.25em
      position: relative
      
      .search-form
        margin-top: 1em
        
      li
        list-style: none
                
      input
        width: 100%
        background-color: white
        border: 2px solid $blue
        
      button.input-search
        position: absolute
        top: 0
        right: 0
          

      .clear-search
        min-width: initial

        &.icon-remove
          font-size: 12px
          position: absolute
          top: 14px
          right: 35px
          color: $very-dark-gray
          cursor: pointer
          
  .hero1
    background-image: url('../../images/2015-03-Warehouse-carousel-1.jpg') !important
    background-repeat: no-repeat !important
    background-position: 0 0
  .hero2
    background-image: url('../../images/2015-03-Warehouse-carousel-2.jpg') !important
    background-repeat: no-repeat !important
    background-position: 0 0
  .hero3
    background-image: url('../../images/2015-03-Warehouse-carousel-3.jpg') !important
    background-repeat: no-repeat !important
    background-position: 0 0

  .hero-slider
    //height: 400px
    overflow: visible
    clearfix()

  .hero-animation-next, .hero-animation-prev
    position: absolute
    width: 100%
    left: 0
    overflow-x: hidden

    -webkit-transition: 500ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all
    -moz-transition: 500ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all
    -ms-transition: 500ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all
    -o-transition: 500ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all
    transition: 500ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all

  .hero-animation-next.ng-enter
    left: 100%

  .hero-animation-next.ng-leave
    left: 0

  .hero-animation-next.ng-enter.ng-enter-active
    left: 0

  .hero-animation-next.ng-leave.ng-leave-active
    left: -100%

  .hero-animation-prev.ng-enter
    right: 100%

  .hero-animation-prev.ng-leave
    right: 0

  .hero-animation-prev.ng-enter.ng-enter-active
    right: 0

  .hero-animation-prev.ng-leave.ng-leave-active
    right: -100%

  .hero-nav
    position: relative
    top: 380px
    text-align: center
    width: 100%
    button
      padding: 0.3em
      margin: 0 0.3em
      border-radius: 50%
      border: 1px solid transparent
      overflow: hidden
      background: white
      box-shadow: 0 0 5px $dark-gray


  section.nav-tabs > ul
    margin: 0 auto
    border-bottom: none
    li
      //padding: 1em 0
        
  .dev-site-info
    margin: 3em 0 0 0
    padding: default-padding*10 0 default-padding*6 0
    background-color: $almost-white
    display: table
    width: 100%
    box-sizing: border-box
           
    .content-area
      max-width: 1440px
      width: 90%
      margin: 0 auto 0 auto
      display: flex
      align-items: center
      justify-content: center
      flex-wrap: wrap

      .dev-site-media, .dev-site-text
        padding-top: 0.5em
        padding-bottom: 0.5em
      
      .dev-site-media
        margin: 0em 2em 0em 0em
        max-width: 450px
        display: flex
        justify-content: center

        @media tablet-horizontal-max
          width: 75%
          margin: 0
        
        @media mobile-horizontal-max
          width: 100%
        
        
        img
          width: 100%

          @media tablet-horizontal-max
            padding-bottom: 2em
          
          &.campaign
            max-width: 500px
            margin-top: 0.5em
            
            @media tablet-horizontal-max
              max-width: 460px
              
            @media mobile-horizontal-max
              max-width: 300px
              
        video
          max-width: 460px
          margin-top: 0.5em
          
          @media mobile-horizontal-max
            max-width: 300px
        
        @media mobile-horizontal-max
          margin-right: 0em
          
      .dev-site-text
        width: 50%
        float: left

        @media tablet-horizontal-max
          width: 75%
        
        @media mobile-horizontal-max
          width: 100%
        
        .title
          font-size: 1.5em
          color: $blue
          margin-bottom: 1em
          text-align: left
          font-family: font-normal
          margin-top: -5px
          margin-left: 0px
          
        .description
          width: 100%
          margin-bottom: 1em
          
          @media tablet-horizontal-max
            width: 100%
         

  .videos
    margin: 3em 0 0 0
    padding: default-padding*10 0 default-padding*6 0
    background-color: $almost-white
    display: table
    width: 100%
    box-sizing: border-box
    
    .video-item 
      background-color: #000;
      position: relative;
      display: block;
      contain: content;
      background-position: 50%;
      background-size: cover;
      cursor: pointer;

      @media tablet-horizontal-max
        padding-bottom: 2em

    .video-item:before 
      content: "";
      display: block;
      position: absolute;
      top: 0;
      background-position: top;
      background-repeat: repeat-x;
      height: 60px;
      padding-bottom: 50px;
      width: 100%;
      transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    
    .video-item:after 
      content: "";
      display: block;
      padding-bottom: 56.25%;
    
    .video-item > iframe 
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    
    .video-item > .play-btn 
      width: 70px;
      height: 46px;
      background-color: #212121;
      z-index: 1;
      opacity: 0.8;
      border-radius: 14%;
      transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    
    .video-item:hover > .play-btn 
      background-color: red;
      opacity: 1;
    
    .video-item > .play-btn:before 
      content: "";
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 11px 0 11px 19px;
    
    .video-item > .play-btn,
    .video-item > .play-btn:before 
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
           
    .content-area
      max-width: 1440px
      width: 90%
      margin: 0 auto 0 auto
      display: flex
      align-items: center
      justify-content: center
      flex-wrap: wrap

      .videos-link, .videos-text
        padding-top: 0.5em
        padding-bottom: 0.5em
      
      .videos-link
        float: left
        margin: 0em 2em 0em 0em
        width: 450px

        @media tablet-horizontal-max
          margin: 0em 0em 2em 0em
        
        iframe
          width: 450px
          height: 255px
          border: 0
                    
          @media mobile-horizontal-max
            max-width: 300px
                                                  
      .videos-text
        width: 50%
        float: left
        
        @media tablet-horizontal-max
          width: 75%
        
        @media mobile-horizontal-max
          width: 100%
          
        .title
          margin-top: -0.25em
          font-size: 1.5em
          font-family: font-semi-bold
          
        .description
          margin-top: 1.5em
          
          @media tablet-horizontal-max
            width: 100%
           
  .news
    clear: both
    float: left
    margin-top: 3em
    width: 100%
    box-sizing: border-box
    
    .title
      width: 100%
      font-size: 2em
      text-align: center
      margin: 0.5em 0em 1em 
        
      a 
        font-family: font-normal
      
    .news-content
      float: left
      margin: 0em 3em 0em 5em
      
      .news-items
    
        .left-column
          width: 48%
          margin-left: 1em
          float: left

          @media tablet-horizontal-max
            width: 100%
           
        .right-column
          width: 50%
          float: left
          
          @media tablet-horizontal-max
            width: 100%
            margin-left: 1em
          
        .news-link
          float: left
          width: 255px
          height: 191px
          overflow: hidden
          float: left
          margin-right: 1em
          margin-bottom: 1em
        
        .news-text
          float: left
          width: 60%
          float: left
          
          @media mobile-horizontal-max
            width: 100%
          
          .news-item-title
            font-size: 1.5em
            color: $blue
            margin-bottom: 1em
            text-align: left
            font-family: font-normal
            margin-top: 0px
            margin-left: 0px
            
            
          .news-item-description
            width: 80%
            margin-bottom: 1em
            
            @media tablet-horizontal-max
              width: 100%
            
  .statistics
    clear: both
    float: left
    width: 100%
    background-color: $blue
    color: $almost-white
    font-size: 2em
    font-family: font-normal
    border: 1px solid $dark-gray
    box-sizing: border-box
    
    .title 
      padding: 1.5em 0 0 0
      text-align: center
      font-family: font-normal
      margin: 1em
      
    .stat-content
      width: 100%
      text-align: center
      display: table
      margin-top: 1.5em
      margin-bottom: 1.5em
              
      .middle-column
        width: 100%
        display: table-cell
      
        .stat-items
          display: inline-block
          border: 1px solid $dark-blue
          border-radius: 50%
          background-color: $dark-blue
          width: 4.5em
          height: 4.5em
          min-width: 4.5em
          min-height: 4.5em
          margin-left: 1em
          margin-bottom: 0.5em
        
        .stat-count
          font-family: font-normal
          display: block
          margin-top: 2em
          font-size: 0.75em
          
        .stat-title
          display: block
          font-size: 0.5em
          font-family: font-normal
          margin-top: 0.5em
        
      .right-column
        width: 30%
        display: table-cell
    
  .suggestions
    text-align: center

    .suggestion-links
      background: $very-light-grey
      padding: default-padding*6 10%

      @media tablet-min
        padding: default-padding*6 15%

      input[type="text"], input[type="search"]
        background-color: white
        border: 2px solid white

        &:focus
          border: 2px solid $blue
          color: $default-text-color

        &:hover
          color: $default-text-color

      .autocomplete-drop
        background-color: white

      a.link-item
        margin: (default-margin/2) (default-margin/2)
        padding: default-padding default-padding*3
        line-height: 1.8em
        display: inline-block
        color: $default-text-color
        font-family: font-normal
        font-size: 0.9em
        background-color: $light-ascent-color

    .suggestion-items
      margin: auto

    .browse-buttons .tabs
      padding: 0 1em

  section.results-container
    header
      text-align: center !important
      h2
        line-height: 3em
        font-size: 3em

.temporary-info-box
  padding: default-padding*3 default-padding*10 default-padding*3
  background-color: $dark-blue
  
  .temporary-info-textarea
    text-align: center
    margin-left: 0em
      
    .info-text-with-link
      color: $almost-white

      a
        color: $yellow

      a:hover
        color: $dark-gray

  &.yellow
    background-color: $yellow

    .temporary-info-textarea
      .info-text-with-link
        color: black

        a
          color: $light-blue

        a:hover
          color: $dark-gray

  &.light-blue
    background-color: #005f9e