#results
  padding: default-padding*8 default-padding*8 default-padding*4 default-padding*8
  margin: 0 auto
        
.cart-header
  text-align: center
  margin: 1em 1em 1em 0em
  font-size: 2.5em
  font-family: font-normal
  color: $dark-text-color
  

section.shopping-cart-results-container
  max-width: 1440px
  padding: 2em 2em 2em 4em
  margin: 0 auto 0 auto
  
  ul.item-actions
    li
      list-style none
        
      .icon-maintenance
        left: 306px
        top: 222px
        
        &.thumbmode
          width: 28px
          padding-left: 2px !important
          top: 161px
          right: 0px
          left: auto
          
    
  @media tablet-horizontal-max
    width: initial
    margin: 1em 1em 1em 1em
    padding: 2em 2em 2em 1em
  
  .result
    margin-bottom: default-margin*2
    margin-top: 1.5em
    position: relative

    .selection
      &.selected
        background-color: $blue

      label
        padding-right: 0
        :before
          margin-right: 0

  &:not(.as-thumbnails)
    .result
      .selection
        padding: default-padding*2
        margin: 0
        position: absolute
        top: 0.3em
        left: 18.4em
        width: default-padding*7
        text-align: left
        box-sizing: border-box
        
      .selection-seach-results
        font-family: font-semi-bold
        padding: default-padding*2
        margin: 0
        position: absolute
        top: 0
        bottom: 0
        width: default-padding*24
        text-align: center
        box-sizing: border-box
        margin-top: 6em
        

  &.as-thumbnails
    text-align: left
    clear: both

    .result
      display: inline-block
      vertical-align: top
      width: 290px

      .selection
        padding: 0
        margin: 0 0
        position: absolute
        top: 5px
        right: 22px
        

    .summary
      text-align: left
      
  &.no-entities
    margin-top: -8em

  .button-primary
    padding: default-padding*2 default-padding*6

  .clear-alerts
    margin-bottom: -5px
    

section.suggestions, section.results-container
  .load-indicator
    margin: default-margin*3 auto
    text-align: center
    max-width: 6em

  header
    text-align: initial

  .listing-wrapper
    text-align: center

    .search-results-tabs
      text-transform: uppercase
      font-family: font-semi-bold
      font-size: 0.9em
      height: 4em


      .search-result-tab
        float: left
        width: 18em
        border: 1px solid $very-light-grey
        border-bottom: none
        height: 4em
        margin-left: default-margin*2
        background-color: $blue
        color: $almost-white
        cursor: pointer

        &:first-child
          margin-left: 1em

        &:hover
          background-color: $yellow

        .result-tab-icon
          font-size: 2em
          margin-top: 0.2em

        &.active
          background-color: white
          color: $default-text-color
          
    .search-target
      text-align: left
      margin-bottom: 1em
      font-family: font-normal
      font-size: 1em
      
      label
        &:not(.button)
          display: block
          padding: default-padding 0
          font-size: 1em
          color: $default-text-color
          margin-left: 0.1em
          margin-right: 1em

    .select-list-mode
      text-align: left
      margin-top: 2em
      margin-bottom: 2em
      
      label
        margin-left: 0.2em
        margin-right: 0.7em
        
        &:not(.button)
          display: block
          padding: default-padding 0
          font-size: 1em
          color: $default-text-color

          &.icon
            margin-top: 1em
            &:before
              margin: 0 default-padding*.5
              
    .download-install
      text-align: left
      margin-bottom: default-margin
      clearfix()

      .version-buttons
        float: left
        
        .error-messages 
          font-size: 0.7em
          
        .requires-maintenance
          font-size: 0.7em
          width: 27.5em
          
          .icon-maintenance
            float:left
            color: $yellow
          
          .maintenance-text
            margin-left: 0.1em
            float: right

        button:first-child
          margin-left: 0

    .results-summary
      
      .tested-versions-filter-info
        float:left
        margin-top: 2em
        margin-left: -13.4em
      
      clearfix()
      margin-bottom: default-margin*2
      font-family: font-semi-bold

      .results-count
        float: left
        text-transform: uppercase
        padding-top: default-padding
        font-family: font-semi-bold

      .paging-options
        float: right

        span
          font-family: font-semi-bold

        .paging-dropdown
          display: inline-block
          min-width: 6em
          text-align: initial
        .paging-type-selection
          margin-left: 1em
          margin-top: 0.25em

    .results
      clearfix()

