a.icon
  padding: 0 .5em
  color: $very-dark-gray
  text-decoration: none
  &:hover
    color: darken(gray, 10)

  span
    margin: 0 default-padding*.5

.icon
  font-variant: normal
  text-transform: inherit
  display: inline-block
  &.plus:before
    content "+"
    font-family: "Pictos"
    font-size: 0.9em
  &.minus:before
    content "-"
    font-family: "Pictos"
    font-size: 0.9em
  &.user:before
    content: "U"
    font-family: "Pictos"

  &.info:before
    content: "i"
    font-family: "Pictos"

  &.email:before
    content: "M"
    font-family: "Pictos"

  &.user-group:before
    content: "g"
    font-family: "Pictos"
    font-size: 1.5em
  &.userGroup:before
    content: "g"
    font-family: "Pictos"
    // font-size: 1.5em
  
  &.help
    color: $blue
    &:hover
      color: $yellow
    

  &.help:before
    content: "?"
    font-family: "Pictos"
    margin: 0 default-padding

  &.support:before
    content: "F"
    font-family: "Glyphish"
    font-size: 1.75em
    vertical-align: top

  &.forum:before
    content: "D"
    font-family: "Glyphish"
    font-size: 1.75em
    vertical-align: bottom

  &.public:before
    content: "G"
    font-family: "Pictos"
    font-size: 1.5em
    margin: 0 default-padding*.5

  &.private:before
    content: "("
    font-family: "Pictos"
    font-size: 1.5em
    margin: 0 default-padding*.5

  &.settings:before
    content "y"
    font-family "Pictos"

  &.notifications:before
    content "M"
    font-family "Pictos"
  &.filters:after
    content "~"
    font-family: "Glyphish"
    font-size: 1.75em
    vertical-align: bottom
  &.folder:before
    content "o"
    font-family "Pictos"
  &.created:before
    content "t"
    font-family "Pictos"
  &.list:before
    content "l"
    font-family "Pictos"
  &.thumb:before
    content "P"
    font-family "Pictos"
  &.clear-search:before
    content "D"
    font-family "Pictos"
  &.unlink:before
    content "D"
    font-family "Pictos"
  &.charts:before
    content "Z"
    font-family "Pictos"
  &.news:before
    content "N"
    font-family "Pictos"
  &.move:before
    content "R"
    font-family "Pictos"

icon-font()
  font-family: 'tekla-icomoon' !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: .75em

  // Better Font Rendering
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

[class^="icon-"], [class*=" icon-"]
  &:before
    icon-font()

.icon-pencil
  &:before
    content "\e614"

.icon-pencil2
  &:before
    content "\e615"

.icon-image
  &:before
    content "\e616"

.icon-folder
  &:before
    content "\e600"

.icon-folder-open
  &:before
    content "\e601"

.icon-image2
  &:before
    content "\e602"

.icon-checkbox-unchecked
  &:before
    content "\e613"

.icon-file
  &:before
    content "\e603"

.icon-checkbox-checked
  &:before
    content "\e612"

.icon-file2
  &:before
    content "\e604"

.icon-clock
  &:before
    content "\e605"

.icon-drawer
  &:before
    content "\e606"

.icon-drawer2
  &:before
    content "\e607"

.icon-users
  &:before
    content "\e608"

.icon-user
  &:before
    content "\e92b"

.icon-lock
  &:before
    content "\e60a"

.icon-unlocked
  &:before
    content "\e60b"

.icon-cog
  &:before
    content "\e60c"

.icon-bars
  &:before
    content "\e60d"

.icon-close, .icon-remove
  &:before
    content "\e610"

.icon-plus
  &:before
    content "\e611"

.icon-organization
  &:before
    content "\e617"

.icon-checkbox-partial
  &:before
    content "\e618"

.icon-search-filter-local
  &:before
    content "\e90e"

.icon-related-content
  &:before
    content "\e90f"

.icon-search-filter-online
  &:before
    content "\e908"

.icon-like
  &:before
    content "\e61a"

.icon-liked
  &:before
    content "\e61b"

.icon-link
  &:before
    content "\e61c"

.icon-maintenance
  &:before
    content "\e61f"

.icon-facebook
  &:before
    content "\e620"

.icon-twitter
  padding-top: 2px !important
  &:before
    content url("/images/twitter-x.svg")

.icon-linkedin
  &:before
    content "\e621"

.icon-youtube
  &:before
    content "\e622"
    
.icon-alert
  &:before
    content "\e90c"

.icon-alert-header
  &:before
    content "\e92c"

.icon-tekla-logo
  &:before
    content "\e902"

.icon-print
  &:before
    content "\e903"

.icon-triangle-right
  &:before
    content "\e900"
    
.icon-trash
  &:before
    content "\e901"

.icon-arrow-right
  &:before
    content "\e904"
    
.icon-arrow-down
  &:before
    content "\e904"
    display: inline-block
    transform: rotate(90deg)

.icon-check
  &:before
    content "\e905"

.icon-tekla-online
  &:before
    content "\e906"

.icon-location
  &:before
    content "\e907"

.icon-public-collection
  &:before
    content "\e909"

.icon-warehouse
  &:before
    content "\e90a"
    
.icon-download
  &:before
    content "\e90b"
    
.icon-upload
  &:before
    content "\e92a"
    
.icon-thumbnails
  &:before
    content "\e90d"
    
.icon-triangle-down
  &:before
    content "\e910"
    
.icon-warehouse-content
  &:before
    content "\e911"
    
.icon-star
  &:before
    content "\e912"
    
.icon-help
  &:before
    content "\e913"
    color $yellow

.icon-help-cart
  &:before
    content "\e913"
    float:left
    color $yellow
    font-size: 2em
    margin-right: 0.2em
    margin-top: 0.12em
    
.icon-install
  &:before
    content "\e914"
      
.icon-view-comment
  &:before
    content "\e90c"
    float:left
    color: $almost-white
    font-size: 1.4em
    margin-left: 0.17em
    margin-top: 0.35em
    
.icon-help-details
  &:before
    content "\e913"
    float:left
    color $yellow
    font-size: 2.2em
    margin-right: 0.2em
    margin-top: 0.1em
    
.icon-globe
  &:before
    content "\e92e"
        
.icon-trimble-logo
  &:before
    content "\e929"
    
.icon-search
  &:before
    content "\e915"
        
.icon-services
  &:before
    content "\e906"
    
.icon-hamburger
  &:before
    content "\e930"

h1
  .icon
    font-size: 1.6em

i
  display: inline-block
  font-style: normal
  font-size: .8em
  padding: default-padding*.25
  &.icon-star:before
    content "S"
    font-family "Pictos"
    &.disabled
      color: $very-light-grey

  &.icon-star-empty:before
    content "S"
    font-family "Pictos"
    color: $very-light-grey
