table:not(.simple)
  width: 100%
  margin-bottom: 1em
  thead
    tr
      background: white
      color: $blue

      th
        text-align: left
        text-transform: uppercase
        font-family: font-semi-bold
        padding: default-padding*2 default-padding*2

        a.icon
          color: $blue

  tbody
    tr
      &:nth-child(even)
        background: transparent
      &:hover
        color: white
        background: $blue
        

      td
        font-size: .9em
        padding: default-padding default-padding*2
        vertical-align: top
        &.text
          min-width: 20em
        &.numeric
          text-align: right
        a.button, button
          margin: 0 !important
          
        @media mobile-horizontal-max
          padding: 1% 0.9%

  th.auto-width, td.auto-width
    width 1%

  input[type="text"], select, .select2-container
    min-width: 10em
    // background: none
  textarea
    height: 2.25em !important
    min-width: 20em
  input[type="number"]
    min-width: 6em
  input[type="file"]
    width: 13em
    padding: default-padding 0
.scrollable-table
  position: relative
  width: 100%
  overflow-x: auto
  height: auto
  overflow-y: inherit
  margin-bottom: 0em
  padding: 1em 0

  input[type="text"]
    width: 15em
  input[type="number"]
    width: 10em
  textarea
    width: 20em

@media tablet-max
  .no-more-tables table,
  .no-more-tables thead,
  .no-more-tables tbody,
  .no-more-tables th,
  .no-more-tables td,
  .no-more-tables tr
    display block

  .no-more-tables
    thead
      tr
        position absolute
        top -9999px
        left -9999px
    tr
      border 1px solid #ccc
    td
      border none
      border-bottom 1px solid #eee
      position relative
      padding-left 50%
      white-space normal
      text-align left
      &:before
        position absolute
        top 6px
        left 6px
        width 45%
        padding-right 10px
        white-space nowrap
        text-align left
        font-weight bold
        content attr(data-title)

