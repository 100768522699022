// Icons, Pictos
icon(content="")
  font-family: "Pictos", arial
  text-decoration: none
  text-transform: none
  speak: none
  padding-right: 0
  content: content
// Icons Glyphish
symbol(content="")
  font-family: "Glyphish", arial
  text-decoration: none
  text-transform: none
  speak: none
  padding-right: 0
  content: content
