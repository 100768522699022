.searchbar-container
  width: 100%
  
  &.narrowmode
    width: 19%
    min-width: 13em
    
    @media mobile-horizontal-max
      width: 100%

.catalog-searchbar
  background-color: $blue
  text-align: center
  padding: default-padding*6 10% default-padding*3 10%

  h1
    margin-top: 0
    font-size: 3.75em
    color: white

  section.creator-filter
    width 60%
    text-align initial
    margin 0 auto

    .select2-container-multi .select2-choices
      border none
      background-color transparent

  section.search
    width 60%
    max-width: 40em
    margin: default-margin*2 auto 2.25em
    position: relative
    margin-top: 0.75em
    
    &.narrowmode
      width: 100%

    input
      background-color: white
      border: 2px solid $blue

    .clear-search
      min-width: initial

 

