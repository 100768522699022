h1, h2, h3, h4
  color $dark-text-color
  margin default-margin 0
  font-weight 300
  font-family font-light
  line-height 1.4em

h1
  font-size 40px
  line-height 48px
  margin-top: 80px

h2
  font-size 32px
  line-height 40px
  margin-top: 64px

h3
  font-size 24px
  color $dark-text-color
  line-height 32px
  margin-top: 48px
  &> a
    text-decoration: underline
    color $blue
h4
  font-family font-semi-bold
  font-weight 600
  text-transform uppercase
  font-size 16px
  line-height 24px
  margin-top: 33px

p, body, a
  color $default-text-color
  font-family font-normal
  font-style normal
  font-variant normal
  font-weight normal

.font-light
  font-family font-light
  font-weight 300

.font-normal
  font-family font-normal
  font-style normal
  font-variant normal
  font-weight normal

.red
  color $red !important
.underline
  text-decoration: underline
.blue
  color $blue !important
.yellow
  color $yellow !important
.white
  color white
.small
  font-size .75em
  line-height 1.45
.dark
  color $dark-gray
.darker
  color $darker-gray
.dark-text
  color $dark-text-color

a
  color $blue
  cursor auto
  font-family font-semi-bold
  text-decoration none
  &:hover
    color $yellow
  &:hover
    text-decoration none
    color $yellow
    cursor pointer
  &.is-disabled
    text-decoration none
    cursor default

.label
  font-family font-bold
  margin-right default-padding
  color $dark-text-color
  text-transform uppercase

.large
  font-size 1.25em

.white-warm
  color $very-light-grey
  &:hover, &:visited
    color $very-light-grey

.bold
  font-family font-bold !important
  font-weight bold !important
.semi-bold
  font-family font-semi-bold !important
  font-weight 600 !important

.italic
  font-style: italic !important

.flex-space-between
  display: flex
  justify-content: space-between
  align-items: baseline

.text-align-end
  text-align: end