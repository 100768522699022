[class*="-tabs-container"]
  .tabs
    padding: default-padding
    box-sizing: border-box
    clearfix()

    ul
      max-width: 1440px
      margin: 0 auto
      
    ul.details-tabs
      width: 100%
      max-width: 1440px
      margin: 0 auto 0 auto

    .tab-item
      a
        float: left
        margin-left: default-margin*.25
        font-family: font-light
        font-weight: 300
        font-size: 22px
        line-height: 1.2em
        outline: 0
        padding: default-padding 0.75em

        .icon
          margin-right: default-padding
          line-height: .9em

        .icon-related
          margin-right: default-padding
          vertical-align: bottom
          margin-bottom: 0.05em

        .icon-text
          margin-left: -0.4em

  .tab-view
    padding: default-padding*2 default-padding*3

.main-tabs-container
  flex: 1

  .tabs
    padding: default-padding default-padding*3 0 default-padding*8
    background-color: $blue
    
    @media tablet-horizontal-max
      padding-left: 1em


    .tab-item
      a
        color: white

        &:hover
          background-color: $yellow

      &.active
        a
          background-color: white
          color: $blue

  .tab-view
    max-width: 1440px 
    background-color: white
    padding: 1em 2em
    
    @media mobile-horizontal-max
      padding: 1em 1em
    

.details-tabs-container
  .tabs
    padding: default-padding default-padding*8 0 6em
    background-color: $very-light-grey
    
    &.organization
      padding-left: 6.5em
      margin-top: 3em
      
    &.caneditorg
      margin-top: 5em
      
      @media tablet-horizontal-max
        padding-left: 3em

    @media tablet-horizontal-max
      padding: 0.5em 0 0 3em
      
    @media mobile-horizontal-max
      padding: 0.5em 0 0 2em

    .tab-item
      a
        color: $blue

        &:hover
          background-color: $blue
          color: white

      &.active
        a
          background-color: white
          color: $blue

  .tab-view
    width: 85%
    background-color: white
    padding: default-padding*2 default-padding*3 default-padding*2 0em
      
    &.wide
      max-width: 1440px
      margin: 0 auto 0 auto
      padding: default-padding*2 default-padding*3 default-padding*2 0em
      
      @media tablet-horizontal-max
        padding: default-padding*2 default-padding*3 default-padding*2 0em
    
  
