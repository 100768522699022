section.search
  position: relative
  font-size: 16px

  button.input-search
    color: $very-dark-gray
    position: absolute
    top: default-padding*.5
    right: 0
    
    &.header
      top: 0

  input
    width: 100%
    background: white
    box-sizing: border-box
    padding: default-margin
    
    @media tablet-horizontal-max
      margin-bottom: 0.5em

    &.header
      padding: 0.35em
      
      @media tablet-horizontal-max
        margin-bottom: 0.5em
    
   
      
section.mobile-search
  position: relative

  button.input-search
    color: $very-dark-gray
    position: absolute
    top: 30px
    right: 0
    
    &.bigmargin
      top: 60px

  input
    width: 100%
    background: white
    box-sizing: border-box
    padding: 0.4em
    
    @media tablet-horizontal-max
      margin-bottom: 0.5em
      

