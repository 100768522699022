
article
  section.logo
    ol.basic-data
      margin-top: -1em
      margin-bottom: 2em

    .l-center-align
      img.logo
        max-width: 350px
        max-height: 200px

#dialog-content
  width: 55%
  @media mobile-horizontal-max
    width: 95%
    height: 60%
  section.warehouseurl
    ol.basic-data
      label
        margin-top: -1em
      input
        width: 48%
        margin-top: 0.2em

 #organization-content
    margin: 0 auto


.organizations
  position: relative

  .content-details
    padding: 2em 2em 2em 5em
    max-width: 1440px
    margin: 0 auto 0 auto
    
    h1
      margin-bottom: 0.1em
      
    .edit-group-name
      font-size: 0.85em
      color: $dark-text-color
      
      .icon
        font-size: 1.25em
        
      label
        padding-right: 0em
  
    .group-name
      font-size: 0.85em
      color: $very-dark-gray
      font-family: font-semi-bold
      
    .editwarehouseurl
      margin-top: 1em
      
    @media tablet-horizontal-max
      padding-left: 3em
      width: 100%

    section.description
      width: 60%

      @media tablet-horizontal-max
        width: 80%

    .organization-logo
      margin-top: 1em

      @media tablet-horizontal-max
        clear: both
        float: left

      &.cannotedit
        position: absolute
        top: 0em
        right: 5%

        @media tablet-horizontal-max
          clear: both
          position: inherit
          float: left


      .edit-logo
        float: right
        margin-top: 1em
        margin-right: 2em

        @media tablet-horizontal-max
          float: left
          width: 100%
          margin-top: 1em

      .logo
        margin-top: 0em

        img
          max-height: 150px
          width: auto
          max-width: 500px
          margin-right: 2em


    header
      .creator
        margin-top: default-margin*2

      .description
        .icon-pencil
          margin-top: 1em

        .description-text
          font-family: font-normal
          margin-top: default-margin*2

      h1
        @media mobile-horizontal-max
          font-size: 30px
          margin-top: -0.5em



  section.result-options
    background-color: transparentc

  section.main

    .tab-view
      max-width: 1440px
      margin: 0 auto 0 auto
      padding: 2em 8em 2em 8em

      @media tablet-horizontal-max
        padding: 2em 1em 0 3em

      .contact-and-support
        margin: 1em 0em

        h3.title
          margin-top: 0em

        ol.more
          margin: default-margin*2 0 0.5em
          font-size: 1em

          li
            margin: 0
            padding: default-padding*.5
            text-decoration: none
            color: $default-text-color
            display: block

      section.social-media-links
        font-size: 2em
        margin-top: default-margin

        a.icon
          color: $blue
          padding: 0 default-padding default-padding 0
