.radiobutton-first-group-label1
  margin-top: -0.9em !important
  margin-left: 1.5em !important
  
.radiobutton-first-group-label2
  margin-top: -0.5em !important
  margin-left: 2em !important
  
.radiobutton-second-group-label1
  margin-top: -0.5em !important
  margin-left: 2em !important
  
.radiobutton-second-group-label2
  margin-top: -0.9em !important
  margin-left: 1.7em !important
  
label.select-translations-file
  margin-left: 0em !important
  width: 30%
  
.import-translations-button
  margin-left: 0em !important
  width: 100%
  margin-top: 3em
  
.actions
  
  button.close
    margin-right: 1.5em !important
  
.export-to-file
  margin-top: 3em
  
.import-finished
  margin-top: 1em
  font-family: 'Open Sans Semi-bold';
  color: $default-text-color
  text-align: center
  
.import-errors-info
  margin-top: 1em
  overflow-y: auto
  color: red
  max-height: 10em
  @media desktop-max
    max-height: 8em

  .import-error-messages
    margin-top: 1em
          
#dialog-content
  &.translations-export
    height: 100%
    min-height: 44em
    min-width: 20em
    
    @media mobile-horizontal-max
      min-height: 39em
      
    @media tablet
      height: 70%
      width: 25% !important
      
    @media tablet-horizontal
      height: 60%
      width: 40% !important
    
    section.select-content
      overflow-y: hidden !important

    header.main
      h2, h3
        text-align: center
        
    .confirm-message
      font-family: font-light
      font-size: 1.5em
      margin-top: 2em
      margin-bottom: 2em
      
    .confirm-buttons
      margin-top: 2em

    .processing-update

      .info-header
        text-align: center

        span
          display: block
          margin: default-margin*2 0

    ol.basic-data > li
      height: 100%
      margin: 0

    .basic-data
      height: 100%
      margin-left: 1em
      
      .select-export-import
        text-align: center
        margin-bottom: 0.5em
        width: 95%
      
        .export-import-info
          text-align: left

      .translation-export-table
        width: 100%
        
        .first-column, .second-column
          height: 100%
          
        .first-column
          width: 95%
          height: 62%
          float: left
          margin-bottom: -5em
          
          @media desktop-max
            margin-bottom: -4em
            height: 53% !important
             
          @media export-first-break
            margin-bottom: -4em !important
            height: 62% !important
            
          @media export-second-break
            height: 65% !important
            
          @media export-third-break
            margin-bottom: -6em !important
            height: 75% !important
            
          @media export-fourth-break
            margin-bottom: -7em !important
            height: 75% !important
            
          
          .select-content-title
            margin-bottom: 0.5em
            margin-top: -2.5em
          
        .export-to-file
          width: 95%
          
          .export-button
            width: 100%
            margin-left: 0em
            

        .second-column
          width: 30%
          padding-left: default-padding*2
          float: right
          box-sizing: border-box
        .column
          border: 1px solid $blue
          vertical-align: top
          overflow-y: auto
          padding: default-padding*2
          box-sizing: border-box
          height: 80%

        h3
          margin-top: 0

        h4
          margin-top: 0
          font-family: font-normal
          text-transform: none

        li
          list-style: none

        .edit-group
          clear: both
          margin-bottom: default-margin
          margin-left: default-margin*2
          position: relative
          overflow: hidden

          label
            display: inline-block

        .sub-group-title
          clear: both
          margin-left: default-margin*1
          font-family: font-bold

        .edit-fields
          margin-left: default-margin*3
          margin-top: default-margin*0.5

        .actiontype
          width: 30%
          margin-bottom: default-padding
          float:left

        .invalid-action
          border: 2px solid $red

        .dropdown
          width: 50%

        .input-with-tooltip
          margin-top: default-margin*-2

        .inputs, .input-with-tooltip, .tag-list-area
          width: 65%
          float: left
          margin-left: default-margin*2

        .tag-list-area
          margin-top: -0.1em

        .show-dropdown
          margin-top: default-padding

          span
            margin-right: default-padding

          .dropdown-wrapper
            display: inline-block
            width: 15.5em
            @media desktop-max
              width: 13.5em

        .select-collection-title
          margin-left: default-padding

        .content-area
          margin-top: default-margin*2

          label
            display: inline-block !important
            padding-right: 0

          .checkbox-label
            vertical-align: middle

          .select-all
            margin-bottom: default-margin*2
            margin-left: 1.8em

            .info
              font-size: 0.7em
              margin-left: default-margin*4

        .collection-list, .content-list, .version-list, .select-all
          margin-top: default-padding*2
          position: relative

        .collection-list
          margin-left: default-padding

          .treecontrols
            font-size: 1.5em
            margin-right: default-padding*.5
            float: left

            .open
              margin-top: 0.05em

            .close
              margin-left: 0.25em

          .checkbox
            display: inline-block

          .content-list
            margin-left: 2em

          .version-list
            margin-left: 3em
