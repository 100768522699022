div.treeview
  -moz-user-select -moz-none
  -khtml-user-select none
  -webkit-user-select none
  -ms-user-select none
  user-select none
  font-size 1.0em
  color #555
  text-decoration none
  i
    color $very-dark-gray
    font-size 1.0em

div.tree-view
  ul
    list-style-type: none

div.tree
  ul
    margin 0
    padding 0
    list-style none
    border none
    overflow hidden
  li
    position relative
    padding 0 0 0 .75em
    .selected
      color $dark-gray
    .highlight
      color $blue
    i
      color $very-dark-gray
      font-size 1.0em

div.tree li i, div.tree li span
  cursor pointer

div.tree-item
  ul
    margin 0
    padding 0
    list-style none
    border none
    overflow hidden
  li
    div.tree-item-container
      &:hover
        background: $almost-white
    position relative
    padding 0 0 0 .75em
    
    .expanded
      padding-top 8px
    .collapsed
      padding-top 8px
    .highlight
      color $blue
    .normal
      padding-top 8px
    .selected
      color $dark-gray
    i
      color $very-dark-gray
      font-size 1.0em
    .white-space
        width 0.5em
        display inline-block
    .stretched
        width 100%
        margin 0 -100% 0 0
        display inline-block

div.tree-item li i, div.tree-item li span
  cursor pointer
