input.search
  padding-right 2em !important
  font-family font-normal
  &::-webkit-search-cancel-button
    position relative
    right 0
  &::-webkit-input-placeholder
    font-weight 300
    color $very-dark-gray
  &:focus::-webkit-input-placeholder
    font-weight 300
    opacity 0
    color transparent
  &:-moz-placeholder
    opacity 1.0
  &:focus:-moz-placeholder
    color transparent
    opacity 0.0
  &::-moz-placeholder
    color $very-dark-gray
    opacity 1.0
  &:focus::-moz-placeholder
    color transparent
    opacity 0.0
  &:-ms-input-placeholder
    color $very-dark-gray
  &:focus:-ms-input-placeholder
    opacity 0
    color transparent

.lt-ie9 input.search
  -pie-background $very-dark-gray url('/images/search-icon.png') no-repeat 95% center scroll
  behavior: url('/PIE.htc')
