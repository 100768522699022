.collections
  .button.show-online-collections, .button.show-local-collections
    float: left
    margin-top: default-padding*6
    margin-right: 0
    
  .button.create
    float: right
    margin-top: default-padding*6
    margin-right: 0
    
    @media mobile-horizontal-max
      float: left
    
  section
    padding: default-padding*6 0 0 0
    clearfix()

    .spinner
      margin auto
      text-align center
      
    h2.local-title

    h4
      line-height: 0


  section.online-lists
    clear: both
    padding: default-padding 0 0 0 0
    clearfix()

    .spinner
      margin auto
      text-align center

    h4
      line-height: 0

  .container
    .thumb-item
      box-shadow: 3px 3px 0 0 $very-light-grey, 4px 4px 0 0 white, 6px 6px 0 0 $very-light-grey
      margin-right: 15px
      
    .package-item
      box-shadow: none
      
    .package-item
      margin-right: 15px
      margin-bottom: 15px
      
  .collection-list-icons
    font-size: 1.8em
    margin-left: 0.3em
    margin-right: 0.2em
    color: $dark-text-color
    margin-top: 5%
    
    @media mobile-horizontal-max
      margin-top: 20%
    
  @media mobile-max
    
    h2.created-by-me
      font-size: 1.7em
      margin-top: -11%
      margin-left: 15%
      float: left
      
    h2.created-by-organization
      font-size: 1.7em
      margin-top: -15%
      margin-left: 20%
      

.collection
  .content-details
    padding: 2em 2em 2em 5em
    max-width: 1440px
    margin: 0 auto 0 auto

     
    @media tablet-horizontal-max
      padding: 2em 2em
      

    header
      padding: 0 0 default-padding*8 0
      
      @media tablet-horizontal-max
        clear: both
        float: left
        margin-top: 1em
        
      h1
        
        @media mobile-horizontal-max
          max-width: 6em
          word-wrap: break-word
      

      ul.header-actions
        padding-top: default-padding
        
        li.action-item
          list-style: none
          position: absolute
          margin-right: default-margin
          margin-left: -0.7em
          
        li.links, li.subscribers
          left: 5em

          ol > li
            list-style: none
            margin-left: default-margin

            span
              margin-right: default-padding

          .querying
            text-align: center
            padding: default-padding
            
        li.subscribers
          position: relative
          left: 5em

        .icon
          font-size: 1.5em
          vertical-align: middle
          color: $very-dark-gray

        .count
          vertical-align: middle
          
        .link-popup, .subscribers-popup
          background: $background-color
          position: relative
          top: -48px
          left: 55px
          border: 1px solid $very-dark-gray
          min-height: 100px
          min-width: 200px
          max-width: 300px
          z-index: 10

          .loading-indicator
            text-align: center
            height: 100%
            width: 100%
            position: absolute

            .spinner
              position: relative
              transform translate(-50%, -50%)
              -webkit-transform translate(-50%, -50%)
              -ms-transform translate(-50%, -50%)
              top: 50%
              left: 50%
              
          .popup-content
            padding: default-padding
        
        .subscribers-popup
          min-height: 10px

        .link-popup:after, .link-popup:before, .subscribers-popup:after, .subscribers-popup:before
          right: 100%
          top: 50%
          border: solid transparent
          content: " "
          height: 0
          width: 0
          position: absolute
          pointer-events: none

        .link-popup:after, .subscribers-popup:after
          position: absolute
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $background-color
          border-width: 10px
          top: 21px

        .link-popup:before, .subscribers-popup:before
          border-color: rgba(194, 225, 245, 0);
          border-right-color: $very-dark-gray
          border-width: 11px
          top: 20px    

  
  section.result-options
    background: $almost-white
    margin: 0 0
    border-bottom: none
    
    &.alerts-result-options, &.likes-result-options 
      margin: 0 0
      padding: 2em 1em 0em 0em
      background: white
      margin-bottom: 0em
      
      @media desktop-max
        padding-left: 1em
      
      @media mobile-horizontal-max
        padding: 2em 4em 0em 1em
        margin-bottom: 0em
    
    .content-type-selection
      margin-top: 5em
      
      @media desktop-max
        clear: both
        float: left
        margin-top: 1em
        
    .version-sort
      max-width: 1440px
      margin: 0 auto 0 auto
      margin-bottom: default-margin
      padding: 2em 2em 2em 4em  
      clearfix()
      
      @media tablet-horizontal-max
        padding: 2em 2em 2em 2em !important
        
        &.version-sort-local
          padding: 2em 2em 2em 1em !important
          
          @media tablet-horizontal-max
            padding: 0em 2em 0.5em 1em !important
      
      .sorts 
        clear: both
        float: left
        
        .select2-container, .select2-choice
          background-color: white !important
        
          &:hover
            background-color: white !important
            color: $default-text-color !important
      
      .version-buttons
        float: left
        
        .icon-warehouse
          font-size: 14px
          margin: 2px 0px 0px 8px
          float: right
        
        @media mobile-horizontal-max
          float: none
          
        .choose-action-info
          float: left
          margin: 1em 1em 1em 0em 
          
        .link-selected
          margin-left: 0em
          
        .unlink-selected
          position: relative
          
        .add-selected-to-cart
          
          &.editmode
            margin-left: 1em
          
        .download
          position: relative
          margin-left: 0.5em
          
          @media mobile-horizontal-max
            margin-left: 0em
          
          &.margin
            margin-left: 9.6em
          
        .install
          position: relative
          margin-left: 0.5em
          
          @media mobile-horizontal-max
            margin-left: 0em
      

        button:first-child
          margin-left: 0
          
        .link-popup
          background: $background-color
          position: absolute
          margin-left: 9.5em
          top: 38.5em
          border: 1px solid $very-dark-gray
          min-height: 100px
          min-width: 300px
          max-width: 400px
          z-index: 10
          
          @media desktop-small
            top: 37em
            margin-left: 9.5em
            
          .loading-indicator
            text-align: center
            height: 100%
            width: 100%
            position: absolute

            .spinner
              position: relative
              transform translate(-50%, -50%)
              -webkit-transform translate(-50%, -50%)
              -ms-transform translate(-50%, -50%)
              top: 50%
              left: 50%

          .popup-content
            padding: default-padding


        .link-popup:after, .link-popup:before
          right: 100%
          top: 50%
          border: solid transparent
          content: " "
          height: 0
          width: 0
          position: absolute
          pointer-events: none

        .link-popup:after
          position: absolute
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $background-color
          border-width: 10px
          top: 21px

        .link-popup:before
          border-color: rgba(194, 225, 245, 0);
          border-right-color: $very-dark-gray
          border-width: 11px
          top: 20px
      
      
      .licence-info
        font-size: 1.15em
        margin-bottom: 0.7em
        


  .results-container
    .listing-wrapper
      position: relative
            
      .button.create
        position: absolute
        right: 0
        top: default-padding*-2
        margin: 0
          
      .icon-maintenance-collection
        left: 306px
        bottom: 0px
        top: 222px
      
        &.thumbmode
          width: 28px
          padding-left: 2px
          top: 161px
          right: 0px
          left auto

  .results-container, .analytics, .access-rights
    padding: default-padding*4 default-padding*8 default-padding*4 default-padding*2
    overflow: hidden
    
    &.local-collection
      padding: default-padding*4 default-padding*8 default-padding*4 default-padding*10
      
      @media tablet-horizontal-max
        margin: 1em 2em 1em 1em
        width: initial
        padding-left: 1em
      
    
    @media tablet-horizontal-max
      margin: 1em 2em 1em 1em
      width: initial
      padding-left: 1em
    
    .results-container
      @media tablet-horizontal-max
        padding: default-padding*8 default-padding*8 default-padding*4 default-padding*4
    
      @media mobile-horizontal-max
        padding: default-padding*4 default-padding*8 default-padding*4 default-padding*4
    
    &.alerts-results-container, &.likes-results-container 
      padding: default-padding*2 default-padding*8 default-padding*4 0
      
      @media tablet-horizontal-max
        margin-left: 1em
    
    &.as-thumbnails
      @media mobile-horizontal-max
        min-width: 290px
        padding: 5% 0% 0em 0em
        
        &.local-collection
           padding: 5% 0% 0em 1em
        