 .pnotify, .ui-pnotify
  .pnotify-container, .ui-pnotify-container
    background-position: 0 0
    padding: 1em
    height: 100%
    margin: 0
    background-color: $blue

    &.brighttheme-success
      background-color: $green

    &.brighttheme-error, &.brighttheme-danger
      background-color: $warning
      
  .pnotify-closer, .pnotify-sticker, .ui-pnotify-closer, .ui-pnotify-sticker
    float: right
    margin-left: .2em

  .pnotify-title, .ui-pnotify-title
    display: block
    margin-bottom: .4em
    color: white
    font-size: 1.1em
    font-family: font-semi-bold
    min-height 17px

  .pnotify-text, .ui-pnotify-text
    display: block
    color: white
    
  .brighttheme-icon-closer
    font-size: 1.1em
    font-family: font-semi-bold
    color: white
    
  #showSurvey
    color: white
    text-decoration: underline
    font-family font-bold
    font-weight bold

  #dontShow
    color: white
    font-family font-normal
    font-weight normal
    text-decoration: underline

.survey-notification
  top: 66px !important
  right: 24px !important