.cookienotification
  position: fixed
  width: 100%
  height: 70px
  line-height: 70px
  bottom: 0px
  background-color: $very-light-grey
  z-index: 10
  margin: 0px
  padding: 0px

  .contentarea
    margin-top: 0px
    margin-bottom: 0px
    margin-right: auto
    margin-left: auto
    padding: 0px
    width: 1000px
    position: relative
    
    @media tablet-horizontal-max
      width: 850px
      
    @media tablet-max
      width: 700px
    
    @media mobile-horizontal-max
      width: 350px

    p
      display: block
      height: 70px
      font-weight: 600
      font-size: 14px
      line-height: 70px
      margin: 0px
      padding: 0px
      
      @media tablet-max
        height: 35px
        line-height: 0px
        padding-top: 20px
        
      
      @media mobile-horizontal-max
        height: 45px
        padding-top: 5px
        line-height: 20px

      a
        display: inline-block
        height: 70px
        font-size: 14px
        line-height: 70px
        margin: 0px
        padding: 0px
        
        @media tablet-max
          height: 45px
          line-height: 45px
        
        @media mobile-horizontal-max
          height: 20px
          line-height: 20px

    a.cookieclose
      position: absolute
      font-size: .8em
      right: 0
      bottom: 0

header#masthead
  position: relative
  background: white
  z-index: 9999

  clearfix()

  .local-service-warning
    padding: default-padding 2em

  .global
    clearfix()
    display: flex
    width: 100%
    height: 56px
    justify-content: space-between
    border-bottom: 1px solid #f1f1f6

    a
      .icon
        height: min-content
        margin: 0px 8px 0px 11px
        
      .icon-arrow-down
        width: 0px
        height: 7px
        margin-left: 0px
        margin-right: 12px
        padding-right: 12px
        
        &.user-menu
          
          @media mobile-horizontal-max
            padding-right: 5px
        

    .icon-user, .icon-services, .icon-globe, .icon-arrow-down
      &:before
        font-size: 16px
        vertical-align: middle
        
    .logo 
      display: flex

      img
        height: 24px
        max-height: 32px
        margin: 0px 0.5rem
        
        @media navi-third-break 
          display: none

    .global-block
      display: flex
      align-items: center
      z-index: 1
      padding: 2px
      
    .global-items-left
      min-height: 48px
      width: 50%
      display: flex
      justify-content: start
      align-self: center

      #tekla-navigation 
        i
          padding: 0

      .icon-trimble-logo
        margin: 12px 0 12px 24px
        color: $light-blue

      @media mobile-horizontal-max
        width: 20%

    .global-items-right
      font-size: 14px
      min-height: 48px
      width: 50%
      align-self: center
      display: flex
      justify-content: end
        
      .icon-globe, .icon-services, .icon-user
        
        @media mobile-horizontal-max
          display: none
        
      .global-block
        
        &.last-block
          margin-right: 12px
          
          @media mobile-horizontal-max
             margin-left: 1em
        
        @media mobile-horizontal-max
          text-align: center          
        
        .text-with-padding
          padding-right: 12px
          
       a.global-item
          color: $light-blue
          display: inline-block
          
          &:hover
            color: $very-light-blue

      @media mobile-horizontal-max
        width: 80%

    #tekla-navigation 
      ul 
        li
          display: inherit
          
    ul
      padding: 0

      li
        display: inline-block

    ul.popup
      position: absolute
      z-index: -1
      margin-top: 9px
      right: -1px
      min-width: 250px
      display: none
      text-align: left
      background: #ffffff
      border: 1px solid $light-grey
      box-shadow: 1px 1px 5px rgba(54, 53, 69, 0.13);
      font-family: font-semi-bold
      font-size: 14px
      
      @media mobile-horizontal-max
        margin-top: 30px
  
      @media navi-fifth-break
        margin-top: 11px
      
      @media mobile-small
        position: fixed
        left: 0px
        width: 360px
        
      &.services
        min-width: 480px
        
        .services-left
          width: 236px
          float: left
          
        .services-right
          width: 236px
          float: right
          
        @media mobile-horizontal-max
          min-width: 250px
          
          .services-left
            clear: both
            width: 100%
            
          .services-right
            clear: both
            width: 100%
            
        @media navi-fifth-break
          margin-top: 30px
            
        @media navi-sixth-break
          margin-top: 11px
        
      &.language-selection
         
        @media mobile-horizontal-max
          left: -1px
          
        @media navi-fifth-break
          margin-top: 30px
          
        @media mobile-small
          margin-top: 31px
          position: fixed
          left: 0px
        
      li
        width: 100%
        white-space: initial
        overflow-wrap: break-word
        box-sizing: border-box
        line-height: 36px
        padding: 0px 16px 0px 16px
        color: $light-blue
        
        @media mobile-horizontal-max
          white-space: normal
        
        &.heading-first
          line-height: initial
          color: $dark-text-color
          padding: 16px 16px 8px 16px
          text-transform: uppercase
          
        &.heading
          line-height: initial
          color: $dark-text-color
          padding: 11px 16px 8px 16px
          text-transform: uppercase
          
        &.site-link
          line-height: 36px
          padding: 0px 32px 0px 32px
          
        &.selected
          background-color: $lighter-grey
          border-left: 4px solid $light-blue
          padding-left: 12px
          color: $light-blue 
        
        &.active
          background-color: $lighter-grey
          border-left: 4px solid $light-blue
          padding-left: 28px
          color: $light-blue 
          
        &:hover:not(.heading):not(.heading-first)
          background-color: $lighter-grey

        &.admin-mode
          vertical-align: middle
          background-color: $almost-white
          padding-left: 16px
          width: 100%

          span
            margin: default-padding default-padding default-padding 0 
            float: left

          .toggle-button
            float: left
            margin-top: 0.5em
            margin-bottom: 0.5em

        a
          width: 100%
          box-sizing: border-box
          color: $light-blue
          display: block
      
    .login
      position: relative
      border: 1px solid transparent
      
      @media mobile-horizontal-max
        text-align: center
        padding: 5px
      
      a
        color: $light-blue 
        
        &:hover
          color: $very-light-blue 
          
        &:focus
          outline: 0

    .popup-wrapper
      position: relative
      text-align: right
      border: 1px solid transparent
      padding-top: 9px
      padding-bottom: 11px
       
      @media mobile-horizontal-max
       text-align: center
      
      a.popup-wrapper-item
        color: $light-blue 
        background-color: $background-color
        
        &:hover
          color: $very-light-blue 
        
      &.open
        border: 1px solid $light-grey
        background-color: $background-color
        border-bottom: none

        ul.popup
          display: block
          
          
  
  nav#main-navigation
    .wrapper
      margin: 0 auto
      
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }
    
    position: relative
    margin: 0 auto
    min-height: 55px
    float: left
    width: 100%
    box-sizing: content-box
    background-color: $navi-grey 
    border-bottom: 1px solid $light-grey
    box-shadow: 1px 3px 5px -2px rgba(54, 53, 69, 0.13);
    
    &.fix-to-top
      position: fixed;
      top: 0
      width: 100%
      z-index: 9999999

    h1
      width: 5.7em
      margin: 0
      a.logo
        background: transparent
        max-width: 100%
        padding: .35em default-padding*1.5
        display: block
        img
          width: 147px //236px
          height: 50px //80px

    a.button.upload
      float: left
      margin-top: 1em
      
    ul.nav-links
      width: 100%
      
      .nav-left, .nav-right, .menu

        > li
          float: left
          max-height: 56px
          > a
            display: block
            padding: 20px 0px 20px 24px
            line-height: 14px
            text-decoration: none
            color: $default-text-color
            font-family: font-semi-bold
            font-size: 14px
            outline: 0
            float: left

            &.logo
              padding: 0 16px 0 24px
              img
                height: 37px
                width: 123px
                padding: 9px 0px 7px 0px
                
        .gadgets
          display: none
          margin-left: 12px
                              
          li.alerts
            float: left
          
            .icon-alert-header
              font-size: 24px
              line-height: 36px
              padding-top: 10px
              padding-bottom: 10px
              color: $light-blue
              
              &:hover
                color: $light-yellow
                
          li.header-search-bar
            float: right
            
            section.search
              margin-top: 14px
              
              .clear-search
              
                &.icon-remove
                  font-size: 12px
                  position: absolute
                  top: 3px
                  right: 30px
                  cursor: pointer
                  color: $light-blue
                  
                  &:hover
                    color: $very-light-blue
                 
              button.input-search-header
                
                margin: 0
                font-size: 12px
                color: $dark-text-color
                padding: 6px
                  
                &.icon-search
                  font-size: 14px
                  position: absolute
                  top: 2px
                  right: 3px
                  
              input
                font-size: 12px
                padding: 5px 50px 5px 11px !important
                height: 24px
                border: 1px solid $input-border
                border-radius: 2px
                color: $input-text
                
                &:hover
                  background: $lighter-grey
                  
              .react-autosuggest__suggestions-container
                border: none
                z-index: initial
            
          li.shopping-cart
            clear: both
            float: left
            
            button
              color: $light-blue
              background-color: $white
              font-family: font-normal
              font-size: 12px
              border: 2px solid $light-blue
              border-radius: 2px
              height: 24px
              box-sizing: border-box
              margin: 10px 0px 10px 12px
              padding: 5px 12px 3px 12px
              
              &:hover
                color: $white
                background-color: $light-blue
                border: 2px solid $light-blue
                   
              &:active 
                color: $white
                background-color: $very-dark-blue
                border: 2px solid $very-dark-blue
                
              &:focus
                outline: 0
                 
              &.disabled
                background-color: $white !important
                border: 2px solid $light-blue !important
                opacity: 0.3
            
          li.add-link
            float: right
            
            .icon-upload
              float: left
              font-size: 12px
              margin-top: -1px
              margin-right: 4px
              display: inline-block
              transform: rotate(180deg)
            
            button
              color: $white
              background-color: $light-blue
              font-family: font-normal
              font-size: 12px
              border: 2px solid $light-blue
              border-radius: 2px
              height: 24px
              box-sizing: border-box
              margin: 10px 0px 10px 0px
              padding: 5px 12px 3px 12px
                  
              &:hover
                background-color: $very-light-blue
                border: 2px solid $very-light-blue
                
              &:active 
                color: $white
                background-color: $very-dark-blue
                border: 2px solid $very-dark-blue
                
              &:focus
                outline: 0
                 
              &.disabled
                background-color: $light-blue !important
                border: 2px solid $light-blue !important
                opacity: 0.3
          
          &.nouser
            @media navi-fifth-break
              &.showMobileMenu
                clear: both
                display: block
                
                li.add-link
                  float: left
                  margin: 4px 4px 4px 12px
                
          &.user
            @media navi-third-break
              &.showMobileMenu
                clear: both
                display: block
            
                
        li.mobile-menu
          float: right
          display: none
          
          &.nouser
            @media mobile-horizontal-max
              display: inline-block
          
          &.user
            @media navi-third-break
              display: inline-block
            
          .hamburger-menu
            background: none
            line-height: 40px
            padding: 10px 0px 0px 0px
            color: $light-blue
            font-size: 25px;
            font-weight:bold;
            cursor:pointer;
            outline:none;
            margin-top: 0em
            margin-right: 0em
            z-index:1000000;
            
            &:hover
              color: $very-light-blue
              
            &:active 
              color: $very-dark-blue
              
        .menu
          @media mobile-horizontal-max
            float: left
            width: 100%
            margin-bottom: 10px
              
        .menuitem
          color: $dark-text-color
          
          &.nouser
            @media mobile-horizontal-max
              clear: both
              display: none
              
              &.showMobileMenu
                display: block
                
          &.user
            @media navi-third-break
              clear: both
              display: none
              
              &.showMobileMenu
                display: block


          &.active, &:hover
            text-decoration: none
            color: $light-blue
            
          .link
            display: table-cell
            
            &.active
              padding-bottom: 18px
              border-bottom: 4px solid $light-blue
              border-left: none
              
            &.user
              @media navi-third-break
            
                &.showMobileMenu
                  padding-left: 11px
                  padding-top: 11px
                  padding-bottom: 15px
                  border: none
                  
                  &.active
                    padding-left: 7px
                    border-left: 4px solid $light-blue
                    
            &.nouser
              @media navi-fourth-break
            
                &.showMobileMenu
                  padding-left: 11px
                  padding-top: 11px
                  padding-bottom: 15px
                  border: none
                  
                  &.active
                    padding-left: 7px
                    border-left: 4px solid $light-blue
                  

          .link:after
            display: table-cell
            width: 100%

      .nav-left
        padding-right: 12px
        
        &.nouser
          @media navi-second-break
            clear: both
         
        &.user
          @media navi-first-break
            clear: both
              
      .nav-right
        float: right
        line-height: 56px
        font-size: 14px
        margin-top: -1px
        
        &.nouser
          @media navi-second-break
            clear: both
            float: left
            margin-left: 24px
            
          @media navi-fifth-break
            display: none
        
        &.user
          @media navi-first-break
            clear: both
            float: left
            
          @media navi-third-break
            display: none
                           
        li.alerts

          .icon-alert-header
            font-size: 24px
            line-height: 36px
            padding-top: 10px
            padding-bottom: 10px
            color: $light-blue
            
            &:hover
              color: $light-yellow

        &:before
          font-size: 1.5em
      
        > li
          float: left
          
          > a
            display: block
            padding-right: 24px
            text-decoration: none
            color: $blue
            font-family: font-semi-bold
            outline: 0
            text-transform: uppercase
            float: left
            
  
        .header-search-bar
          float: right
          line-height: 36px
          padding: 10px 24px 10px 0px
          width: 220px
            
          section.search
            
            .dropdown-menu
              font-size: 14px
            
            .clear-search
              
              &.icon-remove
                font-size: 14px
                position: absolute
                top: 2px
                right: 35px
                cursor: pointer
                color: $light-blue
                  
                &:hover
                  color: $very-light-blue
            
            button.input-search-header
              
              margin: 0
              font-size: 14px
              color: $dark-text-color
              padding: 9px
              
              &.icon-search
                font-size: 16px
                position: absolute
                top: 3px
                right: 5px
              
            input
              font-size: 14px
              padding: 10px 50px 9px 11px !important
              height: 36px
              border: 1px solid $input-border
              border-radius: 2px
              color: $input-text
                            
              &:hover
                background: $lighter-grey
                
            .react-autosuggest__suggestions-container
              border: 1px solid $input-border
              z-index: initial

            .react-autosuggest__container[aria-expanded="false"]

              .react-autosuggest__suggestions-container
                border: none
              
        .shopping-cart
          float: right
          font-family: font-semi-bold
          font-size: 14px
          line-height: 36px
          
          button
            color: $light-blue
            background-color: $white
            font-family: font-normal
            font-size: 16px
            border: 2px solid $light-blue
            border-radius: 2px
            height: 36px
            box-sizing: border-box
            margin: 10px 24px 10px 0px
            padding: 9px 16px 6px 16px
            
            
            &:hover
              color: $white
              background-color: $light-blue
              border: 2px solid $light-blue
                 
            &:active 
              color: $white
              background-color: $very-dark-blue
              border: 2px solid $very-dark-blue
              
            &:focus
              outline: 0
               
            &.disabled
              background-color: $white !important
              border: 2px solid $light-blue !important
              opacity: 0.3
          
          a.cart-link
            padding-left: 0em
          
          .icon-warehouse
            float: left
            font-size: 14px
            margin: 2px 8px 0px 0px
                        
          .icon-help
            margin-right: -1em
            margin-top: 0.11em
            font-size: 1.2em
              

        .add-link
          float: right
          
          .icon-upload
            float: left
            font-size: 14px
            padding-top: 1px
            margin-right: 8px
            display: inline-block
          
          button
            color: $white
            background-color: $light-blue
            font-family: font-normal
            font-size: 16px
            border: 2px solid $light-blue
            border-radius: 2px
            height: 36px
            box-sizing: border-box
            margin: 10px 24px 10px 0px
            padding: 9px 16px 6px 16px
                
            &:hover
              background-color: $very-light-blue
              border: 2px solid $very-light-blue
              
            &:active 
              color: $white
              background-color: $very-dark-blue
              border: 2px solid $very-dark-blue
              
            &:focus
              outline: 0
               
            &.disabled
              background-color: $light-blue !important
              border: 2px solid $light-blue !important
              opacity: 0.3

