.rating
  color: $very-light-grey
  margin: 0
  padding: 0

ul.rating
  display: inline-block

.rating li
  list-style-type: none
  display: inline-block
  padding: 1px
  text-align: center
  font-weight: bold
  cursor: pointer

.rating .filled
  color: $dark-gray

li.ratings
  .rate.disabled
    .rating li
      color: $very-light-grey

