#dialog-content
  &.batch-edit
    width 95% !important
    height 95% !important

      section.edit-content
        overflow-y: hidden !important

    header.main
      h2, h3
        text-align: center
        
    .confirm-message
      font-family: font-light
      font-size: 1.5em
      margin-top: 2em
      margin-bottom: 2em
      
    .confirm-buttons
      margin-top: 2em

    .processing-update

      .info-header
        text-align: center

        span
          display: block
          margin: default-margin*2 0

      .failed-updates
        width: 70%
        margin: 0 auto
        border: 2px solid $dark-gray
        overflow-y: auto

        .failed-content-list
          padding-bottom: 0.2em

    ol.basic-data > li
      height: 100%
      margin: 0

    .basic-data
      height: 100%
      
      @media mobile-horizontal-max
        height: initial
      
      .error-messages
        text-align: right
        padding-right: default-padding*2

      .batch-edit-table
        width: 100%
        height: 100%
        display: flex

        &.has-errors
          height: 85%

        .first-column, .second-column
          height: 100%
          
        .first-column
          width: 40%
          
          @media tablet-horizontal-max
            width: 100%
            float: none
            
        .second-column
          width: 60%
          padding-right: default-padding*2
          box-sizing: border-box
          
          @media tablet-horizontal-max
            width: 100%
            float: none
            margin-left: -1em
            
        .column
          border: 1px solid $blue
          vertical-align: top
          overflow-y: auto
          padding: default-padding*2
          margin-bottom: 5em
          box-sizing: border-box
          height: 80%

        h3
          margin-top: 0
          
          @media mobile-horizontal-max
            margin-top: 5%

        h4
          margin-top: 0
          font-family: font-normal
          text-transform: none

        li
          list-style: none

        .edit-group
          clear: both
          margin-bottom: default-margin
          margin-left: default-margin*2
          position: relative
          overflow: hidden

          label
            display: inline-block

        .sub-group-title
          clear: both
          margin-left: default-margin*1
          font-family: font-bold

        .edit-fields
          margin-left: default-margin*3
          margin-top: default-margin*0.5

        .actiontype
          width: 30%
          margin-bottom: default-padding
          float:left

        .invalid-action
          border: 2px solid $red

        .dropdown
          width: 50%

        .input-with-tooltip
          margin-top: default-margin*-2

        .inputs, .input-with-tooltip, .tag-list-area
          width: 65%
          float: left
          margin-left: default-margin*2

        .tag-list-area
          margin-top: -0.1em

        .batch-edit-versions
          clear: both

        .batch-select-content
          margin-top: default-padding
      
          .selected-organization
            .organization-title
              margin-bottom: 0.5em
              
            a
              display: block
              margin-bottom: 1em
          
          .select-organization-search
            display: block
            margin-bottom: 2em
            
            .organizations-list
              margin-top: 1em
              
              li
                margin-top: 0.5em
            
            form 
              position: relative
              
              input
                width: 80%
          
              button.input-search
                position: relative
                right: 2em
                
          span
            margin-right: default-padding
            
          .dropdown-wrapper
            width: 90%

          @media mobile-horizontal-max
            width: 100%

        .select-collection-title
          margin-left: default-padding
          
        .select-version-title
          float: left
          margin-top: 1em
          margin-left: default-padding

        .content-area
          margin-top: default-margin*2

          label
            display: inline-block !important
            padding-right: 0

          .checkbox-label
            vertical-align: middle

          .select-all
            margin-bottom: default-margin*2
            margin-left: 1.8em

            .info
              font-size: 0.7em
              margin-left: default-margin*4

        .collection-list, .content-list, .version-list, .select-all
          margin-top: default-padding*2
          position: relative

        .collection-list
          margin-left: default-padding

          .treecontrols
            font-size: 1.5em
            margin-right: default-padding*.5
            float: left

            .open
              margin-top: 0.05em

            .close
              margin-left: 0.25em

          .checkbox
            display: inline-block

          .content-list
            margin-left: 2em

          .version-list
            margin-left: 3em
