.access-rights
  margin: default-margin 1.5em

  .loading-indicator
    display: inline-block

  .wrapper
    width: 100%
    display: inline-block
    
    @media tablet-horizontal-max
      width: 100%
      
    .action-dropdown
      width: 30%
      
      label.action
        font-size: 1em
        font-family: font-semi-bold
        margin-bottom: 1em
    
    .admin-header
      margin: 2em 1em 1em 0em
      font-size: 1.25em
      font-family: font-semi-bold
          
    .add-analyst-user
      margin-top: 1em
      margin-bottom: 1em
      font-size: 1em
      font-family: font-normal
      
    .create-sitemap
      margin-left: 0em
      
      .button-create
        margin-left: 0em
          
    .export-analytics
      margin-left: 0em
      margin-bottom: 1em
    
      .button-export
        margin-left: 0em 
        margin-bottom: 1em
        
    .virus-scan
      margin-left: 0em
      margin-bottom: 1em
      
      .packages-list
        position: relative

      .button-search
        margin-left: 0em 
        margin-bottom: 1em
        margin-top: 2em
        
      .select-binary
        margin-bottom: 0.5em
        
      .package-title
        display: inline-block
        margin-top: 0.5em
      
      .version-title, .binary-title
        display: inline-block

    .manage-feature-toggles
      width: 30%
      
      .new-toggle, .edit-toggle
        margin-top: 1em
        margin-bottom: 1em
        
        input
          height: 2em
      
      .edit-is-enabled
        margin-top: 2em
        
      .edit-percentage-title
        margin-top: 2em
        margin-bottom: -1em
        font-size: 1em
        
      .save-button
        margin-top: 2em
      
    .content-manager
      margin-left: 0em
      margin-bottom: 1em
      margin-top: 1em
      padding: 0

      .search-by-keyword, .search-by-criteria
        display: flex
        flex-direction: row
        flex-wrap: wrap

        input.search
          height: 32px
          margin: 0.75em 0
          min-width: 40%
          padding-right: 0.3em !important
        
        .button-search
          margin: 0.75em

        .show-hidden
          margin: 0.75em

      .search-by-criteria
        margin-left: -0.75em
        margin-bottom: 1em

      .manage-content-actions
        margin-top: 1em
        margin-bottom: 1em
        margin-left: -1em

      .collection-list
        margin-left: default-padding
      
        .collection-list-item, .content-list-item
            margin-top: default-padding*2
            position: relative

          .treecontrols
            font-size: 1.5em
            margin-right: default-padding*.5
            margin-left: -0.75em
            float: left

            .open
              margin-top: 0.05em

            .close
              margin-left: 0.10em
              margin-right: 0.15em

          .checkbox
            display: inline-block
            
            label
              padding-right: 0em

      .content-list, .versions-list
        margin-left: 2em
            
      .versions-list
        margin-top: 0em
        margin-left: 2.75em

        .select-version
          margin-top: 0.5em
          margin-bottom: 0.5em  
    
      .button-search
        margin-left: 0em 
        margin-bottom: 1em
        margin-top: 2em

      .icon-search
        &:before
          content "\e915"
          padding-right: 0.5em
        
      .select-package
        margin-bottom: 0.5em
        
    li.candidates
      margin: 0.5em 0em
      
      
    .offline-to-online
      float: left
      width: 20em
      
      .create-options
        margin-top: 2em
        width: 100%
      
      .select-offline-content
        margin-left: 0em
        margin-top: 1.5em
        margin-bottom: 3em
        
      .convert-in-progress
        margin-top: 1em
        
    .manage-users
      margin-bottom: 1em
      
      button.delete-user, button.suspend-user
        margin-left: 0em

      button.delete-user
        background-color: $red
            
      form 
        position: relative
        
        button.input-search
          position: relative
          right: 2em
          
      .loading
        margin-top: 2em
        margin-bottom: 2em
      
      input
        width: 28em
        background: #fff
        
        @media tablet-horizontal-max
          width: 21em
          
      .users
        margin: 1em 0.5em 0.5em 0.5em
        
      .users-list.one-selected
        margin-top: 1em

      ul.users-list  
        list-style: none

      .users-list          
        li.user-item
          display: flex
          align-items: baseline

        .icon
          margin-right: 0.5em 
            
        a 
          margin-left: 0.5em
          margin-bottom: 1em

        .user-suspended
          margin-left: 0.5em

          .loading
            display: flex

            .spinner:before
              height: 1em
              width: 1em

      .actions
        padding-top: 1em

      .select-user
        padding-bottom: 1em

        .search-options
          display: flex
          align-items: center

          button.button-search
            margin-left: 2em

    .migrate-content
      margin-bottom: 1em
      
      .dropdown-menu
        width: 21em !important
        
      form 
        position: relative
        
        button.input-search
          position: relative
          right: 2em
          
      form.collections-form
        position: relative
        width: 28em
        
        @media tablet-horizontal-max
          width: 21em
        
        button.input-search
          position: relative
          right: 2em
      
      .loading
        margin-top: 2em
      
      input
        width: 28em
        background: #fff
        
        @media tablet-horizontal-max
          width: 21em
        
      .items
        margin: 1em 0.5em 0.5em 0.5em
        width: 25em
        
        .items-list
          list-style: none
          
          .icon
            margin-right: 0.5em 
            
          .icon-organization
            margin-right: 0em 
            
          a 
            margin-left: 0.5em
            margin-bottom: 1em
      
      .migrate-from
        float: left
        margin-bottom: 1em
        
        @media tablet-horizontal-max
          margin-bottom: 0em
        
        .title
          margin-bottom: 1em
          
          label
            font-size: 1em
            

        .collection-list, .content-list, .select-all
          margin-top: default-padding*2
          position: relative

        .collection-list
          margin-left: default-padding

          .treecontrols
            font-size: 1.5em
            margin-right: default-padding*.5
            float: left

            .open
              margin-top: 0.05em

            .close
              margin-left: 0.10em
              margin-right: 0.15em

          .checkbox
            display: inline-block
            
            label
              padding-right: 0em

          .content-list
            margin-left: 2em
            
      .migrate-action
        float: left
        margin-top: 2em
        
        @media tablet-horizontal-max
          clear: both 
          margin-top: 0em
          margin-bottom: 1em
        
      .migrate-to
        float: left
        margin-left: 2em
        
        @media tablet-horizontal-max
          clear: both 
          margin-left: 0em
        
        .title
          margin-bottom: 1em
          
          label
            font-size: 1em
          
      .button-migrate
        margin-left: auto
        
    .rzslider
      margin-top: 3em

      .slider-handle
        position: absolute
        left: auto !important

        span
          width: 30px
          height: 24px
          line-height: 24px
          text-align: center
          background: $very-light-blue
          color: #fff
          font-size: 12px
          display: block
          position: absolute
          left: 50%
          transform: translate(-50%, 0)
          border-radius: 6px

        span:before
          content: ""
          position: absolute
          width: 0
          height: 0
          border-top: 10px solid #03a9f4
          border-left: 5px solid transparent
          border-right: 5px solid transparent
          top: 100%
          left: 50%
          margin-left: -5px
          margin-top: -1px

      .slider-line
        background-color: $very-light-blue
        height: 8px
        width: 95%
        border-radius: 999px
        margin-right: 1em

            
    .search-relevancy-settings
      float: left
      margin-top: 1.5em
      width: 50%
      
      .boost-item
        margin-top: 2em
        
        .boost-item-header
          font-family: font-semi-bold
          margin-bottom: -0.5em
          
        .boost-item-value
          margin-left: 1em
          font-family: font-normal
          margin-top: 1em

  ul.wrapper
    list-style: none

    li.visibility
      margin-bottom: default-margin*2

      span.label
        font-family: font-normal
        font-size: 1.1em
        margin-right: default-margin*2
        vertical-align: middle

      .toggle-button
        display: inline-block
        vertical-align: middle

      .loading-indicator
        margin: 0 default-margin*2

  .acl-editor
    padding: default-padding*2 0

    .input-and-message
      display: flex
      flex-direction: column
      width: 100%

      .input-invalid
        padding: 5px

        li
          margin-bottom: 5px

    .command-textinput
      min-width: 30em
      
      @media mobile-horizontal-max
        min-width: 20em

      input[type="text"]
        width: 100%
        
    ul.users-list
      list-style: none
      width: 80%
      margin: default-margin
      margin-left: 0em
      
      li.candidates
        display: block
        padding: default-padding
        margin-bottom: 0em
        
        span.icon
          padding-right: default-padding

      li
        display: inline-block
        padding: default-padding
        margin-bottom: 0em

        span.icon
          padding-right: default-padding

    .loading-indicator
      padding-left: 1em

  .info
    padding: default-margin*3
    display: flex
    flex-direction: row
    vertical-align: top

    .max-inputs
      padding-left: 2em
    
    @media desktop-max
      padding: 0 0
      