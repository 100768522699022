.google-visualization-table
  width: 100%
  
  th
    border: none !important
    padding-top: 0em !important
    
  tr:hover
    color: inherit !important
    
  td
    border: none !important
    padding: 0.5em 0 0.5em 0.5em !important
    
  .version
    background: $very-light-grey
    font-size: 1.5em
    padding-bottom: 0.5em !important
    text-align: center 
    font-weight: bold;
    
  .organization
    background-color: white 
    font-size: 1.2em 
    text-align: left

.analytics
  margin: 0 -0.5em
  text-align: center

  .recording-info
    float: left
    max-width: 25em
    text-align: left
    
    @media mobile-horizontal-max
      margin-left: 0.5em

    &:before
      content: "i"
      font-family: "Pictos"
      float: left
      margin-top: 0.25em

    span
      display: block
      margin-left: 1.8em
      margin-top: .2em
      
  .export-collection
    display: inline-block
    margin-left: 15em
    
    @media desktop-max
      clear: both
      float: left
      margin: 1em 0em 0em 1em
      
    button
      margin: 0

  .export-details
    display: inline-block
    margin-left: default-margin * 4
    
    @media desktop-max
      clear: both
      float: left
      margin-top: 1em
      margin-left: 0.5em

    button
      margin: 0
              
   .export
    display: inline-block
    margin-left: 2em
    
    @media mobile-horizontal-max
      margin-top: 1em
      margin-left: 0em
      
    @media tablet-horizontal-max
      clear: both
      float: left
      margin-top: 1em
      margin-left: 1em

    button
      margin: 0
      
   .controls
    display: inline-block
    width: 100%

    .row
      text-align: initial
      display: inline-block
      width: 100%

      
      .eventtype-options
        float: right
        margin: 0
        margin-left: default-margin
        
        @media desktop-max
          clear: both
          float: left
          margin-top: 1em
          
        .dropdown-container
          float: right
          margin-top: -0.5em

        .dropdown
          width: 10em !important
          
      .timeframe-options
        float: right
        margin-top: 1em
        margin-left: default-margin
        
        @media desktop-max
          clear: both
          float: left
          
        .dropdown-container
          float: right
          margin-top: -0.5em

        .dropdown
          width: 10em !important

        .timeframe-custom
          margin-top: 1em
          text-align: right

          .datepicker
            display: inline-block

      .timeframe-options
        float: right
        margin-top: 1em
        margin-left: default-margin
        
        @media desktop-max
          clear: both
          float: left

        .dropdown
          width: 10em !important

        .timeframe-custom
          margin-top: default-margin
          text-align: right

          .datepicker
            display: inline-block
            
  .analytics-selection
    .radiowrapper
      margin-bottom: 1em

  .chart-row
    width: 100%

    .chart-box
      float: left
      text-align: initial
      width: 50%
      margin: 0
      
      @media tablet-horizontal-max
        width: 80% !important
      

      .wrapper
        border: 1px solid $very-light-grey
        margin: default-margin default-margin default-margin 0
        
        &.extra-margin
          margin-right: 0.75em !important

      &:last-child
        .wrapper
          margin: default-margin 0

      &.wide
        width: 100%

      @media mobile-max
        width: 100%

      h4
        margin: 0
        border-bottom: 1px solid $very-light-grey
        background: $almost-white
        padding: default-padding default-padding*2
        line-height: 1em
        
      .processing
        text-align: center
        margin-top: 1em
        
      .geo-chart
        width: 95%
        padding: 1em 0em 0em 3em
        margin-bottom: 2em
        
        .statsContainer
          height: 300px
          margin-left: 3em
          margin-top: 1em
        
        li
          list-style: none
        
      .line-chart
        width: 90%
        padding: 1em 1em 0em 1em
        
        .statsContainer
          height: 300px
          
        li
          list-style: none
            
      .table-chart
        font-family: font-normal
        padding: 0.5em 0.5em 0em 0.5em
        margin: 0 auto
        height: 307px
        overflow: auto
        
        .header
          display: block
          background-color: $very-light-grey
          
          .value
            font-size: 1.2em
            padding: 0.5em
            text-align: center
            font-weight: bold
            
        .summary
          display: block
          background-color: #fff
          
          .value
            font-size: 1em   
            padding: 0.5em         
            text-align: left            
          
        
        &.org-packages
          margin: 0
          
        
        .statsContainer
          height: 300px
          
        li
          list-style: none
        
      .pie-chart
        width: 85%
        padding: 0.5em
        margin-left: 1.5em
        height: 300px
        
        .statsContainer
          height: 300px
        
        li
          list-style: none
          
      .chart
        padding: default-padding
        height: 300px

        &.scrollable
          overflow: auto

        .version-downloads
          span.label
            font-size: 1.1em
            display: block
            text-align: center
            margin: 0
            background: $very-light-grey
            padding: (default-padding/2) 0 0 0
          ul
            list-style: none
            li
              padding: (default-margin/2) default-margin

        ul.organization-downloads
          padding: 0 default-margin*2

  &.export
    .timeframe-options, .timeframe-options, .eventtype-options
      float: none
      width: 250px
      text-align: left
      margin: default-margin auto

      .timeframe-custom
        text-align: left

        .label, .datepicker
          display: block

