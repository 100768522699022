.admin-transfer-packages
  padding: default-padding*2 0
  margin-top: -1em

  .loading-indicator
    margin: 1em 1em
    
  .collections-form
    margin: 2em 1em 1em 0em
    width: 50%

  .command-textinput
    min-width: 30em
    
    @media mobile-horizontal-max
      min-width: 20em

    input[type="text"]
      width: 100%
      
  ul.users-list
    list-style: none
    width: 100%
    margin: default-margin default-margin default-margin 0 
    
    li.candidates
      display: block
      padding: default-padding
      margin-bottom: 0em
      
      span.icon
        padding-right: default-padding

    li
      display: inline-block
      padding: default-padding
      margin-bottom: 1em

      span.icon
        padding-right: default-padding


.existing-files
  tbody
    tr
      &:hover
        a.rescan-binary
          color: $yellow

section.main-info
  
  .info-texts
    padding: 1em 2em
    
    .header-text
       margin-bottom: 1em
    
    .bullet-texts
      padding: 0em 2em 1em
      
      li  
        padding-top: 0.5em
    
  label
    margin-bottom: 3em
    margin-left: 35%
    
    @media tablet-horizontal-max
      margin-left: 25%
        
  button
    display: block
    margin: 0 auto -2em auto
  

.admin-search-preferences
  margin-top: 2em

.likes
  margin-bottom: 0.5em
  display: flex
  flex-direction: row

  .loading-indicator
    padding-left: 0.6em
  
.image-navi
  display: table-cell
  width: 10%
  min-width: 10em
  float: left
  position: relative
  height: 15em
  margin-top: 0.5em
  margin-bottom: 2em
  
  @media tablet-horizontal-max
    margin: 2em 0em
    width: 20em
    

  .layer
    position: absolute
    width: 100%
    height: 60px
    cursor: pointer
  
  a.navigate-backward
    width: 100%
    
    &.disabled
      pointer-events: none
      cursor: default
      text-decoration: none
      color: $very-dark-gray
        
    &:before
      font-family: 'tekla-icomoon'
      content: "\e900"
      font-size: 2em
      position: absolute
      top: 0
      left: 25%
      transform: rotate(270deg)
      
      @media tablet-horizontal-max
        left: 40%

    
  a.navigate-forward
    width: 100%
    
    &.disabled
      pointer-events: none
      cursor: default
      text-decoration: none
      color: $very-dark-gray
        
    &:before
      margin-top: 1em
      font-family: 'tekla-icomoon'
      content: "\e900"
      font-size: 2em
      position: absolute
      bottom: 0
      left: 25%
      transform: rotate(90deg)
      
      @media tablet-horizontal-max
        left: 40%


.image-views
  padding: 2em 2em
  height: 35em
  overflow-y: scroll
  overflow-x: hidden
  
  .thumb-box, .video
    display: table-cell
    width: 25em !important
    margin-right: default-margin*2 !important
    min-height: 15em !important
      
  .add-new
    margin-left: 0em
    margin-top: 0em
    margin-bottom: 1em
    
  .image-content
    display: table
    
    .default-image-selection
      display: table-cell
      vertical-align: middle
      width: 15%
      max-width: 5em
      min-width: 5em
    
    .row
      margin-bottom: 2em
    
            
    .thumb-box-3d
      display: table-cell
      width: 65%
      
      .threed-pic-frame
        width: 25em
        height: 15em
          
    .action
      display: table-cell
      vertical-align: middle
      padding-left: 1em
      width: 20%
      
  .show3D-view
    .thumb-box-3d
      padding: 10em 1em 1em 1em
      
      .threed-pic-frame
        width: 100%
        height: 20em
    
  .action-options
    
    .thumb-box, .video
      display: table-cell
      width: 25em !important
      margin-right: default-margin*2 !important
      min-height: 15em !important
          
    .selected-file
      margin-bottom: 1em
      
    .video-title
      margin-top: 2em
      margin-bottom: 1em
      
    .url
      width: 100%
      
    .invalid-url
      margin-top: 0.5em
      color: red
      
    .info-3D-exists
      margin-top: 2em
      
      
    .label
      text-transform: none
      font-family: font-normal  
      color: $default-text-color
      
    label.button
      margin-left: 0em
      margin-top: 2.5em
      margin-bottom: 2em
      
    .choose-action
      width: 15em !important
      display: inline-block
  
  .buttons
    position: absolute
    bottom: 1em
    width: 90%
    
    button
      min-width: 6em
      margin: 0em 0em
      
      &.cancel
        float: left
        
      &.add
        float: right

.radiowrapper
  width: 80em
  height: 70px;
  margin-top: 2em
  
  &.edit-image 
    width: initial
    margin-top: 0em
    margin-left: 1em
    
  
  @media mobile-horizontal-max
   margin-top: 0em
   width: 100%
   margin-bottom: 1em
   
  @media tablet-horizontal-max
    width: 100%

  .radio
    position: relative
    width: 18px
    height: 18px
    border-radius: 50%
    margin-right: 10px
    background-color: $very-light-grey
    margin-top: 1em
    margin-bottom: 1em
    
    @media mobile-horizontal-max
      float:none
      
    @media tablet-horizontal-max
      margin-top: 2em
    
    label
      margin-left: 1.5em
      margin-top: -3em
      width: 50em
      font-size: 1em
      text-align: left
      
      @media tablet-max
        width: 17em
        margin-top: -0.5em
      
      @media tablet-horizontal
        width: 20em
        margin-top: 1em
      
      &:not(.button)
        display: block
        padding: default-padding 0
        font-size: 1em
        color: $default-text-color

        &.icon
          &:before
            margin: 0 default-padding
    
    &:hover
      background-color: $default-text-color
   
  .radio-wide-mobile
    label
      @media mobile-horizontal-max
        width: 20em
        margin-top: -0.5em
    
  .radioactive
    position: relative
    width: 18px
    height: 18px
    border-radius: 50%
    margin-right: 10px
    background-color: #ffffff
    border: 2px solid $blue
    margin-top: 1em
    margin-bottom: 1em
    
    @media mobile-horizontal-max
      float:none
      margin: 2% 0
      
    @media tablet-horizontal-max
      margin-top: 2em
    
    label
      margin-left: 1.5em
      margin-top: -3em
      width: 50em
      font-size: 1em
      text-align: left
      
      @media tablet-max
        width: 17em
        margin-top: -0.5em
        
      @media tablet-horizontal
        width: 20em
        margin-top: -0.5em
      
      &:not(.button)
        display: block
        padding: default-padding 0
        font-size: 1em
        color: $default-text-color

        &.icon
          &:before
            margin: 0 default-padding
            
    
    &:hover
      background-color: #ffffff
      
    .radioactivedot 
      display: inline-block
      position: absolute
      top: 6px
      left: 6px
      width: 6px
      height: 6px
      border-radius: 50%
      background-color: $blue
      border: 0px
    
  .radioactive-wide-mobile
    label
      @media mobile-horizontal-max
        width: 20em
        margin-top: -0.5em  
   
.radiowrapper-publish, radiowrapper-visibility 
  width: 100%
  height: 3em;
  margin-top: 0.5em
  margin-bottom: 0.5em
  
  @media mobile-horizontal-max
    margin-bottom: 3.5em
  
  .radio-publish
    position: relative
    width: 18px
    height: 18px
    float: left
    border-radius: 50%
    margin-right: 10px
    background-color: $very-light-grey
    margin-top: 0.1em
    margin-bottom: 2em
    margin-right: 40%
    
    @media mobile-horizontal-max
      float: none
    
    label
      margin-left: 1.5em
      margin-top: -3em
      width: 25em
      font-size: 1em
      text-align: left
      
      @media mobile-horizontal-max
        width: 20em
      
      &:not(.button)
        display: block
        padding: default-padding 0
        font-size: 1em
        color: $default-text-color

        &.icon
          &:before
            margin: 0 default-padding
    
    &:hover
      background-color: $very-dark-gray
      
  .radioactive-publish
    float: left
    position: relative
    width: 18px
    height: 18px
    border-radius: 50%
    margin-right: 10px
    background-color: #ffffff
    border: 2px solid #003f87
    margin-bottom: 2em
    margin-right: 40%
    
    @media mobile-horizontal-max
      float: none
    
    label
      margin-left: 1.5em
      margin-top: -3em
      width: 25em
      font-size: 1em
      text-align: left
      
      @media mobile-horizontal-max
        width: 20em
      
      &:not(.button)
        display: block
        padding: default-padding 0
        font-size: 1em
        color: $default-text-color

        &.icon
          &:before
            margin: 0 default-padding
            
    
    &:hover
      background-color: #ffffff
      
    .radioactivedot-publish 
      display: inline-block;
      position: absolute;
      top: 6px;
      left: 5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #003f87;
      border: 0px;  

.organization-option
  margin-left: 2em
  margin-top: -0.5em

  .dropdown-wrapper.gray [class*="-container"] [class*="-control"]
    width: max-content

  .dropdown-wrapper.gray [class*="-container"] [class*="-menu"]
    width: max-content
      
.catalog
  width: 100% 
  

.thumb-box, .thumb-box-organization
  width: 21em
  min-height: 15em
  background-color: $background-color
  position: relative

  figure
    padding-top: 75%    
    background-size: cover
    background-position: center
    -moz-background-size: cover  /* Firefox 3.6 */
    
  .figure-organization
    padding-top: 75%
    width: 95%
    margin-left: 0.5em
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    -moz-background-size: contain  /* Firefox 3.6 */
    background-color: $background-color
    
    &.defaultbackground
      width: 100%
      margin-left: 0em
      background-color: $default-text-color

  &.thumb16em
    width: 16em
    min-height: 6em

  &.thumb100p
    width: 100%
    min-height: 6em

  .controls
    position: absolute
    right: 0
    top: 0
    
    @media mobile-horizontal-max
      right: auto

    .button, button
      display: block
      margin: default-padding
      padding: default-padding*1.5

section.batch-edit
  .button-bulk-edit
    margin-top: 0.5em
    margin-right: 0em
    margin-left: 0em
    
section.news-feed
  margin: 1em 0em
  max-height: 30em
  width: 50%
  
  h3.title 
    margin-top: 0em

  .add-and-spinner
    display: flex;
    flex-direction: row;
    align-items: baseline;

  .button-add-comment
    margin-left: 0em
    
  .news-feed-items
    margin: 1.5em 0em
    
    .news-feed-createtime
      font-size: 0.8em
    .news-feed-text
      margin-top: -0.5em
      margin-bottom: 1.5em
      max-width: 450px
      
a.previous
  &:before
    font-family: 'tekla-icomoon' !important
    content: "\e900"
    transform: rotate(180deg)
    position: absolute
    font-size: .8em
    
.previous-link-text
  margin-left: 0.8em
    
a.next
  &:after
    font-family: 'tekla-icomoon'
    content: "\e900"
    transform: rotate(90deg)
    font-size: .8em
    

.user-comments
  margin: 1em 1em
  max-height: 30em
  width: 50%
  
  h3.title 
    margin-top: 0em

  .add-and-spinner
    display: flex;
    flex-direction: row;
    align-items: baseline;
    
  .button-add-comment
    margin-left: 0em
    margin-right: 1em
    
  .report-bad-comment
    margin-bottom: 1em
    font-size: 0.8em
    
  .replies
    margin-bottom: 1em
    
    a.view-reply
      font-size: 0.8em
      
  .comment-navigation
    margin-top: 2em
    
  .user-comment-items
    margin: 1.5em 0em
    
    .edit-comment
      margin-bottom: 1em
      
      a.edit-comment-link
        margin-left: -0.5em
    
    .comment-createtime
      float: left
      width: 100%
      font-size: 0.8em
      margin-bottom: 1em
      
    hr.separator
      margin-bottom: 20em
      margin-top: -10em
      
      
    hr.view-comment-separator
      display: block
      margin-bottom: 20em
      border: 2px solid
      color: $yellow
      background: $yellow
      
    .view-comment
      float: left
      background: $yellow
      border-radius: 50%
      margin-right: 0.5em
      margin-top: -0.3em
      height: 1.8em
      width: 1.8em
      margin-bottom: 1em
      
    .comment-text
      clear: both
      margin-top: -0.5em
      margin-bottom: 1.5em
      
a.previous
  &:before
    font-family: 'tekla-icomoon' !important
    content: "\e900"
    transform: rotate(180deg)
    position: absolute
    font-size: .8em
    
.previous-link-text
  margin-left: 0.8em
    
a.next
  &:after
    font-family: 'tekla-icomoon'
    content: "\e900"
    transform: rotate(90deg)
    font-size: .8em

section.result-options

  .toggle-button button
    color: $blue

    &:hover, &.active
      color: white

  .summary
    clearfix()
    
    @media desktop-max
      margin-left: 0em
      
  .local-search-filters
    padding-right: 3em
    
  section.search
    max-width: 30em
    float: left
    font-size: .75em
    margin-top: 0.8em
    margin-left: 0em
    margin-right: 1em
    
    &.local-search
      float: right
      margin-top: -0.2em
      margin-left: 17px
      margin-right: 0em
      
      @media tablet-horizontal-max
        float: left
        margin-left: 1em
    
    &.alerts-search, &.likes-search 
      float: right
      margin-top: -0.2em
      margin-left: 17px
      margin-right: 0em
      
      @media tablet-horizontal-max
        clear: both
        float: left
        margin-left: 0em
        margin-bottom: 1em
      
    @media tablet-horizontal-max
      clear: both
      float: left
      margin-left: 0em
      margin-bottom: 1em

.organizations, .catalog
  aside
      width: 30%
      padding: 0 default-margin*2
      box-sizing: border-box
      background: $almost-white
      position: absolute
      right: 0
      height: 300em
      
      @media tablet-horizontal-max
        width: 30%
        padding: 0 1em

      .editor-mode
        vertical-align: middle
        overflow: hidden
        padding: default-padding*2 0
        
        @media tablet-horizontal-max
          clear: both
          float: left
          margin-bottom: 1em
          
        .editor-mode-button
          float: right
      
          @media tablet-horizontal-max
            clear: both
            float: left

        span
          float: left
          margin: default-padding

      hr
        margin-top: 10px
        border-bottom: 1px solid $very-light-grey
        border-top: none

      .company-logo-organization
        img
          width: 100%
          min-height: 5em
          max-height: 200px
          margin-top: 0em

      ol.more,.downloads-graph
        margin: default-margin*2 0 0.5em
        font-size: .85em
        
        @media mobile-horizontal-max
          display: none
      

        a.report-bad-content
          position: absolute
          bottom: 10px;

        li
          margin: 0
          padding: default-padding*.5
          text-decoration: none
          color: $default-text-color
          display: block

          span, p
            &.icon
              margin-left: 0em
              margin-right: .5em
              margin-top: -1em

          span.label
            color: $default-text-color
            display: block

          &:first-child
            span.user, span.user a
              padding: default-padding

          .latest-update
            text-align: right
            
      section.social-media-links
        font-size: 2em
        margin-top: default-margin

        a.icon
          color: $blue
          padding: 0 default-padding default-padding 0
     

          
section.content-details
  
  .company-logo
    text-align: right
    margin-right: 2.5em
    img
      width: 100px
      
    @media tablet-horizontal-max
      margin-right: 0em

  .editor-mode
    float: right
    margin-top: 0.2em
    margin-right: 2.7em
    
    @media tablet-horizontal-max
      clear: both
      float: left
      margin-bottom: 1em
      
    .editor-mode-button
      float: right
      
      @media tablet-horizontal-max
        clear: both
        float: left
        
    .edits
      
      clear: both
      
      .translations
        float:left
        
         @media tablet-horizontal-max
          clear: both
          float: initial
          
      .batch-edit
        clear: both
        
      .batch-translations
        clear: both
        
      .cart-info
        float:left
        margin-left: 0.5em
        margin-top: 0.7em
        font-size: 1.5em
        
        @media tablet-horizontal-max
          clear: both
          float: initial
        
      button
        float: left
        margin-left: 0em
        margin-right 0em
        
        @media tablet-horizontal-max
          clear: both
          float: initial

      


    .toggle-button
      margin-left: default-margin
      vertical-align: middle

  a.icon-pencil
    display: block
    
    @media tablet-horizontal-max
      clear: both

  header
    h1
      display: inline-block
      margin: 0 0 0 0

      .icon
        margin-right: .2em
        width: 40px
        height: 40px
        font-size: 0.7em

    ol.more
      margin: 0 0 default-margin*3 0

      li.action-item
        list-style: none
        position: absolute
        margin-right: default-margin

      li.links
        left: 5em

        ol > li
          list-style: none
          margin-left: default-margin

      .icon
        font-size: 1.5em

        &.private
          margin: 0.0em 0.6em
          line-height: 23px
          font-size: 24px

      .count
        vertical-align: super

      .link-popup
        background: $background-color
        position: relative
        top: -48px
        left: 55px
        padding: default-padding
        border: 1px solid $very-dark-gray
        min-height: 100px
        max-width: 300px

      .link-popup:after, .link-popup:before
        right: 100%
        top: 50%
        border: solid transparent
        content: " "
        height: 0
        width: 0
        position: absolute
        pointer-events: none

      .link-popup:after
        position: absolute
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $background-color
        border-width: 10px
        top: 21px

      .link-popup:before
        border-color: rgba(194, 225, 245, 0);
        border-right-color: $very-dark-gray
        border-width: 11px
        top: 20px

  article
    
    section.social-media-links
      font-size: 2em
      margin-top: default-margin
      display: table 
      
      h4.social-media-title
        float: left
        margin-top: 0.5em

      a.icon
        color: $blue
        display: table-cell
        vertical-align: top
        padding: 0 0 default-padding default-padding
        font-size: 1em
      
    .description
      display: table
      width: 100%

      a.icon
        margin-bottom: default-margin

      h3
        font-size: 1.5em
      p
        margin-top: 0

      ol
        clearfix()
        border-bottom: 1px solid lighten(gray, 80%)
        padding: default-padding 0

        li
          padding: 0 default-padding*3

      .column
        display: table-cell
        vertical-align: top
        width: 20%
        padding: default-padding default-padding default-padding 0
        
        @media tablet-horizontal-max
          display: block
          
        .view-3d-info
          position: absolutef
          z-index: 10000
          font-size: 1.5em
          opacity: 0.8
          text-align: center
          top: 20px
          width: 100%
          cursor: pointer
          
        .show-download-dialog, .download-on-partner-site
          width: 100%
          min-height: 2em
          margin-left: 0em
          margin-bottom: 0em
          margin-top: 0.3em
          font-size: 1.2em
          
          @media tablet-horizontal-max
            width: 75%
                
        .login-to-download
          width: 100%
          height: 2em
          margin-left: 0em
          margin-bottom: 0em
          margin-top: 0.3em
          font-size: 1.2em
          
          @media tablet-horizontal-max
            width: 75%
            
        .long-description, short-description
          @media tablet-horizontal-max
            width: 75%
          
        .download-dialog
          position: absolute
          width: 99.5%
          background: white
          border: 1px solid $very-dark-gray
          
          .maintenance-info
            padding: 1em 2em 0 2em
            
            .icon
              margin-right 0.25em
              
          ul
            display: table
            
          li
            list-style: none
            display: table-cell
            width: 50%
          
          button
            text-align: left
            margin-left: 0
            margin-right: 2em
            width: 10em
            
          a.button
            margin-left: 0em
            
          a
            margin-right: 0.5em
          
          .version
            padding: 1em 2em 0.5em
            
            h3.title
              width: 100%
              margin-top: 0
              margin-bottom: 0
              font-family: font-semi-bold
            
            .actions
              display: table
              width: 100%
              
              .action-item
                display: table-cell
                width: 50%
                
                .icon
                  float: left
                  margin-right: 7px
                  font-size: 1em
                  line-height: 1em
                  margin-top: 1px
                  
                .icon-download
                  margin-right: 2px
          
          
        &.thumb
          width: 25%
          
        &.description-box-right
          width: 35%

        &.description-box          
          min-width: 25em
          float: left
          position: relative
          padding: 0
           
          button.show-3d-pic
            margin-left: 0em
            min-width: 8em
          
          ul.description-labels
            list-style: none
            color: $default-text-color
            font-weight: bold
            font-size: 1em
            line-height: 1.4em
            padding: 0
            margin-bottom: default-margin*2

            li
              padding: 0

            .label
              color: $default-text-color

      @media mobile-max
        display: inline-block

        .column
          display: block
          width: 100%
          
      button.button-edit-images
        min-width: 6em
        margin-left: 6.5em
        margin-top: 3em

      .thumb-box
        width: 320px
        height: 240px
        margin-right: default-margin
        min-height: 15em
        
        @media mobile-horizontal-max
          width: 300px
        
        &.thumb-box-small
          width: 5em
          height: 5em
          min-height: 5em
          margin-top: -0.5em
          margin-left: 10%
          
          @media tablet-horizontal-max
            margin-left: 35%
            
        &.big-margin
          margin-top: 1.5em
          
        &.big-video-margin
          margin-top: 2.8em
          margin-bottom: -1.25em
          
        &.video-margin
          margin-top: 2em
          
      .thumb-box-narrow
        @media mobile-horizontal-max
          margin-left: 0em
          width: 11.5em
          min-height: 0em

.role-label
  margin-left: default-margin
  font-size: 12px
  font-family: font-semi-bold
  text-transform: lowercase
  padding: default-padding*.5 default-padding
  background-color: $very-light-grey
  color: $blue
  border-radius: .25em
  vertical-align: middle
  
.scan-info-sign, .banned-info-sign
  position: absolute
  float:left
  width: 30px
  height: 30px
  color: $almost-white
  background-color: $red
  border-radius: 50%
  font-size: 1.3em
  line-height: 1.5em
  margin-left default-margin
  margin-top: 0.55em
  border: 1px solid
  border-color: $almost-white
  text-align: center
  margin-top: -0.5em

  &:before
    content: '!'


.warnings
  position: absolute
  margin-left 39em
  
  &.organizations
    margin-left 58em
    height: 3em
    
    @media desktop-max
      clear: both
      position: relative
      float: left
      margin-left: -1em
      margin-right: 1em
      margin-bottom: 1em
      height: 100px
  

.warning-sign
  position: absolute
  float:left
  width: 30px
  height: 30px
  color: $almost-white
  background-color: $yellow
  border-radius: 50%
  font-size: 1.3em
  line-height: 1.5em
  margin-left default-margin
  margin-top: 0.55em

  &:before
    content: '!'

.warning-text
  margin-left: 3.5em
  font-family: font-normal
  text-transform: none
  color: $default-text-color
  text-align: left
  margin-top: 0.8em
  
  &.organizations
    margin-top: 1em

.react-autosuggest__container[aria-expanded="false"]

  .react-autosuggest__suggestions-container
    border: none

.react-autosuggest__suggestions-container

  position absolute
  width 100%
  background-color white
  border 1px solid $dark-gray
  box-sizing border-box
  text-align initial
  margin-top -2px
  z-index 900

  ul.react-autosuggest__suggestions-list

    padding 0.25em

    li
      margin-bottom 0
      color $blue
      padding default-padding*.5 default-padding
      cursor pointer
      display block
      width 100%
      font-weight normal
      font-family font-normal
      box-sizing border-box

      &:hover, &.active
        background-color $blue
        color white

.dropdown-menu
  position absolute
  width 100%
  background-color white
  border 1px solid $dark-gray
  box-sizing border-box
  text-align initial
  padding default-padding*.5
  margin-top -2px
  z-index 1000

  li
    margin-bottom 0
    color $default-text-color
    padding default-padding*.5 default-padding

    &:hover, &.active
      background-color $blue
      a
        color white

    a
      display block
      width 100%
      font-weight normal
      font-family font-normal
      box-sizing border-box
      
      
.confirm-delete
  font-family: font-light
  font-size: 1.5em
  margin-top: 4em
  margin-bottom: 2em
  
.confirm-delete-buttons
  margin-top: 2em
  margin-bottom: 4em
  
.translations-info
  font-weight normal
  font-family font-normal
  font-size: 1em
  text-align: center
  margin-top: 2em
    
  table:not(.simple)
    tbody
      tr
        background: white
        
        &:hover
          color: $default-text-color
  
  .first-column
    width: 30%
    
    @media mobile-horizontal-max
      width: 0%
    
  .second-column
    width: 45%
    
    @media mobile-horizontal-max
      width: 100%
    
  .third-column
    width: 30%
    
.edit-translations
  margin: 0em 1.5em
  border: 1px solid $almost-white
  vertical-align: top
  overflow: visible
  padding: default-padding*2
  box-sizing: border-box
  height: 80% 
  
  @media mobile-horizontal-max
    margin: 0em 0em
  
  .collection-language
    width: 30%
    min-width: min-content
    display: inline-flex
    margin-left: 1em
  
  .language
    width: 30%
    min-width: min-content
    display: inline-flex
    margin: 1em 1em
    
    @media mobile-horizontal-max
      width: 70%

    .dropdown-wrapper
      width: 100%
  
  .action
    margin-top: 0.5em
    display: inline-flex
    
    .save-translations
      float: left
      
    .translate-all
      float:left
  
    .close-translations
      float:left
    
  .edit-translation-fields
    width: 96%
    margin-top: 2em
    margin-left: 1em
    margin-bottom: 0em
    font-size: 1em
    
      
    .edit-translation-field
      margin-bottom: 1em
      width: 100%
      
      label
        font-size: 1em
      
      input
        width: 100%
        padding 0.5em
        font-size: 1em
        
.radio-title
  margin-bottom: 1em
  font-family: font-semi-bold
        
.radiowrapper-search
  height: 70px;

  .radio-search
    position: relative
    float: left
    width: 18px
    height: 18px
    border-radius: 50%
    background-color: $almost-white
    border: 1px $almost-white
    margin-bottom: 1em
    margin-right: 17em
    
    label
      margin-left: 1.5em
      margin-top: -3em
      width: 12em
      font-size: 1em
      text-align: left
       
      &:not(.button)
        display: block
        padding: default-padding 0
        font-size: 1em
        color: $default-text-color

        &.icon
          &:before
            margin: 0 default-padding
    
    &:hover
      background-color: #9997ad;
    
  .radioactive-search
    position: relative
    float: left
    width: 18px
    height: 18px
    border-radius: 50%
    margin-right: 10px
    background-color: $almost-white
    border: 1px solid $almost-white
    margin-right: 17em
    margin-bottom: 1em
    
    label
      margin-left: 1.5em
      margin-top: -3em
      width: 12em
      font-size: 1em
      text-align: left
      
      &:not(.button)
        display: block
        padding: default-padding 0
        font-size: 1em
        color: $default-text-color

        &.icon
          &:before
            margin: 0 default-padding
              
    .radioactivedot-search
      display: inline-block
      position: absolute
      top: 6px
      left: 6px
      width: 6px
      height: 6px
      border-radius: 50%
      background-color: $blue
      border: 0px
      
.collection-cart-instructions
    min-height: 10em
    color: $dark-text-color
    background-color: $very-light-grey
    
    .steps
      margin: 0 auto 0 auto
      max-width: 1440px
      padding: 2em 2em 2em 4em
      width: 100%
      display: table
      
      @media tablet-horizontal-max
        padding: 1em 2em
        
      .select2-container, .select2-choice
        background-color: white !important
        
        &:hover
          background-color: white !important
          color: $default-text-color
      
      .step-numbering
        text-align: center
        float:left
        width: 55px
        font-size: 1.2em
        height: 55px
        color: $almost-white
        background-color: $blue
        border-radius: 50%
        line-height: 55px
        
      .step-one
        &:before
          content: '1'
          
      .step-two
        &:before
          content: '2'
          
      .step-three
        &:before
          content: '3'
      
      .choose-version
        float: left
        margin-bottom: 1em
        margin-right: 1em
        display: table-cell
        width: 25%
        
        @media tablet-horizontal-max
          width: 100%
        
        .text
          float: left
          margin: 1em 1em 2em 1em
          
        .action
          clear: both
          float: left
          width: 50%
          margin-left: 4.5em
          
          @media tablet-horizontal-max
            width: 20%
            min-width: 10em
            
          @media mobile-horizontal-max
            margin-left: 1em
                  
      .choose-content
        float: left
        margin-bottom: 1em
        margin-right: 1em
        display: table-cell
        width: 30%
        
        @media tablet-horizontal-max
          width: 100%
        
        .text
          float: left
          margin: 1em 1em 2em 1em
          
        .action
          clear: both
          float: left
          margin-bottom: 1em
          margin-left: 4.5em
        
      .choose-action
        float: left
        margin-right: 1em
        display: table-cell
        width: 40%
        
        @media tablet-horizontal-max
          width: 100%
          
        .icon
          float: left
          margin-right: 7px
          font-size: 1em
          line-height: 1em
          margin-top: 1px
          
        .icon-download
          margin-right: 2px
                  
        .text
          float: left
          margin: 1em 1em 2em 1em
          
        .action
          clear: both
          float: left
          margin-bottom: 1em
          margin-left: 4.5em
          
          @media mobile-horizontal-max
            margin-left: 1em
          
          button
            margin: 0em 0.5em 0em 0em !important
            
        .error-messages
          font-size: 0.7em
          clear: both
          float: left
          margin-left: 6em
          
          @media tablet-horizontal-max
            margin-left: 1em
         
        .requires-maintenance
          clear: both
          float: left
          margin-left: 6.5em
          font-size: 0.7em
          min-height: 1em
          
          @media tablet-horizontal-max
            margin-left: 1em
            
          .icon-maintenance
            float:left
            color: $yellow
    
          .maintenance-text
            margin-left: 0.1em
            float: right
            
        .show-result-options
          display: inline-block
          float: right
          
          @media tablet-horizontal-max
            float: left
            margin-left: 1em
            
section.collection-cart-result-options
  background: $almost-white
    
  .select2-container, .select2-choice
    background-color: white !important
    
    &:hover
      background-color: white !important
      color: $default-text-color !important
  
  .toggle-button
    margin-top: 0.75em
  
  @media tablet-horizontal-max
    padding: default-padding*2 default-padding*6
      
  .search-results-tabs
    text-transform: uppercase
    font-family: font-semi-bold
    font-size: 0.9em
    height: 4em
    text-align: center
    margin-top: default-margin*3

    .search-result-tab
      float: left
      width: 18em
      border: 1px solid $very-light-grey
      border-bottom: none
      height: 4em
      margin-left: default-margin*2
      background-color: $blue
      color: $almost-white
      cursor: pointer
      
      &:first-child
        margin-left: 1em

      &:hover
        background-color: $yellow

      .result-tab-icon
        font-size: 2em
        margin-top: 0.2em
        
      .result-tab-icon-organization
        font-size: 1.7em
        margin-top: 0.2em

      &.active
        background-color: white
        color: $default-text-color
        
  .error-messages
    font-size: 0.7em
      
  .version-sort
    margin-bottom: 0.5em
    max-width: 1440px
    margin: 0 auto 0 auto
    padding: 2em 2em 2em 4em
    
    @media tablet-horizontal-max
      padding: 2em 2em 2em 2em
      
    clearfix()

    .version-downloads, .sorts
      display: flex
      flex-wrap: wrap

    .sorts
      .toggle-button
        height: fit-content
      
    .sorting-options
      float: left
      margin-right: 1em
      margin-top: 0.75em
      margin-left: 0em
      
      @media tablet-horizontal-max
        clear: both
        float: left
      
    .search
      float: left
      margin-top: 0.36em
      margin-right: 1em
      font-size: 1em
      height: 36px

      input 
        border: 1px solid #e7e6ed
      
      @media tablet-horizontal-max
        clear: both
        float: left
        margin-left: 0em
        margin-bottom: 0.1em
      
    .version-buttons
      float: left
      margin-right: 1em
      
      @media mobile-horizontal-max
        float: none
        
      .choose-action-info
        float: left
        margin: 1em 1em 1em 0em 
        
      .unlink-selected
        float: left
      
      .remove-from-basket
        float: left
        
        @media mobile-horizontal-max
          float:none
          margin-left: 0em
          
        @media tablet-horizontal-max
          float:none
          margin-left: 0em
          
      .install
        float:none
        margin-left: 0em
        
      .link-selected
        float: left
        margin-left: 0em
        
      .download
        position: relative
        margin-left: 9.6em
        
       
      .cart-button-text
        float: left
          
      .icon-warehouse
        font-size: 14px
        margin: 1px 0px 0px 8px
        float: right
      
      button:first-child
        margin-left: 0
        
      .link-popup
        background: $background-color
        position: absolute
        margin-left: 9.5em
        border: 1px solid $very-dark-gray
        min-height: 100px
        min-width: 300px
        max-width: 400px
        z-index: 10
        
        @media desktop-small
          top: 0em
          margin-left: 0em
          
        .loading-indicator
          text-align: center
          height: 100%
          width: 100%
          position: absolute

          .spinner
            position: relative
            transform translate(-50%, -50%)
            -webkit-transform translate(-50%, -50%)
            -ms-transform translate(-50%, -50%)
            top: 50%
            left: 50%

        .popup-content
          padding: default-padding


      .link-popup:after, .link-popup:before
        right: 100%
        top: 50%
        border: solid transparent
        content: " "
        height: 0
        width: 0
        position: absolute
        pointer-events: none

      .link-popup:after
        position: absolute
        border-color: rgba(136, 183, 213, 0);
        border-right-color: $background-color
        border-width: 10px
        top: 21px

      .link-popup:before
        border-color: rgba(194, 225, 245, 0);
        border-right-color: $very-dark-gray
        border-width: 11px
        top: 20px


  .email-info
    margin-top: 25px
    line-height: 15px
    margin-left: 2px

    span.label
      font-family: font-normal
      font-size: 0.8em
      text-transform: none

  .local-online-filter
    float: left

    .icon
      padding 0
      line-height: .25em
      font-size: 1.25em
      vertical-align: sub
      &:before
        margin-right: .5em

  .item-type-menu
    float: left

    .dropdown-wrapper
      width: 12em

  .email-notifications
    margin-top: 5px
    float: left
    max-width: 40%
    position: relative

  ol.item-type-categories
    margin: 0
    min-width: 11em
    list-style-type: none
    border: 1px solid $very-light-grey
    position: absolute
    top: 0px
    left: 0px
    z-index: 10
    li
      box-sizing: border-box
      text-overflow: ellipsis
      margin: 0
      color: black
      display: none
      a
        display block
        padding: default-padding default-padding*2
        text-decoration none
        font-size: .9em
        color: $default-text-color
        text-overflow: ellipsis;
        width: 100%;
        box-sizing: border-box
        overflow: hidden;

        &:hover
          color: $yellow

        &.selected, &.active
          background-color: $very-light-grey

          &:hover
            color: $default-text-color

    li:first-child
      display: block
    li:first-child a
      &:before
        font-family: 'tekla-icomoon' !important
        content: "\e900"
        color: $default-text-color
        transform: rotate(90deg)
        position: absolute
        right: default-margin
        font-size: .8em

  ol.item-type-categories:hover
    background-color: $almost-white
    li
      display: block
