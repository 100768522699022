// TODO, move with dialog styles
.loader
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0)
  & > div
    height 100%
  .spinner
    left 50% !important
    top 50% !important
