.profile

  h1
    margin-left: .2em
    
    @media mobile-horizontal-max
      margin-top: 0em
    
  section.profile-preferences
    article.user-info
      position: relative
      
      ul.profile-pic
        display: inline-block
        width: 31%
        vertical-align: top
        padding: default-padding
        
        @media mobile-horizontal-max
          display: none

        li
          margin-bottom: 1.5em
          .icon
            margin-left: default-padding
            margin-right: default-padding
            
        .profile-image
          margin-bottom: 1em
          
          img
            width: 100%

        .thumb-box
          width: 19.5em
          height: 19em
          max-width: 21em
          background-image: url("https://account.tekla.com/images/defaultProfilePic.svg")
          background-size: cover;

          figure
            padding-top: 100%


      ul
        display: inline-block
        width: 31%
        vertical-align: top
        padding: default-padding
        
        @media mobile-horizontal-max
          width: 100%

        li
          display: flex
          flex-wrap: wrap
          margin-bottom: 1.5em
          .icon
            margin-left: default-padding
            margin-right: default-padding
            
        .profile-image
          margin-bottom: 6em
          
          img
            width: 100%

        .thumb-box
          width: 19.5em
          height: 19em
          max-width: 21em
          background-image: url("https://account.tekla.com/images/defaultProfilePic.svg")
          background-size: cover;

          figure
            padding-top: 100%

      .button.edit
        margin: 0
        margin-bottom: 0em
        bottom: 0
        
        @media mobile-horizontal-max
          margin-bottom: -2em

    .roles-table
      display: inline-block
      width: 100%
      vertical-align: top
      padding: default-padding

      h2
        margin-top: 1em

  article
    margin: 0 auto
    
    .buttons
      margin-top 2.5em
    
  .work-for
    .catalog-searchbar
      padding: 0
      background: transparent

.alerts
  .results-summary
    margin-top: default-margin*2

