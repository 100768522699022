// Trimble Colors (rebranding)
$very-dark-gray     = #9997ad
$darker-gray        = #636172
$dark-gray          = #7e7d8b
$yellow             = #ffbe00
$light-yellow       = #ffd200
$red                = #d52a33
$blue               = #003f87
$dark-blue          = #212a60
$very-light-grey    = #e7e6ed
$almost-white       = #f8f7fa
$default-text-color = #73718b
$dark-text-color    = #363545
$light-ascent-color = #D0CFD9
$warning            = $red
$green              = #4d6f33
$background-color   = #ffffff
$light-blue         = #005F9E
$very-light-blue    = #009ad9
$light-grey         = #e2e2e7
$lighter-grey       = #f3f3f7
$navi-grey          = #f9f9fb
$white              = #ffffff
$very-dark-blue     = #00437b
$input-border       = #d0d0d7
$input-text         = #6a6976

// Layout
default-padding  = .5em
default-margin   = .75em

// Display width breakpoints
display-mobile-smallest     =  360px
display-mobile              =  440px
display-mobile-horizontal   =  640px
display-phablet              =  850px
display-tablet              =  768px
display-tablet-horizontal   = 1024px
desktop                     = 1440px

// Simple queries
mobile-small              = "all and (max-width: " + display-mobile-smallest + ")"
mobile-min                = "all and (min-width: " + display-mobile + ")"
mobile-max                = "all and (max-width: " + (display-mobile - 1px) + ")"
mobile-horizontal-min     = "all and (min-width: " + display-mobile-horizontal + ")"
mobile-horizontal-max     = "all and (max-width: " + (display-mobile-horizontal - 1px) + ")"
tablet                    = "all and (width: " + display-tablet + ")"
tablet-horizontal         = "all and (width: " + display-tablet-horizontal + ")"
tablet-min                = "all and (min-width: " + display-tablet + ")"
tablet-max                = "all and (max-width: " + (display-tablet) + ")"
phablet-min               = "all and (min-width: " + display-phablet + ")"
phablet-max               = "all and (max-width: " + (display-phablet - 1px) + ")"
tablet-horizontal-min     = "all and (min-width: " + display-tablet-horizontal + ")"
tablet-horizontal-max     = "all and (max-width: " + (display-tablet-horizontal) + ")"
desktop-max               = "all and (max-width: " + (desktop - 1px) + ")"

//Translations export dialog specific breakpoints
export-first-break        = "all and (min-width: " + (desktop + 100px) + ")"
export-second-break       = "all and (min-width: " + (desktop + 200px) + ")"
export-third-break        = "all and (min-width: " + (desktop + 400px) + ")"
export-fourth-break       = "all and (min-width: " + (desktop + 300px) + ")"

//Header related breakpoints
navi-first-break          = "all and (max-width: " + (desktop - 250px) + ")"
navi-second-break         = "all and (max-width: " + (desktop - 400px) + ")"
navi-third-break          = "all and (max-width: " + (desktop - 750px) + ")"
navi-fourth-break         = "all and (max-width: " + (desktop - 850px) + ")"
navi-fifth-break          = "all and (max-width: " + (display-mobile - 1px) + ")"
navi-sixth-break          = "all and (max-width: 415px)"



// Fonts
@font-face
  font-family: Pictos
  font-display: swap
  font-style: normal
  src: url('../../fonts/pictos_webfont.eot?#iefix') format('embedded-opentype'), \
       url('../../fonts/pictos_webfont.ttf') format('truetype'), \
       url('../../fonts/pictos_webfont.woff') format('woff'), \
       url('../../fonts/pictos_webfont.svg#svgFontName') format('svg')

@font-face
  font-family: Glyphish
  font-display: swap
  font-style: normal
  src: url('../../fonts/glyphish_webfont.eot?#iefix') format('embedded-opentype'), \
       url('../../fonts/glyphish_webfont.ttf') format('truetype'), \
       url('../../fonts/glyphish_webfont.woff') format('woff'), \
       url('../../fonts/glyphish_webfont.svg#svgFontName') format('svg')

@font-face
  font-family: 'tekla-icomoon'
  font-display: swap
  font-weight: normal
  font-style: normal
  src: url('../../fonts/icomoon.eot?-908dwl');
  src: url('../../fonts/icomoon.eot?#iefix-908dwl') format('embedded-opentype'),
       url('../../fonts/icomoon.woff?-908dwl') format('woff'),
       url('../../fonts/icomoon.ttf?-908dwl') format('truetype'),
       url('../../fonts/icomoon.svg?-908dwl#tekla-icomoon') format('svg');

font-normal = "Open Sans", sans-serif
font-light = "Open Sans Light", sans-serif
font-semi-bold = "Open Sans Semi-bold", sans-serif
font-bold = "Open Sans Bold", sans-serif
font-italic = "Open Sans Italic", sans-serif
