html, body
  font-size: 1em
  background: $background-color

  &.dialog-open
    overflow: hidden

#root
  display: flex
  flex-direction: column
  min-height: 100vh
  min-width: fit-content

html.is-ie
  .hide-from-IE
    display: none !important

html:not(.is-ie)
  .show-only-for-IE
    display: none !important

#container
  min-height: 100%
  position: relative
  clearfix()
  box-sizing: border-box
  margin: 0 auto 0

#content,#content-details
  min-height: 100%
  margin: 0 auto
  width: 100%
  box-sizing: border-box
  
  .publish-phase
    margin: 5% 5%

  #sidebar
    float: left
    width: 30%
    padding: 0 default-padding*5 0 default-padding*2
    box-sizing: border-box
    margin-bottom: default-margin*3

    @media desktop-max
      width: 30%
    @media tablet-horizontal-max
      width: 32
    @media tablet-max
      float: none
      width: 100%

    .warehouse-service-info
      padding-left: 1em
      padding-top: 1.5em

  .content-sidebar
    float: left
    width: 45%
    margin-bottom: default-margin*3
    @media desktop-max
      width: 50%
    @media tablet-horizontal-max
      width: 48%
    @media tablet-max
      padding: 5% 10% 0%
      margin-top: -4em
      width: 100%

table
  td
    padding: 0.5em

hr
  display: block
  border: none
  border-top: 1px solid lighten(gray, 80%)
  border-bottom: 1px solid white
  margin: 0
  padding: 0.5em
  line-height: 0
  font-size: 1px
  width: 100%
  overflow: visible

// SMACSS styles

.l-right
  float right
.l-left
  float left
.l-width-10
  width: 10%
.l-width-20
  width: 20%
.l-width-25
  width: 25%
.l-width-40
  width: 40%
.l-width-45
  width: 45%
.l-width-50
  width: 50%
.l-width-60
  width: 60%
.l-width-70
  width: 70%
.l-width-80
  width: 80%
.l-width-90
  width: 90%
.l-full-width
  width: 100%
.l-center
  margin auto
.l-middle
  position: absolute
  top: 50%
  transform: translateY(-50%)
.is-hidden
  display: none !important
.no-hover
  &:hover
    cursor: default
.l-center-align
  text-align: center

.l-margin-right-1em
  margin-right 1em

.l-margin-default
  margin default-margin

.l-margin-left-default
  margin-left default-margin

.l-no-margin
  margin: 0 !important

.l-padding-default
  padding default-padding

.l-opacity-30
  opacity: .3

.l-inline
  display: inline-block !important
.l-block
  display: block !important

.l-relative
  position: relative

.background-red
  background-color $red

.background-blue
  background-color $blue
  color white
  a
    color $yellow

.background-warning
  background-color $warning

.login-note
  padding-bottom: 4em !important