.version-options
  margin-top: default-margin
  
.select-all-option
  
  .select-all
    position: relative
    margin-top: 0   
    
.local-sorting
  padding: 2em 3em 2em 4em
  
  @media tablet-horizontal-max
    padding: 0em 2em 2em 2em
       
.category-type-options
  float:right
  white-space: nowrap
  margin-top: 0.75em
  margin-right: 0em
  
  .category-dropdown
    display: inline-block  
  
.sorting-options-search
  float: left
  width: 100%
  white-space: normal
  padding: 1.5em 0em
  margin-top: 0.75em
  border-top: 1px solid $very-dark-gray
  
  .sort-dropdown-search, .version-dropdown-search
    display: inline-block
    margin-left: 0em
    width: 100%
    
    span.label
      margin-right: default-margin
      margin-bottom: 1em
      color: black !important
      font-family: font-semi-bold
      color: $default-text-color
      text-transform: initial
      transform: translateY(40%)
      float: left

    .dropdown-wrapper-search
      float: left
      width: 100%
      
    .sort-dropdown-wrapper-search
      float: left
      width: 100%
  

.sorting-options, .version-options
  float: left
  white-space: nowrap
  margin: 0.75em 1em 0 0
  
  &.local-sorting-options
    margin: 0em 0em -1em 1em
    float: right
    
    .local-sort-dropdown
      margin-right: 1em
    
    @media tablet-horizontal-max
      clear: both
      float: left
      margin: 1em 0em 0 0em
  
  &.alerts-sorting-options, &.likes-sorting-options 
    margin-top: 0.3em
    float: right
    
    @media tablet-horizontal-max
      clear: both
      float: left
      margin-left: 0em

    
  &.likes-sorting-options
    margin-top: 0em
  
  @media tablet-horizontal-max
    clear: both
    float: left
    margin-left: 0em

  .sort-dropdown, .version-dropdown
    display: inline-block
    
    &.alerts-sort-dropdown, &.likes-sort-dropdown 
      @media mobile-horizontal-max
        clear: both
        float: left
        margin-bottom: 1em
      
    
    span.label
      margin-right: default-margin
      font-family: font-normal
      color: $default-text-color
      text-transform: initial
      transform: translateY(40%)
      float: left
      
      @media mobile-horizontal-max
        margin-bottom: 1em

    .dropdown-wrapper
      float: left
      width: 11em
      margin-right: 1em    
      
    .sort-dropdown-wrapper
      float: left
      width: 11em
            
  .sort-dropdown-mobile-search
    @media mobile-horizontal-max
      clear: both
      float: left
      margin-top: 1em


  .version-dropdown
    @media mobile-horizontal-max
      float: right
      margin-left: 0em  
      width: 20em
       
.show-as
  float: left
  display: inline-block
  margin-top: 0.75em
  
  &.local-show-as
    margin-top: 0em
    margin-bottom: 2em
    margin-left: 1em
    float: right
  
  &.show-as-search
    float: left
    margin-left: 0em
  
  &.search-show-as
    float: left
    margin-left: 0em
  
  &.alerts-show-as, &.likes-show-as
    margin-top: 0em
    margin-left: 1em
    float: right
    
    @media tablet-horizontal-max
      clear: both
      float: left
      margin: 0 1em 1em 0
  
  @media tablet-horizontal-max
    clear: both
    float: left
    margin-left: 0em
    margin-top: 0em
    
  .show-as-search-mobile
    
    @media mobile-horizontal-max
      clear: both
      float: left