/// normalize.css 2011-11-04T15:38 UTC - http://github.com/necolas/normalize.css

// HTML5 display definitions

// Corrects block display not defined in IE6/7/8/9 & FF3
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section
  display: block

// Corrects inline-block display not defined in IE6/7/8/9 & FF3
audio, canvas, video
  display: inline-block

// Prevents modern browsers from displaying 'audio' without controls
audio:not([controls])
  display: none

// Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
// Known issue: no IE6 support
[hidden]
  display: none

// Base

html
  // Corrects text resizing oddly in IE6/7 when body font-size is set using em units
  // http://clagnut.com/blog/348/#c790
  font-size: 100%

  // Keeps page centered in all browsers regardless of content height
  overflow-y: scroll

  // Prevents iOS text size adjust after orientation change, without disabling user zoom
  // www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%

// Addresses margins handled incorrectly in IE6/7
body
  margin: 0

// Addresses font-family inconsistency between 'textarea' and other form elements.
body, button, input, select, textarea
  font-family: sans-serif

// Links

a
  // Addresses outline displayed oddly in Chrome
  &:focus
    outline: thin dotted

  // Improves readability when focused and also mouse hovered in all browsers
  // people.opera.com/patrickl/experiments/keyboard/test
  &:hover, &:active
    outline: 0

// Typography

// Neutralise smaller font-size in 'section' and 'article' in FF4+, Chrome, S5
h1
  font-size: 2em

// Addresses styling not present in IE7/8/9, S5, Chrome
abbr[title]
  border-bottom: 1px dotted

// Addresses style set to 'bolder' in FF3+, S4/5, Chrome
b, strong
  font-weight: bold

blockquote
  margin: 1em 40px

// Addresses styling not present in S5, Chrome
dfn
  font-style: italic

// Addresses styling not present in IE6/7/8/9
mark
  background: #ff0
  color: #000

// Corrects font family set oddly in IE6, S4/5, Chrome
// en.wikipedia.org/wiki/User:Davidgothberg/Test59
pre, code, kbd, samp
  font-family: monospace, serif
  font-size: 1em

// Improves readability of pre-formatted text in all browsers
pre
  white-space: pre
  white-space: pre-wrap
  word-wrap: break-word

q
  // Addresses CSS quotes not supported in IE6/7
  quotes: none

  // Addresses quote property not supported in S4
  &:before, &:after
    content: ''
    content: none

small
  font-size: 75%

// Prevents sub and sup affecting line-height in all browsers
// gist.github.com/413930
sub, sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline

sup
  top: -0.5em

sub
  bottom: -0.25em

// Lists

ul, ol
  margin: 0
  padding: 0

dd
  margin: 0 0 0 40px

nav
  ul, ol
    list-style: none
    list-style-image: none

// Embedded content

img
  // Removes border when inside 'a' element in IE6/7/8/9, FF3
  border: 0

  // Improves image quality when scaled in IE7
  // code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
  -ms-interpolation-mode: bicubic

// Corrects overflow displayed oddly in IE9
svg:not(:root)
  overflow: hidden

// Figures

// Addresses margin not present in IE6/7/8/9, S5, O11
figure
  margin: 0

// Forms

// Corrects margin displayed oddly in IE6/7
form
  margin: 0

// Define consistent border, margin, and padding
fieldset
  border: 1px solid #c0c0c0
  margin: 0 2px
  padding: 0.35em 0.625em 0.75em

legend
  // Corrects color not being inherited in IE6/7/8/9
  border: 0

button, input, select, textarea
  // Corrects font size not being inherited in all browsers
  font-size: 100%

  // Addresses margins set differently in IE6/7, FF3+, S5, Chrome
  margin: 0

  // Improves appearance and consistency in all browsers
  vertical-align: baseline

// Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
button, input
  line-height: normal

button, input[type='button'], input[type='reset'], input[type='submit']
  // Improves usability and consistency of cursor style between image-type 'input' and others
  cursor: pointer

  // Corrects inability to style clickable 'input' types in iOS
  -webkit-appearance: button

input
  &[type="checkbox"], &[type="radio"]
    // Addresses box sizing set to content-box in IE8/9
    box-sizing: border-box

    // Removes excess padding in IE8/9
    padding: 0

  &[type="search"]
    // Addresses appearance set to search field in S5, Chrome
    -webkit-appearance: textfield

    // Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
    -moz-box-sizing: content-box
    -webkit-box-sizing: content-box
    box-sizing: content-box

    // Removes inner padding that is displayed in S5, Chrome on OS X
    &::-webkit-search-decoration
      -webkit-appearance: none

// Removes inner padding and border in FF3+
// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
button::-moz-focus-inner, input::-moz-focus-inner
  border: 0
  padding: 0

textarea
  // Removes default vertical scrollbar in IE6/7/8/9
  overflow: auto

  // Improves readability and alignment in all browsers
  vertical-align: top

// Tables

// Remove most spacing between table cells
table
  border-collapse: collapse
  border-spacing: 0
