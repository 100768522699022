.learn
  .feature-list
     margin-left: 2em
  #content
    figure
      img
        max-width: 100%
    .placeholder
      background-color: lightgray
  #sub-navigation
    margin-top: default-margin*5

    ul
      margin: 0
      width: 100%
    li
      border-bottom: 1px solid $very-light-grey
      margin: 0

      &.header
        color: $default-text-color

        a
          padding: default-padding*2 default-padding*2 default-padding*2 default-padding*7

        a.sub-items
          &:before
            font-family: 'tekla-icomoon'
            content: "\e900"
            position: absolute
            margin-left: default-padding*-5

      &.subheader
        border-bottom: 1px solid white
        margin: 0px

        a
          font-family: font-normal

      &.active
        > a
          cursor default
          background: $very-light-grey

          &.sub-items
            &:before
              transform: rotate(90deg)

      a
        display: block
        padding: default-padding
        background: white
        color: $blue
        font-size: .875em
        &:hover
          cursor: pointer
          background: $blue
          color: white


