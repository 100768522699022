.upload
        
  section.steps
    background-color: $blue
    text-align: center
    padding: default-padding*6 10% default-padding*4 10%
    overflow: hidden

    .navigation
      h1
        margin-top: 0
        margin-bottom: default-margin*3
        color: white
        font-size: 3.85em
      
      .wizard-wrapper
        margin-left: 16.9%
        
        @media mobile-horizontal-max
          margin-left: 10%
          width: 100%
          

      .wizard-line
        width: 45%
        height: 2px
        background-color: white
        opacity: .33
        margin 0 auto -55px 17%

        @media tablet-max
          width: 50%

      .wizard-navigation
        width: 80%
        margin: auto
        padding 1em 0
        list-style: none
        @media tablet-max
          width: 100%

        ul
          border-bottom: 2px
          border-color: black

        li
          // 4 columne
          width: 25%
          display: inline-block
          float: left
          box-sizing: border-box
          text-align: center
          //padding 0.5em
          margin 0 0
          color: $very-dark-gray
          font-size: 1.3em
          margin-right: 0px
          @media tablet-max
            font-size: 1em
          &.step
            color: white
            padding: 0
            position: relative

            .step-bg-wrapper
              position: absolute
              width: 100%

            .step-title
              opacity: .33

            .step-circle
              width: 45px
              height: 45px
              line-height: 43px
              margin: 0 auto default-padding auto
              background-color: white
              border-radius: 50%
              opacity: .33
              padding: .5em
              font-size: 1.5em
              color: $blue

              &.bg-circle
                background-color: $blue
                opacity: 1


            .step-title
              font-size: .75em
              font-family: font-light

              &.main
                text-transform: uppercase
                font-family: font-semi-bold

            &.active
              color: white

              .step-circle
                background-color: $yellow
                opacity: .99
                color: white
              .step-title
                opacity: .99

  .step
    text-align: center
    padding: default-padding*4 default-padding*12
    
    @media mobile-horizontal-max
      padding: default-padding*4 default-padding*2

    &.new-collection
      hr
        display: none

      .wrapper
        &.as-table
          margin-bottom: 0
          margin-top: 0

    header
      max-width: 50em
      margin: 0 auto
      h1, h2, h3
        margin-top: default-margin*2

      h2
        font-size: 2.5em
        
      h2.create-collection
          @media mobile-horizontal-max
            margin-bottom: -1em

      h3
        color: $default-text-color

      &.step-main
        margin-top: default-margin*-2f
        
        @media mobile-horizontal-max
          float: left
          margin-top: 0em

      nav
        margin-top: default-margin

    .uploadfiles
      text-align: left

        
    .details
      text-align: left
      margin-top: -3em
      margin-bottom: 2em
    
      label
        font-size: 1em
        padding: 0.5em 0em  
    
      textarea
        width: 100%
        min-height: 15em
        
    .wrapper
      margin: default-margin*2 auto default-margin*4 auto
      max-width: 30em
      text-align: left
      
      &.more-top-margin
        margin-top: 3em

      &.full-width-center
        max-width: 100%
        text-align: center

        table
          text-align: left
          
          .scan-in-progress
            color: green
          
          a.scan-failed-binary
            color: red

      &.as-table
        display: table
        width: 50%
        
        h3.visibility,  h3.owner-type 
          margin-top: 1em
          
          @media mobile-horizontal-max
            margin-top: 2em
            margin-bottom: 1em
            
      &.as-table-wide
        display: table
        max-width: 100%
        width: 100%
        
        h3.visibility,  h3.owner-type 
          margin-top: 1em
          
          @media mobile-horizontal-max
            margin-top: 2em
            margin-bottom: 1em
        
        
      input[type="text"], input[type="search"], input[type="email"], input[type="number"], input[type="url"], input[type="password"], input[type="date"], textarea
        width: 100%
        
        &.textarea-max-height
          height: 7em

        &.less-wide
          width: 40%
          margin: default-padding*2
          
      input.threed-file
        width: 55%
        
      a.remove-threed3-file
        margin-right: -1em
        
      label.threed-select-button
        margin-left: 1em

      .spinner
        margin: default-margin auto
        text-align: center

      label
        &:not(.button)
          clear: both
          display: block
          padding: default-padding 0
          font-size: 1em
          color: $default-text-color

          &.icon
            &:before
              margin: 0 default-padding*.5

      span.or
        margin-left: default-padding

      a.cancel
        margin-top: default-margin
        display: block

      .column
        display: table-cell
        padding-right: default-padding*2
        width: 33%
        
        @media tablet-horizontal-max
          display: block !important
          width: 100%

        &.last
          padding-right: 0
          
        

      button.add, .button.add
        margin: 0
        
      .disclaimers
        clear: both
        float: left
        margin-bottom: 2em
        margin-top: -1em

        .disclaimer
          margin-left: default-margin*2
          margin-bottom: 1em
          width: 100%
          display: block
          color: $very-dark-gray

      .datepicker, 
        .datepicker > div.react-datepicker-wrapper, 
          .datepicker > div > div.react-datepicker__input-container
            display: block

  .actions
    margin-top: default-margin
    &.actions-bottom
      margin-top: default-margin*2
      .previous-and-next
        clearfix()

        .dont-notify
          position: relative
          display: inline-block
          margin-top: 1em

      .temporary-upload-error-message-parent
        display: inline-flex
        align-items: center
        padding-top: 2em
        font-style: italic
        color: #da212c

        .temporary-upload-error-message
          padding-left: 2em
          padding-right: 2em