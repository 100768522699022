section.profile-filters
  margin: default-margin*4 default-margin (- default-margin)*3 default-margin

  .sbar
    width: 100%
    margin-bottom: default-margin*2

  .filter-controls
    text-align: right
    width: 100%
    font-size: 0.9em
    margin-bottom: default-margin
    a
      margin-right: default-margin

  .filter-pulldowns
    margin: default-margin 0
    text-align: center

    .pull
      display: inline-block
      text-align: initial
      margin-bottom: default-margin
      vertical-align: top
      
      @media mobile-horizontal-max
        margin-bottom: 0em

      .pull-wrapper
        display: inline-block
        width: 10em
        margin-left: default-margin*2

        .clear-filter
          margin-top: 5px


        .select2-container
          .select2-choice
            &:hover
              background-color: $dark-gray
              border: 1px solid $dark-gray
              color: white

              &.select2-default
                color: $light-ascent-color !important

      &:first-child
        .pull-wrapper
          margin-left: 0
          
          @media mobile-horizontal-max
            margin-left: default-margin*2
            
  .select2-container-multi
      display: block

      .select2-choices
        border: none
        background-color: transparent
        min-height: 2em
        padding: default-padding 0

        .select2-search-choice
          border: 1px solid $very-light-grey
          -webkit-transition: border 200ms ease-in
          -moz-transition: border 200ms ease-in
          -o-transition: border 200ms ease-in
          transition: border 200ms ease-in
          line-height: 1em
          margin: default-padding*.5 0
          float: none

          &.activated
            border: 1px solid $blue

#results
  padding: 0
  margin: 0 auto  
  display: flex
  flex-direction: row
    
.left-panel
  width: 20%
  min-width: 13em
  clear: both
  background-color: $very-light-grey
  color: black

  @media mobile-horizontal-max
    width: 30%

  .sorting-options-search
    .sort-dropdown
      .label
        font-family: font-semi-bold
        margin-bottom: 0.5em
        text-transform: initial
        display: block

  .dropdown-wrapper
    &.light
      margin-bottom: 0.3em

      [class*="-container"]
        min-width: auto !important

  .select2-container, .select2-choice
    background-color: white !important
    color: $default-text-color
    
    &:hover
      color: black
    
  .select2-container-multi .select2-choices
    border none
    background-color $very-light-grey !important
  
  .left-panel-content
    padding: 1em 2em
    
    .personalized-search
      margin-bottom: 1em
      
      .title
        margin-top: 1em
        margin-bottom: 1em
        
    section.creator-filter
      text-align initial
      margin 0.5em 0em -2em 0em

      .select2-container-multi .select2-choices
        border none
        background-color $very-light-grey
        
    .sorting-options-search
      .sortby
        font-family: font-semi-bold
        margin-bottom: 0.5em
    
    .search-type-selection
      .personalized-search
        margin-bottom: 1em
        .title
          margin-top: 1em
          margin-bottom: 1em
          
      height: 10em
      
      .title
        float: left
        margin-bottom: 1em
        
    .online-local-selection
      width: 100%
      float: left
      margin: 0.5em 0em
      padding-top: 1.5em
      border-top: 1px solid $very-dark-gray
      
      .local-content-info
        font-size: 1em
        clear: both
        text-align: left
        margin-bottom: 1em
      
    .filters
      clear: both
      float: left
      margin: 0.5em 0em 0em 0em
      padding-top: 1.5em
      width: 100%
      color: black
      border-top: 1px solid $very-dark-gray
      
      .item-type
        margin-top: -0.5em
        float: left
        width: 100%
        
        .env-file-filter
          margin-top: 1em
          
      .filter-actions
        display: block
        margin-bottom: 1em
        width: 100%
        position: relative
        text-align: center
        clear: both
        
        .filter-links
          float: left
          padding: 0 5% 5% 0%
        
          .clear-filters
            display: block
            margin-bottom: 1em
            width: 100%
            margin-left: 50%
            
            @media mobile-horizontal-max
              margin-left: 0em
              
          a.exclude
            display:block
            text-align: left
            margin-bottom: 0.5em
            color: $default-text-color
            
            &:before
              display: inline-block
              font-family: 'tekla-icomoon'
              content: "\e900"
              
            &.active
              &:before
                transform: rotate(90deg)
                
        .exclude-filters
          float: left
          width: 100%
          margin-top: -0.5em
          margin-bottom: 0.5em
        
      
      .title
        font-family: font-semi-bold
        
      .sbar
        width: 100%
        margin-bottom: default-margin*2

      .filter-controls
        text-align: right
        width: 100%
        font-size: 0.9em
        margin-bottom: default-margin
        a
          margin-right: default-margin

      .filter-pulldowns
        margin: default-margin 0
        clear: both
        float: left
        width: 100%
        
        .pull-exclude
          margin-bottom: 3.7em !important

        .pull
          text-align: initial
          margin-bottom: default-margin
          vertical-align: top
          
          .pull-wrapper
            margin-bottom: -3em
            
            .clear-filter
              margin-top: 5px

            .select2-container
              .select2-choice
                &:hover
                  background-color: $dark-gray
                  border: 1px solid $dark-gray
                  color: white

                  &.select2-default
                    color: black !important
                    
          &:first-child
            .pull-wrapper
              margin-left: 0    
              
      .select2-container-multi
        display: block

        .select2-choices
          border: none
          background-color: transparent
          min-height: 2em
          padding: default-padding 0
          
          .select2-search-choice
            border: 1px solid $very-light-grey
            -webkit-transition: border 200ms ease-in
            -moz-transition: border 200ms ease-in
            -o-transition: border 200ms ease-in
            transition: border 200ms ease-in
            margin: default-padding*.5 0
            float: none
            line-height: 1.5em
            
            .select2-search-choice-close
              &.excludechoice
                color: $almost-white
                
            &.excludechoice
              background-color: $red
              color: $almost-white
            
            &:last-child
              margin-bottom: 3em     

            &.activated
              border: 1px solid $blue
              
            

    .show-hide-filters
      margin: default-margin*2 default-margin
      font-size: 1em
      line-height: 2em

      .more, .less
        padding-top: 1em
        font-size: 1.25em

      .more, .less, .loaddefaults, .clearfilters, .saveuserdefaults
        a
          color: $yellow

      .more:after, .less:after, .more:before, .less:before
        content: '\203a'
        transform: rotate(90deg)
        display: inline-block
        color: $yellow
        margin: 0 default-margin

      .less:after, .less:before
        transform: rotate(-90deg)

      .less:before, .more:before
        opacity: 0

.right-panel
  width: 80%
    
  @media mobile-horizontal-max
    width: 100%
  
  &.thumbmode
    @media mobile-horizontal-max
      width: 100%
    
  &.narrowmode
    margin-top: -15em
    
  .right-panel-content
    padding: 2em 2em 1em 2em

    @media tablet-horizontal-max
      padding: 1em 1em 0.5em 2em;
    
  
section.results-container
  .result
    margin-bottom: default-margin*2
    position: relative

    .selection
      &.selected
        background-color: $blue

      label
        padding-right: 0
        :before
          margin-right: 0

  &:not(.as-thumbnails)
    .result
      .selection
        padding: default-padding*2
        margin: 0
        position: absolute
        top: 0.3em
        left: 18.4em
        width: default-padding*7
        text-align: left
        box-sizing: border-box
        
      .selection-seach-results
        font-family: font-semi-bold
        padding: default-padding*2
        margin: 0
        position: absolute
        top: 0
        bottom: 0
        width: default-padding*24
        text-align: center
        box-sizing: border-box
        margin-top: 6em
        
        @media mobile-horizontal-max
          width: initial
          padding: default-padding*2 default-padding*2 default-padding*2 0
        
        .shopping-cart-actions
        
          .cart-button-text
            float: left
            font-size: 1.4em
            
          .icon-warehouse
            font-size: 14px
            margin: 1px 0px 0px 8px
            float: right
            
            
      .item.can-select-search-results
        margin-left: default-padding*24
        
        @media mobile-horizontal-max
          margin-left: 8em
        

  &.as-thumbnails
    text-align: center
    clear: both
    
    .result
      display: inline-block
      vertical-align: top
      width: 270px
      
      .selection
        padding: 0
        margin: 0 0
        position: absolute
        top: 0.3em
        right: 0
        

    .summary
      text-align: left
      
      @media desktop-max
        margin-left: 1em
      
      
  &.no-entities
    margin-top: -8em

  .button-primary
    padding: default-padding*2 default-padding*6

  .clear-alerts
    margin-bottom: -5px
    
.cart-header
  text-align: center
  margin: 0.5em 1em 1em 0em
  font-size: 2.5em
  font-family: font-normal
  color: $dark-text-color

section.result-options
  background-color: $almost-white
  padding: default-padding*2 default-padding*8 0
  border-bottom: 1px solid $very-light-grey
  
  &.local-result-options
    padding: 0 1em
    background-color: white
    
  &:not(.alerts-result-options), &:not(.likes-result-options) 
    .select2-container, .select2-choice
      background-color: white !important
  
  @media tablet-horizontal-max
    padding: 1em 0em 0em 0em
    
  .error-messages 
    font-size: 0.7em
         
  .requires-maintenance
    font-size: 0.7em
    width: 27.5em
    
    .icon-maintenance
      float:left
      color: $yellow
    
    .maintenance-text
      margin-left: 0.1em
      float: right
      
  .email-notifications
    margin-top: 5px
    margin-bottom: 0.5em
    float: left
    max-width: 40%
    position: relative
    
    @media tablet-max
      max-width: 100%
      margin-bottom: 1em
      
section.result-options-mobile
  @media desktop-max
    height: 290px
    margin-bottom: -2em
    padding-left: 1em
    
  @media tablet-horizontal-max
    height: 420px
    
  @media mobile-horizontal-max
    height: 470px   
  

section.suggestions, section.results-container
  .load-indicator
    margin: default-margin*3 auto
    text-align: center
    max-width: 6em

  header
    text-align: initial

  .listing-wrapper
    text-align: left

    .search-results-tabs
      text-transform: uppercase
      font-family: font-semi-bold
      font-size: 0.9em
      border-bottom: 1px solid $very-light-grey
      height: 4em


      .search-result-tab
        float: left
        width: 18em
        border: 1px solid $very-light-grey
        border-bottom: none
        height: 4em
        margin-left: default-margin*2
        background-color: $blue
        color: $almost-white
        cursor: pointer

        &:first-child
          margin-left: 1em

        &:hover
          background-color: $yellow

        .result-tab-icon
          font-size: 2em
          margin-top: 0.2em

        &.active
          background-color: white
          color: $default-text-color
          
    .search-target
      text-align: left
      margin-bottom: 1em
      font-family: font-normal
      font-size: 1em
      
      label
        &:not(.button)
          display: block
          padding: default-padding 0
          font-size: 1em
          color: $default-text-color
          margin-left: 0.1em
          margin-right: 1em

    .select-list-mode
      text-align: left
      margin-top: 2em
      margin-bottom: 2em
      
      label
        margin-left: 0.2em
        margin-right: 0.7em
        
        &:not(.button)
          display: block
          padding: default-padding 0
          font-size: 1em
          color: $default-text-color

          &.icon
            margin-top: 1em
            &:before
              margin: 0 default-padding*.5
              
    .download-install
      text-align: left
      margin-bottom: default-margin
      clearfix()

      .version-buttons
        float: left
        
        @media mobile-horizontal-max
          margin-bottom: 1em
        
        .add-all-to-basket
          margin-bottom: 2em
          
        .add-all-info-text
          display:inline-block
          font-size: 0.7em
        
        .cart-button-text
          float: left
          margin-top: 0.2em
        
        .icon-warehouse
          font-size: 14px
          margin: 2px 0px 0px 8px
          float: right
          
        .error-messages 
          font-size: 0.7em
          
        .requires-maintenance
          font-size: 0.7em
          width: 27.5em
          
          .icon-maintenance
            float:left
            color: $yellow
          
          .maintenance-text
            margin-left: 0.1em
            float: right

        button:first-child
          margin-left: 0
          
          @media mobile-horizontal-max
            margin-bottom: 1em
          


    .results-summary
       
      .tested-versions-filter-info
        clear: both
        float:left
        margin-top: 1em
        margin-left: -1em
        text-align: center
      
      clearfix()
      margin-bottom: default-margin*2
      font-family: font-semi-bold
      
      @media desktop-max
        margin-top: 1em

      .results-count
        float: left
        text-transform: uppercase
        padding-top: default-padding
        margin-top: 1em
        font-family: font-semi-bold

      .paging-options
        float: right

        span
          font-family: font-semi-bold

        .paging-dropdown
          display: inline-block
          min-width: 6em
          text-align: initial
        .paging-type-selection
          margin-left: 1em
          margin-top: 0.25em

    .results
      clearfix()
      
      &.results-local
        float: left

      .notifications-wrapper
        padding: default-padding*.5 default-padding
        text-align: left
        font-size: .85em
        box-sizing: border-box


