//Views

#content-details
  min-height: 110em
    
  &.editmode
    min-height: 130em  

.catalog
  @import "list/list.styl"
  
  .autocomplete-drop
    background-color: white

  &.entity
    position: relative
    overflow: hidden

    section.main
      float: left
      width: 100%

      .content-details
        max-width: 1440px
        margin: 0 auto 0 auto
        padding: 2em 2em 2em 5em
        
        @media tablet-max
          padding: 1em 2em
          

        header
          position: relative
          padding: 0 0 default-padding*8 0

          h1
            display: inline-block
            
          h1.details
            
            @media mobile-horizontal-max
              max-width: 15em
              word-wrap: break-word
            
            max-width: 21em


          ul.header-actions
            padding-top: default-padding


            li.action-item
              list-style: none
              position: absolute
              margin-right: default-margin
              margin-left: -1em
              

            li.links, li.subscribers
              left: 5em

              ol > li
                list-style: none
                margin-left: default-margin

                span
                  margin-right: default-padding

              .querying
                text-align: center
                padding: default-padding

            li.subscribers
              left: 9em

            .icon
              font-size: 1.5em
              vertical-align: middle
              color: $very-dark-gray

            .count
              vertical-align: middle

            .link-popup, .subscribers-popup
              background: $background-color
              position: relative
              top: -48px
              left: 55px
              border: 1px solid $very-dark-gray
              min-height: 100px
              min-width: 300px
              max-width: 400px
              z-index: 10

              .loading-indicator
                text-align: center
                height: 100%
                width: 100%
                position: absolute

                .spinner
                  position: relative
                  transform translate(-50%, -50%)
                  -webkit-transform translate(-50%, -50%)
                  -ms-transform translate(-50%, -50%)
                  top: 50%
                  left: 50%

              .popup-content
                padding: default-padding

            .subscribers-popup
              min-height: 10px

            .link-popup:after, .link-popup:before, .subscribers-popup:after, .subscribers-popup:before
              right: 100%
              top: 50%
              border: solid transparent
              content: " "
              height: 0
              width: 0
              position: absolute
              pointer-events: none

            .link-popup:after, .subscribers-popup:after
              position: absolute
              border-color: rgba(136, 183, 213, 0);
              border-right-color: $background-color
              border-width: 10px
              top: 21px

            .link-popup:before, .subscribers-popup:before
              border-color: rgba(194, 225, 245, 0);
              border-right-color: $very-dark-gray
              border-width: 11px
              top: 20px

      span.versions-count
        margin-left: default-padding*.5

      .tab-view
        max-width: 1440px
        margin: 0 auto 0 auto
        padding: 1em 1.5em 1em 0em
        min-height: 40em
         
        .rich-content
          width: 95%
          
          .f-video-editor
            iframe
              @media mobile-horizontal-max
                height: 100%
              @media tablet-horizontal-max
                width: 100%
                
        .report-bad-content, .report-illegal-content
          margin-top: 2em
          font-size: 0.9em

        .report-illegal-content
          margin-top: 1em
        
        .edit-details
          @media mobile-horizontal-max
            margin-top: 0.5em
                                 
        @media tablet-horizontal-max
          padding: 1em 1em 0 1em

        a.tekla-maintenance-required
          span.icon
            margin-right: default-padding*.5

        table
          td.label
            color: $default-text-color
            font-family: font-semi-bold
            width: 50%
          tr:hover
            td.label
              color: white

        .controls
          display: inline-block
          width: 100%

          .row
            text-align: initial
            display: inline-block
            width: 100%

            .sort-options
              margin: 0
              margin-left: default-margin
              margin-bottom: default-margin
              float: right

              .versions-sort-dropdown
                width: 14em !important

              span.label
                float: left
                font-size: .9em
                line-height: 2.5em
                margin-right: .75em

            a.add-version
              float: left
              margin: 0 default-margin 0 0

            a.icon
              float: left

            a.tekla-maintenance-required, a.login-to-download
              float: right
              clear: both
              margin-right: 0
              margin-bottom: 10px

        .versions
          
          @media tablet-horizontal-max
            width: 95%
            
          a.edit-versions
            margin-top: 0.5em
              
          ol.versions-table
            margin: 1em 0
            width: 100%
                
            li.version
              display: flex
              flex-direction: column
              background-color: $almost-white
              padding: 0 2*default-padding 2*default-padding 2*default-padding
              margin-top: 30px
              min-height: 6em

              .header
                display: flex
                flex-direction: row
                justify-content: space-between
                padding: 2*default-padding 0


                .version-info
                  display: flex
                  flex-direction: column
                  flex-grow: 1
                  min-width: 50%
                  
                  span
                    font-size: .85em
                    padding: 0 default-padding*.5
                    color: $default-text-color

                  .version-title
                    display: flex
                    padding-bottom: 0.5em

                    h4
                      padding: 0 default-padding*.5
                      margin: 0
                      color: $darker-gray
                      font-size: 1.5em
                      font-family: font-light
                      text-transform: none

                    span
                      display: flex
                      align-items: center
                      margin-left: 0.5em !important
                      font-size: 12px
                      padding-left: 0.5em
                      padding-right: 0.5em

                  .version-number
                    padding: 0.5em 0 0.25em 0.25em
                    
                  .version-edited
                    display: flex
                    flex-direction: row
                    align-items: center
                    .created
                      margin-left: 0.2em
                      
                  .show-version-details
                    margin-top: 1em
                    margin-left: 0.5em
                    font-size: 0.9em
                    
                    a.show-details
                      &:after
                        font-family: 'tekla-icomoon'
                        content: "\e900"
                        position: absolute
                        margin-left: 0.2em
                        padding: 1px
                      
                    a.hide-details
                      &:after
                        font-family: 'tekla-icomoon'
                        content: "\e900"
                        position: absolute
                        transform: rotate(90deg)
                        margin-left: 0.2em
                        padding: 1px

                .versions-install-buttons
                  padding: 2*default-padding 0
                  text-align: right
                  
                  .icon
                    margin-right: 7px
                    font-size: 1em
                    line-height: 1em
                    margin-top: 1px
                    
                  .icon-download
                    margin-right: 2px
                    
                  a
                    font-size: 0.9em
                    margin-left: 10px

                  .button
                    margin-right: 0px

                  ul > li
                    padding: default-padding*.5 0
                    list-style: none

                  .button.icon-download
                    margin: 0

              .versions-table-prerequisites, .versions-releate-notes
                margin-left: 0.4em
                width: 50%
                list-style: none
              
                @media mobile-horizontal-max
                  margin-left: -0.2em
                  width: 100%

                .prerequisite_link
                  margin-right: 0.5em
                  margin-bottom: default-margin
                  display: inline-block

                li
                  padding: 0 .5em

                  span
                    font-size: .85em
                    padding: 0
                    color: $very-dark-gray
                      
              table
                display: inline-table
                margin-bottom: .5em
                margin-top: 2em
              
        .release-notes
          margin: 1em 1em
          
          a.icon-pencil
            padding-left: 0em
          
          .release-notes-item
            margin-top: 1em
            
        .versions
          margin: 1em 1em
          
        .properties
          margin: 1em 1em
          
          a.icon-pencil
            padding-left: 0em
          
          .search-criteria-item, .group-category-item
            margin: 1em 1em 2em 0em
            
            .license, .commenting
              margin-top: 1em
            
        .recommended
          margin: 1em 1em
        
        .details
          margin: 1em 1em
                
        .support
          margin: 1em 1em 2em 0em
          
          .edit-support
            margin-left: 0.5em
          
          .support-item
            margin: 1em 1em 2em 1em
                
        .statistics
          width: 85%
          text-align: right
          
          .latest-update
            margin-top: 1em
            
            .created
              margin-right: 0.5em
              
          .copyright
            margin-top: 1em
              
        .recommended
          
          @media mobile-horizontal-max
            padding-right: 1em
            margin-left: -0.5em
          
          .other-content
            
            .other-content-title
              margin-bottom: 2em

          .related
            button.add-related-content
              margin-bottom: default-margin*3
              margin-left: 0em
              
            button.remove-related-content
              display: block
              margin: 1em auto
              
            .related-title
              margin-bottom: 2em


ul.tree-item-browser
  list-style-type: none

  li > a.header
    display: block
    padding: default-padding
    background-color: $almost-white
    border-bottom: 1px solid white
    transition: .2s linear all
    &:before
      content '▸'
      margin-right .5em
    &:hover
      background-color: $very-light-grey
      cursor: pointer
      text-decoration: none
    &.selected
      color: white
      cursor: pointer
      &:before
        content '▾'
        margin-right .5em
  li
    .spinner
      margin default-margin 0 0 default-margin

    ul
      li
        clearfix()
        &.selected
          background-color: $very-light-grey
        &:hover
          cursor: pointer
        input[type="checkbox"]
          margin-right: default-padding
          float: left
        label
          float: left
          width: 100%
        input[type="checkbox"]
          margin-top: .25em

.content-info, .content-banned-banner
  color: $almost-white
  background-color: $red
  padding-left: default-padding*6
  
  &.mediummode
    height: 8em  
  
  &.bigmode
    height: 11em  
      
  .content-scan, .content-banned
    padding: 1.5em 0
    margin: 0 auto
    display: flex
    align-items: center
    max-width: 1440px

    .scan-info-sign, .banned-info-sign
      margin-top: 0
      margin-left: 0
      float: none
    
    .scan-info-text, .banned-info-text
      margin-left: 4em
      
      a
        color: $yellow
        padding: 0
        
      .contact-trimble
        margin-top: 0.5em

.content-archived-banner
  color: #363545
  background-color: $very-light-grey
  padding-left: default-padding*6

  .content-archived
    padding: 1.5em 0
    margin: 0 auto
    display: flex
    align-items: center
    max-width: 1440px

    .archived-info-sign
      margin-top: 0
      margin-left: 0
      float: none
    
    .archived-info-text
      display: flex
      width: 100%
      justify-content: center